import { Application, filters, Container, IPoint, LoaderResource, Sprite, Texture, SCALE_MODES } from "pixi.js";
import { addInteraction } from "./Interactions";
import deformer_base from '../../assets/defoermer_base.png';
import {Sprite2d} from 'pixi-projection';

export const createDeformerSpriteAndApply = (container: Container, app: Application, quad: IPoint[]) => {
    console.log('PROJECTION:')
    const deformerBaseSprite = new Sprite2d(Texture.from(deformer_base));
    deformerBaseSprite.width = 512;
    deformerBaseSprite.height = 512;
    deformerBaseSprite.anchor.set(0.5);
    container.addChild(deformerBaseSprite);

    return new Promise<Sprite2d>((resolve, reject) => {
        setTimeout(() => {
            try {
                deformerBaseSprite.proj.mapSprite(deformerBaseSprite, quad);
                resolve(deformerBaseSprite)
            } catch (err) {
                reject(err)
            }
        }, 500)
    })
}

export const setDimanesionsOfPlaceholder = (placeholderResource: LoaderResource, placeholderSprite: Sprite, defomerSprite: Sprite2d, uploadedImage?: string) => {
    const originalWidth = placeholderResource.texture.width;
    const originalHeight = placeholderResource.texture.height;
    const ratio = originalHeight / originalWidth;


    if (uploadedImage ? originalWidth < originalHeight : originalWidth >= originalHeight) {
        placeholderSprite.width = defomerSprite.width;
        placeholderSprite.height = placeholderSprite.width * ratio;
    } else {
        placeholderSprite.height = defomerSprite.width;
        placeholderSprite.width = placeholderSprite.height / ratio;
    }

    return 1 / (originalWidth / placeholderSprite.width);
}

export const addPlaceholderLayer = (
    deformerBaseSprite: Sprite2d,
    placeholderResource: LoaderResource,
    filter: (typeof filters.DisplacementFilter) | any,
    canvas_container: HTMLDivElement,
    enableInteraction: boolean,
    registerDragEvent?: () => void,
    uploadedImage?: string,
    id?: string,
    onSelect?: (id: string) => void,
    registerStartEvent?: () => void,
    getIsEditing?: () => boolean,
    getTouchVelocity?: () => number,
    getSelectedId?: () => string
) => {
    const placeholderSprite = new Sprite2d(placeholderResource.texture);
    placeholderSprite.texture.baseTexture.scaleMode = SCALE_MODES.LINEAR;
    placeholderSprite.texture.update();
    placeholderSprite.anchor.set(0.5);

    setDimanesionsOfPlaceholder(placeholderResource, placeholderSprite, deformerBaseSprite, uploadedImage);

    if (enableInteraction && canvas_container) {
        addInteraction(placeholderSprite, registerDragEvent, canvas_container, id, onSelect, registerStartEvent, getIsEditing, getTouchVelocity, getSelectedId);
    }

    placeholderSprite.filters = [filter];

    (deformerBaseSprite as any).addChild(placeholderSprite);

    return placeholderSprite;

}