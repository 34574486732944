import React from 'react'
import styled from "styled-components"

const Svg = styled.svg`
    display: none;
`

export default function PickerRect({ id = '' }: { id: string }): JSX.Element {
    return (
        <Svg>
            <defs>
                <g id={id}>
                    <defs>
                        <filter id="Rectángulo_1412" x="0" y="0" width="23" height="35" filterUnits="userSpaceOnUse">
                            <feOffset />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectángulo_1412)">
                        <rect x="-5" y="-10" id="Rectángulo_1412-2" width="5" height="17" rx="2.5" transform="translate(9 9)" fill="#fff" />
                    </g>
                </g>
            </defs>
        </Svg>
    )
}
