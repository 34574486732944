import React, { useState } from "react";
import styled, { css } from "styled-components"
import { CategoryViewModel } from "../../../../api/clientInterfaces/CategoryModel";
import { ReactComponent as Close } from "../../../../assets/icons/close.svg"
import { sendEvent } from "../../../../utils/Events";
import Categories from "../../mockupsBar/categories/Categories";
import MockupList from "../../mockupsBar/MockupList";
import isMobile from 'ismobilejs';


const MobileMockupsWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .header__container{
    display:grid;
    grid-template-columns: 3fr 1fr;
    padding: 4em 3em 3em 3em;
    }

    .mockups__title{
        grid-column: 1/3;
        text-align: center;
        h2{
            font-size: 14px;
            font-weight: 500;
        }
    }
    .mockups__close{
        grid-column: 3/4;
        svg{
             min-width:18px;
             min-height:18px;
             cursor:pointer;
            path {
                stroke: #707070;
            }
        }
    }

    .mockups-container {
        flex: 1;
        overflow: hidden;
        display: flex;
    }
`


type Props = {
    selectedMockupId: number,
    closeEvent: () => void
}

const MobileMockups = (props: Props) => {
    const [openBrowseMockups, setOpenBrowseMockups] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<CategoryViewModel>(undefined)

    const handleBrowseAllMockups = (arrow?: boolean) => {
        if (arrow) {
            sendEvent('MG - Related Column', 'openArrow');
        }
        sendEvent('MG - Related Column', 'browseCategories');
        setOpenBrowseMockups(!openBrowseMockups)
    }

    return (
        <MobileMockupsWrapper>
            <div className="header__container">
                <div className="mockups__title">
                    <h2>Try other mockups</h2>
                </div>
                <div className="mockups__close" onClick={props.closeEvent}>
                    <Close />
                </div>
            </div>
            <div className={`mockups-container`}>
                <Categories
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    selectedMockupId={props.selectedMockupId}
                />
                <MockupList
                    open={true}
                    openBrowseMockups={true}
                    selectedMockupId={props.selectedMockupId}
                    handleBrowseAllMockups={() => handleBrowseAllMockups()}
                />
            </div>
        </MobileMockupsWrapper>
    )

}

export default MobileMockups;