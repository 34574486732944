import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CustomizeColor from "./CustomizeColor/CustomizeColor"
import SelectBackground from "./SelectBackground/SelectBackground"
import Tabs from './Tabs/Tabs'
import { MockupWithImagesViewModel } from '../../../api/clientInterfaces/MockupModel'
import { screenSize } from '../../../stylesGlobal/Variables'
import UploadImageList from './UploadImage/UploadImageList'
import FiltersList from './FiltersList/FiltersList'
import { dataLayerPush } from '../../../utils/DataLayer'

const ToolbarWrapper = styled.section`
    min-height:100vh;
    background-color:var(--color-white);
    display:grid;
    grid-template-columns: auto 200px;
    padding-top:5.4rem;
    position:relative;
    z-index:var(--zIndex-medium);

    .toolbar__tabsContent{
        width:100%;
        min-height:100vh;
        background-color:var(--color-white);
        padding:1em;

        @media (min-width: ${screenSize.size_m}){
            padding:3em;
        }
    }

    @media (min-width: ${screenSize.size_m}){
        display: grid;
        grid-template-columns: auto 300px;
        width:auto;
    }
`

export enum TabType {
    uploadImage = "UploadImage",
    customizeColor = "CustomizeColor",
    selectBackground = "SelectBackground",
    filters = "Filters",
}

type Props = {
    selectedMockup: MockupWithImagesViewModel,
    loading: boolean,
    mockupReady: boolean,
}

const Toolbar = (props: Props) => {

    const [activeTab, setActiveTab] = useState<TabType>(TabType.uploadImage)

    useEffect(() => {
        setActiveTab(TabType.uploadImage)
    }, [props.selectedMockup])

    const handleTabChange = (tabtype: TabType) => () => {
        setActiveTab(tabtype)
        switch (tabtype) {
            case TabType.customizeColor:
                return dataLayerPush('mg_sidebar_color')
            case TabType.selectBackground:
                return dataLayerPush('mg_sidebar_background')
            case TabType.filters:
                return dataLayerPush('mg_sidebar_filters')
        }
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case TabType.uploadImage:
                return <UploadImageList />
            case TabType.customizeColor:
                return <CustomizeColor
                    loading={props.loading}
                />
            case TabType.selectBackground:
                return <SelectBackground
                    selectedMockup={props.selectedMockup}
                    loading={props.loading}
                />
            case TabType.filters:
                return <FiltersList selectedMockup={props.selectedMockup} loading={props.loading} />
            default:
                console.error(`TabType ${activeTab} not exist`)
        }
    }

    return (
        <ToolbarWrapper>
            <div className="toolbar__tabs">
                <Tabs
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    selectedMockup={props.selectedMockup}
                    mockupReady={props.mockupReady}
                />
            </div>
            <div className="toolbar__tabsContent" style={{ overflow: activeTab === TabType.selectBackground || activeTab === TabType.uploadImage || activeTab === TabType.filters ? 'auto' : 'visible' }}>
                {renderTabContent()}
            </div>
        </ToolbarWrapper>
    )
}

export default Toolbar