import React from "react"
import styled, { css } from "styled-components"
import isMobile from 'ismobilejs';
import { ReactComponent as Close } from "../../../../assets/icons/close.svg"
import { CategoryViewModel } from "../../../../api/clientInterfaces/CategoryModel"
import { screenSize } from "../../../../stylesGlobal/Variables"

type SelectedCategoryWrapperProps = {
    image: string,
    isMobile?: boolean
}
type SelectedCategoryContainer = {
    isMobile?: boolean
}

const SelectedCategoryContainer = styled.form<SelectedCategoryContainer>`
  ${(props) => props.isMobile ? css`
   display: flex;
   justify-content: center;
  `: null} 
`;

const SelectedCategoryWrapper = styled.div<SelectedCategoryWrapperProps>`
    display:inline-flex;
    height:6rem;
    align-items:center;
    border-radius:.6rem;
    border:2px solid var(--color-blue);
    padding-right:1rem;
    margin:2rem 0 2rem 1%;
    ${(props) => props.isMobile ? css`
    margin:2rem 0 2rem 0%;
    `: null} 

    .selectedCategory__info{
        display:flex;
        flex-direction:column;
        padding-left:1rem;

        h2{
            font-size:1.4rem;
            font-weight: 500;
        }
        p{
            font-size:1rem;
            color:#B9B9B9;
        }
    }

    .selectedCategory__image{
        width: 7rem;
        height:100%;
        background:url("${props => props.image}");
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
        border-radius:.2rem;
    }

    .selectedCategory__buttonContainer{
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left:2rem;
    }

    .selectedCategory__button{
        border:none;        
        background:var(--color-blue);
        border-radius:100%;
        width:20px;
        height:20px;
        cursor:pointer;
        position:relative;

        &:focus{
            outline:none;
        }

        svg{
            min-width:1rem;
            min-height:1rem;
            position:absolute;
            top:calc(50% - .5rem);
            left:calc(50% - .5rem);
            cursor:pointer;

            path {
                stroke: white;
            }
        }
    }

    @media (min-width: ${screenSize.size_2xl}){
        .selectedCategory__info{
            h2{
                font-size:1.4rem;
                font-weight: 600;
            }
            p{
                font-size:1.2rem;
            }
        }
    }
`

type Props = {
    category: CategoryViewModel,
    cleanSelectedCategory: () => void
}

const SelectedCategory = (props: Props) => {

    return (
        <SelectedCategoryContainer isMobile={isMobile(window.navigator).any}>
            <SelectedCategoryWrapper image={props.category.thumb} isMobile={isMobile(window.navigator).any}>
                <div className="selectedCategory__image" />
                <div className="selectedCategory__info">
                    <h2>{props.category.name}</h2>
                    <p>{`${props.category.amount} ${props.category.amount > 1 ? "designs" : "design"}`}</p>
                </div>
                <div className="selectedCategory__buttonContainer">
                    <button
                        className="selectedCategory__button"
                        onClick={props.cleanSelectedCategory}
                    >
                        <Close />
                    </button>
                </div>
            </SelectedCategoryWrapper>
        </SelectedCategoryContainer>

    )
}

export default SelectedCategory