import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DimensionType } from '../../mockups/MockupTypes';
import { CRTFilter } from '@pixi/filter-crt';
import { OldFilmFilter } from '@pixi/filter-old-film';
import * as Sentry from "@sentry/browser"
import { Sprite2d } from 'pixi-projection';
import {
    canvasDimensions,
    appSetup,
    loadTextures,
    addBaseLayer,
    addShadowsAndLightsLayer,
    setupPlaceholderLayer,
    buildFilter,
    addMaskLayer,
    buildContainer,
    changePlaceholderImage,
    changeBackgroundImage,
    downloadImage,
    clean,
    applyRotation,
    applyScale,
    addColorLayer,
    getTextureNames,
    idleCallback,
    addWatermarkLayer,
    addHighlightLayer,
    setupFilters
} from './SmartFunctions';
import { addPlaceholderLayer } from './SmartFunctionsPixiNoTypes';
import DownloadSpinner from '../ui/spinner/DownloadSpinner';
import { MockupWithImagesViewModel } from '../../api/clientInterfaces/MockupModel';
import ErrorBox from '../ui/spinner/ErrorBox';
import jimp from 'jimp';
import { SCALE_STATE, UserUploadType, SELECTED_SOURCE, FILTER } from '../../Recoil/RecoilState';
import { v4 as uuidv4 } from 'uuid';
import { applyFilter } from './FiltersFunctions';
import { Application, Loader, Rectangle, Sprite, Texture, filters } from 'pixi.js';
import { convertToPNG } from '../../utils/convertToPNG';

export type PlaceholderBoundsType = {
    width: number,
    height: number,
    x: number,
    y: number
}


interface Props {
    getTouchVelocity: () => number,
    setCanvasRef: (canvasRef: HTMLDivElement | null) => void,
    setIsEditing: () => void,
    isEditing: boolean,
    isMobile: boolean,
    filterPower: number,
    filter: FILTER,
    baseColor: string,
    selectedImageId: {
        id: string,
        source: SELECTED_SOURCE
    },
    highlightedImage: string,
    setSelectedImageId: (id: string, scale: number, spriteBounds: PlaceholderBoundsType) => void,
    mockupReady: boolean,
    centerImage: boolean,
    endCenterImage: () => void,
    startDownload: boolean,
    downloadSize: {
        width: number,
        height: number,
    }
    endDownload: () => void,
    imageRotation: number,
    imageScale: number,
    imageScaleState: SCALE_STATE,
    // uploadedImage: string,
    uploadedImages: { [id: string]: UserUploadType },
    mockup: MockupWithImagesViewModel,
    relativeElement: HTMLElement,
    backgroundSelected: number,
    backgroundColor: string,
    setOriginalSize: (width: number, height: number) => void,
    setIsBackgroundChanging: (isChanging: boolean) => void,
    setMockupReady: () => void,
    registerDragEvent: () => void,
    handleErrorModal: () => void,
    setDefaultScale: (defaultScale: number) => void,
    deformerImagesLoaded: (ids: string[]) => void,
    watermark: boolean
}

type PlaceholderType = {
    placeholderOriginalWidth: number,
    placeholderOriginalHeight: number,
    placeholderSprite: Sprite,
    originalPlaceholderURL: string,
    dragStart: {
        x: number,
        y: number
    }
}

type DeformerType = {
    id: string,
    deformerBaseSprite: Sprite2d,
    placeholder: PlaceholderType,
    highlight: Sprite,
    hitAreaOriginalPosition: { x: number, y: number, width: number, height: number }
}

type ColorMatrixFilte = typeof filters.ColorMatrixFilter;

const MockupViewerWrapper = styled.article`
    display: flex;
    justify-content: center;
    align-items: center;

    .optimizing {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    .container {
        --color: #8f8f8f;
        z-index: 1;
        background-image: linear-gradient(45deg, var(--color) 25%, transparent 25%), linear-gradient(-45deg, var(--color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color) 75%), linear-gradient(-45deg, transparent 75%, var(--color) 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    }
`

const useMockupViewer = (props: Props, setoptimizing: (value: boolean) => void) => {
    const [loadingImages, setloadingImages] = useState(true);
    const [hasError, setHasError] = useState(false);
    const canvas_container = useRef<HTMLDivElement>();
    const app = useRef<Application>();
    const loader = useRef<Loader>();
    const hiResLoader = useRef<Loader>();
    const dinamycUserImageLoader = useRef<Loader>();
    const dinamycBackgroundImageLoader = useRef<Loader>();
    const baseSprite = useRef<Sprite>();
    const lightSahdowsSprite = useRef<Sprite>();
    const watermarkSprite = useRef<Sprite>();
    const colorSprite = useRef<Sprite>();
    const dimensions = useRef<DimensionType>();
    const filterIG = useRef<ColorMatrixFilte | any>();
    const filterCTR = useRef<CRTFilter>();
    const filterOldFilm = useRef<OldFilmFilter>();
    const filterOverLayer = useRef<Sprite>();
    const filterOverLayerOpacity = useRef<number>();
    const filter = useRef<typeof filters.DisplacementFilter | any>();
    const textureNames = getTextureNames();
    const deformerList = useRef<{ [id: string]: DeformerType }>({});
    const previousUploadedImages = useRef<{ [id: string]: UserUploadType }>(props.uploadedImages);
    const IsEditing = useRef<boolean>(props.isEditing);
    const selectedId = useRef("");

    useEffect(() => {
        IsEditing.current = props.isEditing
        if (!IsEditing.current) {
            selectedId.current = ""
        }
    }, [props.isEditing])

    useEffect(() => {
        if (props.selectedImageId) {
            selectedId.current = props.selectedImageId.id
        } else {
            selectedId.current = ""
        }
    }, [props.selectedImageId])


    const updateBackgroundImage = async (backgroundImage: string) => {
        props.setIsBackgroundChanging(true)
        await changeBackgroundImage(backgroundImage, dinamycBackgroundImageLoader.current, baseSprite.current);
        props.setIsBackgroundChanging(false)
    }

    const updatePlaceholderImage = async (uploadedImage: string, imageId: string) => {
        const placeholderSprite = deformerList.current[imageId].placeholder.placeholderSprite;
        const deformerSprite = deformerList.current[imageId].deformerBaseSprite;
        await changePlaceholderImage(uploadedImage, dinamycUserImageLoader.current, placeholderSprite, deformerSprite, true);
    }

    useEffect(() => {
        if (colorSprite.current) {
            if (props.backgroundColor) {
                colorSprite.current.tint = Number(props.backgroundColor);
                colorSprite.current.alpha = 1;
            } else {
                colorSprite.current.alpha = 0;
            }
        }
    }, [props.backgroundColor])

    useEffect(() => {
        if (props.imageRotation !== undefined && props.selectedImageId && deformerList.current[props.selectedImageId.id]) {
            const placeholderSprite = deformerList.current[props.selectedImageId.id].placeholder.placeholderSprite;
            applyRotation(placeholderSprite, props.imageRotation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.imageRotation])

    useEffect(() => {
        if (props.imageScale && props.selectedImageId && deformerList.current[props.selectedImageId.id]) {
            const placeholderSprite = deformerList.current[props.selectedImageId.id].placeholder.placeholderSprite;
            applyScale(placeholderSprite, props.imageScale);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.imageScale])

    const setOverlayLayer = (layer: Sprite) => {
        filterOverLayer.current = layer;
    }

    const setOverlayLayerAlpha = (alpha: number) => {
        filterOverLayerOpacity.current = alpha;
    }

    useEffect(() => {
        if (props.filter && filterIG.current && filterOldFilm.current) {
            applyFilter(app.current.stage, props.filter, filterIG.current, filterCTR.current, setOverlayLayer, setOverlayLayerAlpha)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter])

    useEffect(() => {
        if (props.filterPower && filterIG.current && filterCTR.current) {
            (filterIG.current as any).alpha = props.filterPower * 1 / 100;
            filterCTR.current.vignettingAlpha = props.filterPower * 1 / 100;
            if (filterOverLayer.current) {
                filterOverLayer.current.alpha = props.filterPower * filterOverLayerOpacity.current / 100
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterPower])

    const reduceImage = async (imageId: string, isPlaceholder: boolean) => {
        if (deformerList.current && deformerList.current[imageId]) {
            const placeholderSprite = deformerList.current[imageId].placeholder.placeholderSprite;
            setoptimizing(true);
            const imageString = isPlaceholder ? deformerList.current[imageId].placeholder.originalPlaceholderURL : props.uploadedImages[imageId].userImage.base64;
            const image = await jimp.read(imageString);
            const sw = placeholderSprite.width;
            const sh = placeholderSprite.height;
            image.resize(sw, jimp.AUTO);
            const imageBase64 = await image.getBase64Async("image/png");
            placeholderSprite.texture = Texture.from(imageBase64);
            placeholderSprite.width = sw;
            placeholderSprite.height = sh;
            setoptimizing(false);
        }
    }

    const reduceImageExperiment = async (imageId: string, isPlaceholder: boolean): Promise<void> => {
        if (deformerList.current && deformerList.current[imageId]) {
            const placeholderSprite = deformerList.current[imageId].placeholder.placeholderSprite;
            const deformerSprite = deformerList.current[imageId].deformerBaseSprite;
            placeholderSprite.alpha = 0;
    
            let imageString = isPlaceholder ? deformerList.current[imageId].placeholder.originalPlaceholderURL : props.uploadedImages[imageId].userImage.base64;
    
            try {
                imageString = await convertToPNG(imageString);
                await updatePlaceholderImage(imageString, imageId);
                setoptimizing(true);
                const image = await jimp.read(imageString);    
                const sw = isPlaceholder ? deformerList.current[imageId].placeholder.placeholderOriginalWidth : placeholderSprite.width;
                const sh = isPlaceholder ? deformerList.current[imageId].placeholder.placeholderOriginalHeight : placeholderSprite.height;
                image.resize(sw, jimp.AUTO);
                const imageBase64 = await image.getBase64Async("image/png");
                await changePlaceholderImage(imageBase64, dinamycUserImageLoader.current, placeholderSprite, deformerSprite);
                placeholderSprite.width = sw;
                placeholderSprite.height = sh;
                placeholderSprite.alpha = 1;
                setoptimizing(false);
                props.setDefaultScale(placeholderSprite.width);
            } catch (error) {
                setoptimizing(false);
            }
        }
    }
    
    
    useEffect(() => {
        if (props.selectedImageId && props.imageScaleState && props.imageScaleState === SCALE_STATE.stop) {
            if (props.uploadedImages[props.selectedImageId.id].userImage.imageFile) {
                reduceImage(props.selectedImageId.id, false);
            } else {
                reduceImage(props.selectedImageId.id, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.imageScaleState])

    useEffect(() => {
        if (props.centerImage && props.selectedImageId && deformerList.current[props.selectedImageId.id]) {
            const placeholderSprite = deformerList.current[props.selectedImageId.id].placeholder.placeholderSprite;
            placeholderSprite.position.set(0, 0);
            if (props.uploadedImages[props.selectedImageId.id].userImage.imageFile) {
                reduceImage(props.selectedImageId.id, false);
            } else {
                reduceImage(props.selectedImageId.id, true);
            }
            const hitArea = new Rectangle(-(placeholderSprite.width / 2), -(placeholderSprite.height / 2), placeholderSprite.width, placeholderSprite.height);
            placeholderSprite.hitArea = hitArea;
            deformerList.current[props.selectedImageId.id].hitAreaOriginalPosition = {
                x: -(placeholderSprite.width / 2),
                y: -(placeholderSprite.height / 2),
                width: placeholderSprite.width,
                height: placeholderSprite.height
            }
            props.endCenterImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.centerImage])

    const onImageSelect = (id: string) => {
        const placeholderSprite = id && deformerList.current[id].placeholder.placeholderSprite;
        const bounds = placeholderSprite && placeholderSprite.getBounds(true);
        const width = bounds.width;
        const height = bounds.height;
        const x = bounds.x;
        const y = bounds.y;
        props.setSelectedImageId(id, placeholderSprite && placeholderSprite.width, { width, height, x, y });
        props.setIsEditing();
    }

    const isMultiImage = () => {
        return props.mockup.canvasLayers.deformerImage.length > 1
    }

    const dragEvent = (id: string) => {
        if (isMultiImage()) {
            const movementX = deformerList.current[id].placeholder.dragStart.x - deformerList.current[id].placeholder.placeholderSprite.x;
            const movementY = deformerList.current[id].placeholder.dragStart.y - deformerList.current[id].placeholder.placeholderSprite.y;
            const placeholder = deformerList.current[id].placeholder.placeholderSprite;

            const hitArea = new Rectangle(deformerList.current[id].hitAreaOriginalPosition.x + movementX, deformerList.current[id].hitAreaOriginalPosition.y + movementY, deformerList.current[id].hitAreaOriginalPosition.width, deformerList.current[id].hitAreaOriginalPosition.height);
            placeholder.hitArea = hitArea;

            deformerList.current[id].hitAreaOriginalPosition = {
                x: deformerList.current[id].hitAreaOriginalPosition.x + movementX,
                y: deformerList.current[id].hitAreaOriginalPosition.y + movementY,
                width: deformerList.current[id].hitAreaOriginalPosition.width,
                height: deformerList.current[id].hitAreaOriginalPosition.height
            }
        }
    }

    const startEvent = (id: string) => {
        if (isMultiImage()) {
            deformerList.current[id].placeholder.dragStart = {
                x: deformerList.current[id].placeholder.placeholderSprite.x,
                y: deformerList.current[id].placeholder.placeholderSprite.y
            }
        }
    }

    const configMobile = (canvasW) => {
        const container = canvas_container.current;
        container.style.transform = `scale(${window.innerWidth / canvasW})`;
    }

    // useEffect(() => {
    //     // if(props.isMobile && !props.isZoomedIn){
    //     //     const container = canvas_container.current;
    //     //     container.style.transform = `scale(0.4) translate(0px, 0px)`;
    //     // }
    // }, [props.isZoomedIn])

    // const aniateZoomInMobile = (placeholderSprite: Sprite) => {
    //     props.setZoomIn();
    //     const DEBUG = false;
    //     const container = canvas_container.current;
    //     const contW = container.clientWidth;
    //     const contH = container.clientHeight;
    //     const bounds = placeholderSprite.getBounds(true);
    //     const PivotX = bounds.x + (bounds.width / 2);
    //     const PivotY = bounds.y + (bounds.height / 2);
    //     const amountX = PivotX > (contW / 2) ? ((PivotX - (contW / 2)) * -1) : ((contW / 2) - PivotX);
    //     const amountY = PivotY > (contH / 2) ? ((PivotY - (contH / 2)) * -1) : ((contH / 2) - PivotY);
    //     container.style.transform = `scale(1) translate(${amountX}px, ${amountY}px)`;
    //     if(DEBUG){
    //         const div = document.createElement("div");
    //         div.style.position = "absolute";
    //         div.style.top = "0";
    //         div.style.left = "0";
    //         div.style.zIndex = "100";
    //         div.style.width = "30px";
    //         div.style.height = "30px";
    //         div.style.backgroundColor = "red";
    //         div.style.transform = `translate(${bounds.x + (bounds.width / 2)}px, ${bounds.y + (bounds.height / 2)}px)`;
    //         container.appendChild(div);
    //     }
    // }

    const getIsEditing = useRef(() => {
        return IsEditing.current
    })

    const getTouchVelocity = useRef(() => {
        return props.getTouchVelocity()
    })

    const getSelectedId = useRef(() => {
        return selectedId.current
    })

    useEffect(() => {
        let isSuscribed = true;
        const setup = async () => {
            try {
                loader.current = new Loader();
                hiResLoader.current = new Loader();
                dinamycUserImageLoader.current = new Loader();
                dinamycBackgroundImageLoader.current = new Loader();
                dimensions.current = await canvasDimensions(props.mockup.layers.baseImages[props.backgroundSelected], props.mockup.layers.deformerImage[0], props.relativeElement, props.isMobile);
                canvas_container.current.style.height = `${dimensions.current.canvasHeight}px`;
                await idleCallback()
                const dimensionAlias = dimensions.current;
                props.setOriginalSize(dimensionAlias.originalWidth, dimensionAlias.originalHegiht)
                await idleCallback()
                await loadTextures(loader.current, props.mockup.canvasLayers, props.backgroundSelected, isSuscribed, undefined, undefined, props.mockup.canvasLayersWatermark.shadowsAndLightsImage, props.mockup.watermark);
                await idleCallback()
                if (isSuscribed) {
                    app.current = appSetup(dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, canvas_container.current);
                    if (props.isMobile) {
                        configMobile(dimensionAlias.canvasWidth);
                    }
                    await idleCallback()
                    app.current.view.setAttribute("name", props.mockup.title);
                    baseSprite.current = addBaseLayer(app.current.stage, loader.current.resources[textureNames.base], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                    await idleCallback()
                    const colorMask = addMaskLayer(app.current.stage, loader.current.resources[textureNames.colorMask], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                    colorSprite.current = addColorLayer(app.current.stage, colorMask, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, props.backgroundColor || props.baseColor);
                    await idleCallback()
                    filter.current = buildFilter(app.current.stage, loader.current.resources[textureNames.displacement], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                    await idleCallback()
                    let index = 0;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    for (const _defImage of props.mockup.canvasLayers.deformerImage) {
                        const id = uuidv4() as string;
                        const mask = addMaskLayer(app.current.stage, loader.current.resources[textureNames.logoMask + '-' + index], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                        const userImageContainer = buildContainer(app.current.stage, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, mask);
                        const spriteAndHitArea = await setupPlaceholderLayer(app.current, userImageContainer, canvas_container.current, dimensionAlias, loader.current.resources[textureNames.deformer + '-' + index]);
                        const placeholderSprite = addPlaceholderLayer(spriteAndHitArea, loader.current.resources[textureNames.placeholder + '-' + index], filter.current, canvas_container.current, true, () => dragEvent(id), undefined, id, onImageSelect, () => startEvent(id), getIsEditing.current, getTouchVelocity.current, getSelectedId.current);
                        const highlightSprite = addHighlightLayer(app.current.stage, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, mask);

                        // If Multi-image then limit the hit area
                        if (props.mockup.canvasLayers.deformerImage.length > 1) {
                            const hitArea = new Rectangle(-(placeholderSprite.width / 2), -(placeholderSprite.height / 2), placeholderSprite.width, placeholderSprite.height);
                            placeholderSprite.hitArea = hitArea;
                        }

                        deformerList.current[id] = {
                            id,
                            deformerBaseSprite: spriteAndHitArea,
                            placeholder: {
                                placeholderOriginalWidth: placeholderSprite.width,
                                placeholderOriginalHeight: placeholderSprite.height,
                                placeholderSprite: placeholderSprite,
                                originalPlaceholderURL: loader.current.resources[textureNames.placeholder + '-' + index].url,
                                dragStart: {
                                    x: placeholderSprite.x,
                                    y: placeholderSprite.y
                                }
                            },
                            highlight: highlightSprite,
                            hitAreaOriginalPosition: {
                                x: -(placeholderSprite.width / 2),
                                y: -(placeholderSprite.height / 2),
                                width: placeholderSprite.width,
                                height: placeholderSprite.height
                            }
                        }
                        index = index + 1;
                    }
                    await idleCallback()
                    const shadowsAndLightsTexture = props.watermark ? loader.current.resources[textureNames.shadowsAndLightsWatermark] : loader.current.resources[textureNames.shadowsAndLights]
                    lightSahdowsSprite.current = addShadowsAndLightsLayer(app.current.stage, shadowsAndLightsTexture, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                    watermarkSprite.current = addWatermarkLayer(app.current.stage, loader.current.resources[textureNames.specialWatermark], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
                    await idleCallback()
                    const filters = setupFilters(app.current.stage);
                    filterIG.current = filters.filterIg;
                    filterCTR.current = filters.filterCtr;
                    filterOldFilm.current = filters.filterOldFilm;
                    setloadingImages(false);
                    props.setMockupReady();
                    props.deformerImagesLoaded(Object.keys(deformerList.current))
                }
            } catch (error) {
                console.error("Error generating mockup", error);
                Sentry.setTag("Source", "Mockup viewer")
                Sentry.captureException(error)
                if (isSuscribed) {
                    setloadingImages(false);
                    setHasError(true);
                    props.setMockupReady();
                }
            }
        }

        const setupResult = setup();

        return async () => {
            await setupResult
            clean(app.current, loader.current, dinamycUserImageLoader.current, dinamycBackgroundImageLoader.current);
            isSuscribed = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (lightSahdowsSprite.current && loader.current.resources[getTextureNames().shadowsAndLightsWatermark] && props.watermark) {
            lightSahdowsSprite.current.texture = loader.current.resources[getTextureNames().shadowsAndLightsWatermark].texture
        } else if (lightSahdowsSprite.current && loader.current.resources[getTextureNames().shadowsAndLights] && !props.watermark) {
            lightSahdowsSprite.current.texture = loader.current.resources[getTextureNames().shadowsAndLights].texture
        }
    }, [props.watermark])

    useEffect(() => {
        if (props.mockupReady && watermarkSprite.current) {
            if (props.watermark) {
                watermarkSprite.current.visible = true;
            } else {
                watermarkSprite.current.visible = false;
            }
        }
    }, [props.mockupReady, props.watermark])

    useEffect(() => {
        Object.keys(deformerList.current).forEach((id) => {
            deformerList.current[id].highlight.alpha = 0;
        })
        if (props.highlightedImage && deformerList.current[props.highlightedImage]) {
            deformerList.current[props.highlightedImage].highlight.alpha = 0.6;
        }
    }, [props.highlightedImage])

    useEffect(() => {
        const exe = async () => {
            if (deformerList.current) {
                const idsOfChangedUploads = Object.keys(props.uploadedImages).filter((id) => props.uploadedImages[id] !== previousUploadedImages.current[id]);
                previousUploadedImages.current = props.uploadedImages;
                for (const id of idsOfChangedUploads) {
                    if (props.uploadedImages[id].userImage.imageFile) {
                        await reduceImageExperiment(id, false);
                    } else {
                        await reduceImageExperiment(id, true);
                    }
                }
            }
        }
        exe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadedImages])

    useEffect(() => {

        if (props.backgroundSelected !== null
            && props.backgroundSelected !== undefined
        ) {
            updateBackgroundImage(props.mockup.canvasLayers.baseImages[props.backgroundSelected])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.backgroundSelected])

    useEffect(() => {
        const download = async () => {
            for (const id of Object.keys(props.uploadedImages)) {
                await changePlaceholderImage(props.uploadedImages[id].userImage.base64, dinamycUserImageLoader.current, deformerList.current[id].placeholder.placeholderSprite, deformerList.current[id].deformerBaseSprite);
                deformerList.current[id].highlight.alpha = 0;
            }
            await downloadImage(
                app.current,
                dimensions.current,
                filter.current,
                props.mockup,
                props.downloadSize,
                props.handleErrorModal,
                hiResLoader.current,
                loader.current,
                dinamycBackgroundImageLoader.current,
                props.backgroundSelected,
                baseSprite.current,
                lightSahdowsSprite.current,
                props.watermark
            );
            props.endDownload();
        }

        if (props.startDownload) {
            download();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startDownload])

    return {
        loadingImages,
        canvas_container,
        hasError,
        applyFilter,
        dimensions
    }
}

const MockupViewer: React.FC<Props> = (props) => {
    const [optimizing, setoptimizing] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loadingImages, canvas_container, hasError, applyFilter, dimensions } = useMockupViewer(props, setoptimizing);
    return (
        <MockupViewerWrapper>
            {
                hasError && <ErrorBox label="Error on setting up the mockup" />
            }
            {
                optimizing && !loadingImages && <div className="optimizing"><DownloadSpinner label="Optimizing image" /></div>
            }
            {
                props.startDownload && <DownloadSpinner label="Downloading image" />
            }
            {
                loadingImages && <DownloadSpinner label="Mockup setup" />
            }
            {
                <div
                    style={{
                        zIndex: 0,
                        display: props.startDownload || loadingImages || hasError ? 'none' : 'block',
                        userSelect: optimizing ? 'none' : 'all'
                    }}
                    ref={(ref) => { canvas_container.current = ref; props.setCanvasRef(ref) }} className="container" />
            }
        </MockupViewerWrapper>
    )
}

export default MockupViewer;
  