import React from "react";
import ErrorMessage from "../../login/ErrorMessage";
import styled from 'styled-components';

type Props = {
    placeholder: string,
    errorMessages: string[],
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    type: 'text' | 'password'
}

const InputTextWrapper = styled.section`
.input-text {
    border: none;
    background: none;
    background-color: #E8F0FE;
    border-radius: 30px;
    font-size: 1.6rem;
    width: 100%;
    padding-left: 3rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
}
.input-error {
    margin-left: 1rem;
}
.input-error:first-of-type {
    margin-top: 0.5rem;
}
`

const InputText = (props: Props) => {
    const { errorMessages, handleChange, type, placeholder } = props;
    return (
        <InputTextWrapper>
            <input className="input-text" onChange={handleChange} type={type} placeholder={placeholder} />
            { errorMessages.length > 0 &&
                errorMessages.map((errorMessage, index) => {
                    return <ErrorMessage className="input-error" key={index} message={errorMessage}/>
                })
            }
        </InputTextWrapper>
    )
}
export default InputText;
