import Cookies from "universal-cookie";
import { isDevelopment } from "./EnvHelper";

export const dataLayerPush = async (eventName?: string, newData?: any) => {
  const cookies = new Cookies();
  const userId = cookies.get("vx.user_id") || undefined;
  const user = sessionStorage.getItem("user");

  const payload = {
    event: eventName,
    data: newData,
    user_id: userId,
  };

  if (user) {
    payload["user"] = JSON.parse(user);
  }

  if (!isDevelopment) {
    window.dataLayer.push(payload);
  }
  return;
};
