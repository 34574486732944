import React from "react"
import styled from "styled-components"
import { useSetRecoilState } from "recoil"
import { mockupState, MockupState } from "../../../Recoil/RecoilState"
import { ReactComponent as Center } from "../../../assets/icons/center.svg"
import produce from "immer"
import { screenSize } from "../../../stylesGlobal/Variables"
import { sendEvent } from "../../../utils/Events"

const CenterImageWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:var(--color-white);
    margin-right: 6%;

    .centerImage__label{
        font-size:1.3rem;
        margin-right:1em;
        font-weight:500;
    }

    .centerImage__button{
        display: flex;
        border:none;
        background:none;
        cursor:pointer;

        svg{
            fill:#707070;
            transition:fill .3s;
            width:2rem;
        }

        &:focus{
            outline:none;
        }

        &:hover{
            svg{
                fill:var(--color-white);
            }
        }
    }
    @media (min-width: ${screenSize.size_m}){
        margin-right: 7%;
    }
    @media (min-width: ${screenSize.size_xl}){
        margin-right: 8%;
    }
`

const CenterImage = () => {
    const setMockupGeneralState = useSetRecoilState(mockupState)

    const handleClick = () => {
        sendEvent('MG - Toolbar', 'backToCenter');
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.images[draftState.selectedImage.id].rotationDegrees = 0;
                draftState.mockup.images[draftState.selectedImage.id].imageScale = draftState.mockup.images[draftState.selectedImage.id].defaultScale;
                draftState.mockup.images[draftState.selectedImage.id].centerImage = true;
            })
        )
    }

    return (
        <CenterImageWrapper>
            <p className="centerImage__label">back to center</p>
            <button
                type="button"
                className="centerImage__button"
                onClick={handleClick}
            >
                <Center />
            </button>
        </CenterImageWrapper>
    )
}

export default CenterImage