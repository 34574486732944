import React, { useState } from "react";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import styled from "styled-components"

const SocialsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2.5em;
`

type Props = {
    isLogin: boolean,
}

const Socials = (props: Props) => {
    return (
        <SocialsWrapper>
            <GoogleButton isLogin={props.isLogin} />
            <FacebookButton isLogin={props.isLogin} />
        </SocialsWrapper>
    );
};

export default Socials;
