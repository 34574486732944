import { MockupState, mockupState } from "../../Recoil/RecoilState";

import { ApiClient } from "../../api/ApiClient";
import FacebookLogin from "react-facebook-login";
import React from "react";
import produce from "immer";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const FacebookWrapper = styled.div`
  .button {
    border: none;
    font: inherit;
    color: white;
    background-color: #4072fb;
    padding: 1em;
    border-radius: 40px;
    font-size: 14px;
  }
  .fa-facebook {
    margin-right: 10px;
  }
`;

type Props = {
  isLogin: boolean;
};

const FacebookButton = (props: Props) => {
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const [mockupGeneralState, setMockupGeneralState] =
    useRecoilState(mockupState);

  const responseFacebook = async (facebookRes) => {
    try {
      if (facebookRes.status === "unknown" || !facebookRes.accessToken) return;
      const res = await ApiClient.loginFacebook(facebookRes.accessToken);
      if (res.error) return;
    } catch (error) {
      console.error("FacebookButton/responseFacebook", error);
      return;
    }

    try {
      const { userName, userAvatar, userPlanType, watermark, isSubscriptor, hasMockupsAccess, hasRemainingDownloads } =
        await ApiClient.getUserInfo();
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.user = {
            loggedIn: true,
            token: "",
            name: userName,
            avatar: userAvatar,
            planType: userPlanType,
            watermark,
            isSubscriptor,
            hasMockupsAccess,
            hasRemainingDownloads,
          };
        })
      );
    } catch (error) {
      console.error("FacebookButton/GetUserInfo", error);
      return;
    }

    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.modal = {
          open: false,
          type: undefined,
        };
      })
    );
  };

  return (
    <FacebookWrapper className="button">
      <FacebookLogin
        cssClass="button kep-login-facebook py-input my-2"
        appId={facebookAppId}
        fields="name,email,picture"
        scope="public_profile,user_friends"
        callback={responseFacebook}
        icon="fa-facebook"
        textButton={
          props.isLogin ? "Log in with Facebook" : "Sign up with Facebook"
        }
      />
    </FacebookWrapper>
  );
};

export default FacebookButton;
