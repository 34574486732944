import React from 'react';
import { ReactComponent as ExpandArrow } from "../../../assets/icons/expand_arrow.svg"
import styled from 'styled-components';
import { sendEvent } from '../../../utils/Events';

interface Props {
    isOpen: boolean,
    onClick: () => void
}

const ExpandWrapper = styled.button`
    position: absolute;
    right: 2rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transform: ${(props: Props) => props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};

    svg path {
        stroke: #707070;
        transition: stroke .3s;
    }

    :hover {
        svg path {
            stroke: var(--color-white);
        }  
    }
`

const Expand: React.FC<Props> = (props) => {
    const handleExpand = () => {
        sendEvent('MG - Toolbar', 'collapseRelated');
        props.onClick();
    }
    return (
        <ExpandWrapper {...props} onClick={handleExpand}>
            <ExpandArrow />
        </ExpandWrapper>
    )
}

export default Expand;