import React from "react"
import styled from "styled-components"
import isMobile from 'ismobilejs';

type TabWrapperProps = {
    active: boolean,
    disabled: boolean
}

const TabWrapper = styled.li<TabWrapperProps>`
    display:flex;
    align-items: center;
    justify-content: center;
    width:5rem;
    height: ${isMobile(window.navigator).any ? "100%" : "5.2rem"};
    position:relative;

    cursor:${(p) => p.disabled ? "not-allowed" : "pointer"};
    user-select:${(p) => p.disabled ? "none" : "auto"};
    ${(p) => p.active && !isMobile(window.navigator).any ? 
        `&:before {
            content : "";
            position: absolute;
            left: calc(100% - 2px);
            bottom: 0;
            height: 64%;
            width: 0;
            border-right: 2px solid var(--color-blue);
            top: 18%;
    }` : ""}

    svg{
        z-index:var(--zIndex-low);
        display:inline-block;
        fill:${(p) => p.disabled
        ? "var(--color-light-gray)"
        : p.active || isMobile(window.navigator).any
            ? "var(--color-blue)"
            : "var(--color-strong-gray)"
        };
        width: 2.2rem;
    };
    min-width:1.8rem;
    min-height:1.8rem;
    }
`

type Props = {
    active: boolean,
    onClick: () => void,
    onClickDisabled?: () => void,
    children: React.ReactNode,
    disabled: boolean,
}

const TabIcon = (props: Props) => {

    const handleOnClick = () => {
        if (!props.disabled) {
            props.onClick()
        } else if (props.onClickDisabled){
            props.onClickDisabled();
        }
    }
    return (
        <TabWrapper
            title={props.disabled ? "This feature is not available for this mockup" : ""}
            active={props.active}
            disabled={props.disabled}
            onClick={handleOnClick}>
            {props.children}
        </TabWrapper>
    )
}

export default TabIcon