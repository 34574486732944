import React, { useEffect } from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import { Router } from "react-router";
import Editor from './pages/Editor';
import Home from './pages/Home';
import './stylesGlobal/GlobalStyles.css';
import './stylesGlobal/GlobalVariables.css';
import { paths } from './utils/Router/RoutePaths';
import history from './utils/history';
import ReactGA from 'react-ga';
import { isDevelopment } from './utils/EnvHelper';

const App: React.FC = () => {
  useEffect(() => {
    if (!isDevelopment){
      history.listen((location) => {
        ReactGA.pageview(location.pathname + location.search);
      })
    }
  }, [])
  return (
    <Router history={history}>
      <Switch>
        <Route path={paths.mockup(":mockupName", ":mockupId")}>
          <Editor />
        </Route>
        <Route path="/" exact={true}>
          <Home />
        </Route>
        <Route path="/mockup" exact={true}>
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
