import React, { useEffect, useState } from 'react';
import { MockupViewModelWithLoading, RENDER_STATE } from './RelatedMockups';
import MockupThumbnailViewer from '../../../smartCanvas/MockupThumbnailViewer';
import styled from 'styled-components';
import Spinner from '../../spinner/Spinner';
import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/Router/RoutePaths';
import { sendEvent } from '../../../../utils/Events';
import { ReactComponent as Crown } from "../../../../assets/icons/crown.svg"

interface Props {
    isSelected: boolean,
    uploadedImage: string,
    mockup: MockupViewModelWithLoading,
    onReady: (mockupId: number) => void,
    previousMockupState: RENDER_STATE,
    hasMockupsAccess: boolean,
}

type RelatedMockupWrapperProps = {
    isSelected: boolean,
}
const RelatedMockupWrapper = styled.div<RelatedMockupWrapperProps>`
    border-radius:.5rem;
    border: ${props => props.isSelected ? 'solid 2px var(--color-blue)' : 'solid 2px #efefef'};
    margin-bottom: 2rem;
    cursor:pointer;
    transform-origin:center;
    transition:transform .3s;
    overflow: hidden;
    position: relative;
    min-height:7rem;

    &:hover{
        transform:scale(1.03);
    }

    a {
        height: 100%;
        display: block;
    }

    img {
        display: block;
        width: 100%;
    }

    .spinner-container {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .crown-container{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 10px;
        margin-right: 10px;
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 7px;
        z-index: 2;
    }
    .crown {
        width: 20px;
        height: 20px;
        margin: 5px;
        display: block;
    }
`

const RelatedMockup: React.FC<Props> = (props) => {
    const [loading, setloading] = useState(false)
    const [thumb, setthumb] = useState({ image: props.mockup.thumbnailsLayers.baseImages[0], inverted: false })

    useEffect(() => {
        if (props.previousMockupState === RENDER_STATE.RENDERED) {
            setloading(true);
        }
    }, [props.previousMockupState])

    useEffect(() => {
        if (props.mockup.state === RENDER_STATE.LOADING) {
            setloading(true);
        }
    }, [props.mockup])

    const finishedRendering = (renderedImage: string, inverted?: boolean) => {
        setthumb({ image: renderedImage, inverted: inverted });
        setloading(false);
        props.onReady(props.mockup.id);
    }

    const isPremium = props.mockup.premium && !props.hasMockupsAccess;

    return (
        <RelatedMockupWrapper isSelected={props.isSelected}>
            <Link to={paths.mockup(props.mockup.title, props.mockup.id)} onClick={() => sendEvent('MG - Related Column', 'slectRelated')}>
                <img src={props.uploadedImage ? thumb.image : props.mockup.thumbnailsLayers.baseImages[0]} alt="Thumbnail" style={{ transform: thumb.inverted ? 'rotate3d(1, 0, 0, 180deg)' : '' }} />
                {
                    loading && <MockupThumbnailViewer ready={finishedRendering} mockup={props.mockup} uploadedImage={props.uploadedImage} />
                }
                {
                    loading && <div className="spinner-container"><Spinner /></div>
                }
            </Link>
            {isPremium &&
                <div className="crown-container">
                    <Crown className="crown" />
                </div>
            }
        </RelatedMockupWrapper>
    )
}

export default RelatedMockup;