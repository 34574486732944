import {useRouteMatch } from "react-router-dom"

export const useSiteRouteMatch = () => {
    const match = useRouteMatch<{
        mockupName: string,
        mockupId: string,
    }>()

    const parsedMockupId = parseInt(match.params.mockupId,10)

    return parsedMockupId
}
