import produce from 'immer';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { MockupWithImagesViewModel } from '../../../../api/clientInterfaces/MockupModel';
import { FILTER, MockupState, mockupState } from '../../../../Recoil/RecoilState';
import { ENUM_FILTER, Filters } from '../../../smartCanvas/FiltersFunctions';
import TabTitle from '../TabTitle';
import { motion, AnimateSharedLayout } from "framer-motion";
import isMobile from 'ismobilejs'

type FiltersWrapperProps = {
    isMobile?: boolean,
}

const FiltersWrapper = styled.section<FiltersWrapperProps>`

.filter__list{
    display:flex;
    justify-content: ${props => props.isMobile ? 'flex-start' : 'center'};
    flex-direction: ${props => props.isMobile ? 'row' : 'column'};
    padding:2rem 0;
    overflow-x: ${props => props.isMobile ? 'auto' : 'initial'};
    ${(props) => props.isMobile ? css`
        padding-left: 15px;
    `: null}

    .filter__container {
        margin-bottom:2rem;
        flex: ${props => props.isMobile ? '0 0 auto' : 'none'} ;
        width: ${props => props.isMobile ? 50 : 100}%;
        ${(props) => props.isMobile ? css`
        width: 40%;
        margin-right: 15px;
        margin-bottom: 0;
    `: null}
    }

    .filter__divider {
        height: 1px;
        background: black;
        opacity: 0.2;
    }

    .filter__range {
        margin-bottom:2rem;
        width: 100%;
        -webkit-appearance: none;
        height: 5px;
        outline: none;
        cursor: pointer;
        border-radius: 5px;

        &:focus{
            outline:none;
            border:none;
        }

        &::-moz-range-track {
            height: 5px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 13px;
            height: 13px;
            background: var(--color-blue);
            cursor: pointer;
            border-radius: 100%;
            transition:transform .2s;

            &:hover{
                transform:scale(1.1);
            }
        }

        &::-moz-range-thumb {
            background: var(--color-blue);
            height: 13px;
            width: 13px;
            border:none;
            border-radius: 100% !important;
            box-sizing: border-box;
            transition:transform .2s;

            &:hover{
                transform:scale(1.1);
            }
        }

        &::-ms-thumb { 
            background: var(--color-blue);
            border:none;
            height: 13px;
            width: 13px;
            border-radius:100%;
            box-sizing: border-box;
            transition:transform .2s;

            &:hover{
                transform:scale(1.1);
            }
        }

        &::-ms-fill-lower { 
            background: #7A9AF6;
        }

        &::-ms-ticks-after { 
            display: none; 
        }

        &::-ms-ticks-before { 
            display: none; 
        }

        &::-ms-track { 
            background: #D6D6D6;
            color: transparent;
            height: 4px;
            border: none;
        }

        &::-ms-tooltip { 
            display: none;
        }
    }

    .slider::-moz-range-thumb {
        width: 16px;
        height: 16px;
        background: var(--color-blue);
        cursor: pointer;
    }

    .filter__image{
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: 0.5rem;
        margin-bottom:2rem;

        img{
            display: block;
            margin: 0;
            border: 0;
        }

        label {
            color: white;
            padding: 5px;
            min-width: 66px;
            text-align: center;
            border-radius: 4px;
            background: #0000007a;
            position: absolute;
            left: 5px;
            top: 5px;
            backdrop-filter: blur(6px);
            z-index: 1;
        }
    }

    .active{
        border:2px solid var(--color-blue);
    }
}

.filter_1997 {
    filter: sepia(1) hue-rotate(311deg);
}
.filter_amaro {
    filter: contrast(1) brightness(1.4);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #c59e005c;
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
    }
}
.filter_ashby {
    filter: contrast(1) brightness(1.3);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #c59e005c;
        background-image: radial-gradient(transparent, #00000069);
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
    }
}
.filter_brooklyn {
    filter: contrast(1) brightness(1.2) hue-rotate(0deg) saturate(1.3);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #2baaff5e;
        background-image: radial-gradient(transparent 34%,#0000003b 116%);
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
    }
}
.filter_ginham {
    filter: contrast(1) brightness(1.2);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #e6e6e6;
        background-image: radial-gradient(transparent 34%,#0000003b 83%);
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
    }
}
.filter_ginza {
    filter: contrast(1) brightness(1.2) hue-rotate(360deg) saturate(1);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #ffd585;
        background-image: radial-gradient(transparent 34%,#0000003b 83%);
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
    }
}
.filter_inkwell {
    filter: grayscale(1);
}
.filter_lofi {
    filter: contrast(1.1) brightness(1.1);
}
.filter_maven {
    filter: grayscale(0.6) contrast(0.9) brightness(1) saturate(1) hue-rotate(-12deg);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: radial-gradient(transparent 34%,#0000003b 83%);
        position: absolute;
        top: 0;
    }
}
.filter_nashville {
    filter: contrast(1.3) brightness(1);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#b3650080,#693b00a6);
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}
.filter_rise {
    filter: contrast(1.2) brightness(1) saturate(1.2);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: linear-gradient(#00000000,#ffdb5a38);
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}
.filter_skyline {
    filter: contrast(0.9) brightness(1) saturate(2.5) hue-rotate(-1deg);
}
.filter_valencia {
    filter: contrast(1.3) brightness(1);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background: #e6c13d36;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}
.filter_walden {
    filter: contrast(1.3) brightness(1) saturate(1.2);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background:#e5f08042;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}
.filter_xpro {
    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: radial-gradient(transparent 34%,#0000003b 83%);
        position: absolute;
        top: 0;
    }
}

.filter_vexelsone {
    filter: contrast(1.2) brightness(1.1) saturate(1);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background:linear-gradient(45deg, #ff0000, #ffc722);
        opacity: 0.5;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}

.filter_vexelstwo {
    filter: contrast(1.2) brightness(1.1) saturate(1) hue-rotate(-19deg);

    ::before {
        content: '';
        width: 100%;
        height: 100%;
        background:radial-gradient(#ffffff, #4c36ff);
        opacity: 0.5;
        mix-blend-mode: screen;
        position: absolute;
        top: 0;
    }
}
`

interface Props {
    loading: boolean,
    selectedMockup: MockupWithImagesViewModel,
}

const FiltersList: React.FC<Props> = (props) => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)

    const handleFilterSelection = (filter: FILTER) => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.filter = filter;
                draftState.filterIntensity = 100;
            })
        )
    }

    const getFilter = (filterName: string) => {
        switch (filterName) {
            case ENUM_FILTER.FILTER_1997:
                return 'filter_1997'
            case ENUM_FILTER.FILTER_AMARO:
                return 'filter_amaro'
            case ENUM_FILTER.FILTER_ASHBY:
                return 'filter_ashby'
            case ENUM_FILTER.FILTER_BROOKLYN:
                return 'filter_brooklyn'
            case ENUM_FILTER.FILTER_GINHAM:
                return 'filter_ginham'
            case ENUM_FILTER.FILTER_GINZA:
                return 'filter_ginza'
            case ENUM_FILTER.FILTER_INKWELL:
                return 'filter_inkwell'
            case ENUM_FILTER.FILTER_LOFI:
                return 'filter_lofi'
            case ENUM_FILTER.FILTER_MAVEN:
                return 'filter_maven'
            case ENUM_FILTER.FILTER_NASHVILLE:
                return 'filter_nashville'
            case ENUM_FILTER.FILTER_RISE:
                return 'filter_rise'
            case ENUM_FILTER.FILTER_SKYLINE:
                return 'filter_skyline'
            case ENUM_FILTER.FILTER_VALENCIA:
                return 'filter_valencia'
            case ENUM_FILTER.FILTER_WALDEN:
                return 'filter_walden'
            case ENUM_FILTER.FILTER_XPRO:
                return 'filter_xpro'
            case ENUM_FILTER.FILTER_VEXELS_ONE:
                return 'filter_vexelsone'
            case ENUM_FILTER.FILTER_VEXELS_TWO:
                return 'filter_vexelstwo'
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const scaleValue = parseInt(event.currentTarget.value, 10);
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.filterIntensity = scaleValue;
            })
        )
    }

    const renderFilters = () => {
        return Filters.map((filter, index) => {
            return (
                <li className={"filter__container"}>
                    <motion.div layout transition={{ ease: "easeOut", duration: 0.16 }} onClick={() => handleFilterSelection(Filters[index])} className={`filter__image ${mockupGeneralState.filter.filterType === filter.filterType ? "active" : ""}`}>
                        <motion.label>{filter.name}</motion.label>
                        <motion.div className={`${getFilter(filter.filterType)}`}>
                            <motion.img src={props.selectedMockup.thumbnailsLayers.baseImages[mockupGeneralState.mockup.baseImageIndex]} alt="Base selected" />
                        </motion.div>
                    </motion.div>
                    {
                        mockupGeneralState.filter.filterType === filter.filterType && mockupGeneralState.filter.filterType !== ENUM_FILTER.NONE && <React.Fragment>
                            <motion.input
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="filter__range"
                                type="range"
                                min="1"
                                max="100"
                                value={mockupGeneralState.filterIntensity.toString()}
                                onChange={handleChange}
                                style={{
                                    background: `linear-gradient(to right, #7A9AF6 0%, #7A9AF6 ${mockupGeneralState.filterIntensity}%, #D6D6D6 ${mockupGeneralState.filterIntensity}%, #D6D6D6 100%)`
                                }} />
                            {!isMobile(window.navigator).any && <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.2 }}
                                exit={{ opacity: 0 }} className="filter__divider" />}
                        </React.Fragment>
                    }
                </li>
            )
        })
    }

    return (
        <FiltersWrapper isMobile={isMobile(window.navigator).any}>
            <AnimateSharedLayout>
                <TabTitle
                    title="Filters"
                />
                <ul className="filter__list">
                    {!props.loading && props.selectedMockup && renderFilters()}
                </ul>
            </AnimateSharedLayout>
        </FiltersWrapper>
    )
}

export default FiltersList;