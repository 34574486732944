import React from "react"
import styled from "styled-components"
import Spinner from "../spinner/Spinner"

export enum ButtonColor {
    green = "GREEN",
    blue = "BLUE",
    red = "RED",
    pink = "PINK"
}

const getButtonColor = (color: ButtonColor) => {
    switch (color) {
        case ButtonColor.red:
            return "var(--color-red)";
        case ButtonColor.green:
            return "var(--color-green)";
        case ButtonColor.blue:
            return "var(--color-blue)";
        case ButtonColor.pink:
            return "var(--color-pink)";
    }
}

const getButtonHoverColor = (color: ButtonColor) => {
    switch (color) {
        case ButtonColor.red:
            return "var(--color-button-red-hover)";
        case ButtonColor.green:
            return "var(--color-button-green-hover)";
        case ButtonColor.blue:
            return "var(--color-button-blue-hover)";
        case ButtonColor.pink:
            return "var(--color-button-pink-hover)";
    }
}

type ButtonWrapperProps = {
    large: boolean,
    color: ButtonColor,
    borderRadius?: String,
    padding?: String,
    gap?: String
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
    border:none;
    padding:1rem ${(p) => p.large ? "5rem" : "2rem"};
    ${({ padding }) => `padding: ${padding || ''}`};
    border-radius:${({ borderRadius }) => borderRadius ? `${borderRadius}` : "1.5rem"};
    color:var(--color-white);
    font-size:1.4rem;
    display:flex;
    gap: ${({ gap }) => gap ? `${gap}` : '1.5rem'};
    justify-content:center;
    align-items:center;
    background-color:${(p) => getButtonColor(p.color)};
    cursor:pointer;
    transition:background-color .3s;

    svg{
        fill:var(--color-white);
        width:1.7rem;
    }

    &:focus,&:hover{
        outline:none;
        background-color:${(p) => getButtonHoverColor(p.color)};
    }

    &:disabled{
        cursor:not-allowed;
        svg{
            fill:var(--color-button-disabled-text);
        }
        background-color:var(--color-button-disabled-background);
        color:var(--color-button-disabled-text);
    }
`

type Props = {
    color: ButtonColor,
    label: string,
    onClick: () => void,
    large?: boolean,
    disabled?: boolean,
    iconSVG?: JSX.Element,
    loading?: boolean,
    borderRadius?: String,
    padding?: String,
    gap?: String
}

const Button = (props: Props) => {

    return (
        <ButtonWrapper
            large={props.large}
            color={props.color}
            onClick={props.onClick}
            disabled={props.disabled}
            borderRadius={props.borderRadius}
            padding={props.padding}
            gap={props.gap}
        >
            {props.loading
                ? <Spinner />
                : (
                    <>
                        {props.iconSVG
                            && props.iconSVG
                        }
                        {props.label}
                    </>
                )
            }

        </ButtonWrapper>
    )
}

export default Button