import React from "react"
import styled, { css } from 'styled-components';
import isMobile from 'ismobilejs'

type TabTitleWrapperProps = {
    isMobile?: boolean,
}

const TabTitleWrapper = styled.h2<TabTitleWrapperProps>`
    width:100%;
    text-align:center;
    font-weight:500;
    font-size:16px;
    ${(props) => props.isMobile ? css`
    margin-top: 1em;
    `: null}
`

type Props = {
    title: string
}

const TabTitle = (props: Props) => {
    return (
        <TabTitleWrapper isMobile={isMobile(window.navigator).any}>
            {props.title}
        </TabTitleWrapper>
    )
}

export default TabTitle