import { CRTFilter } from '@pixi/filter-crt';
import { OldFilmFilter } from '@pixi/filter-old-film';
import * as PIXI from 'pixi.js';
import { DimensionType } from "../../mockups/MockupTypes";
import placeholder from '../../assets/placeholder.png';
import { createDeformerSpriteAndApply, setDimanesionsOfPlaceholder } from './SmartFunctionsPixiNoTypes';
import { addInteraction } from './Interactions';
import { layerValuesConfiguration } from './Configuration';
import { MockupLayers, MockupWithImagesViewModel } from '../../api/clientInterfaces/MockupModel';
import { UAParser } from "ua-parser-js"
import * as Sentry from "@sentry/browser"
import isMobile from 'ismobilejs';
import { filters } from 'pixi.js'
import { track } from '../../api/track';

const loadImage = (baseImage: string) => {
    return new Promise<{ baseImageWidth: number, baseImageHeight: number }>((resolve, reject) => {
        const image = new Image();
        try {
            image.onload = () => {
                resolve({ baseImageWidth: image.width, baseImageHeight: image.height })
            }
            image.onabort = (ev) => {
                reject(ev)
            }
            image.onerror = (ev) => {
                reject(ev)
            }
            image.src = baseImage;
        } catch (err) {
            reject(err)
        }
    });
}

export const canvasDimensions = async (baseImage: string, deformerImage: string, relativeElement: HTMLElement, isMobile: boolean) => {

    const { baseImageWidth, baseImageHeight } = await loadImage(baseImage);
    const deformerImageDimensions = await loadImage(deformerImage);
    const contextBarHeight = 43
    const element = relativeElement as HTMLElement;
    const bound = element.getBoundingClientRect();
    const elementWidth = isMobile ? 4000 : bound.width;
    const elementHeight = bound.height - contextBarHeight;

    let canvasWidth
    let canvasHeight

    if (baseImageWidth >= baseImageHeight) {
        const imageRatio = baseImageHeight / baseImageWidth;
        canvasWidth = elementWidth * 0.9;
        canvasWidth = canvasWidth > 1000 ? 1000 : canvasWidth;
        canvasHeight = canvasWidth * imageRatio;

        if (canvasHeight > elementHeight) {
            const increaseHeight = canvasHeight - elementHeight
            const increasePercentaje = (increaseHeight / elementHeight * 100) / 100
            canvasWidth = elementWidth * (0.9 - increasePercentaje);
            canvasWidth = canvasWidth > 1000 ? 1000 : canvasWidth;
            canvasHeight = canvasWidth * imageRatio;
        }
    } else {
        const imageRatio = baseImageHeight / baseImageWidth;
        canvasHeight = elementHeight * 0.9;
        canvasHeight = canvasHeight > 1000 ? 1000 : canvasHeight;
        canvasWidth = canvasHeight / imageRatio;

        if (canvasWidth > elementWidth) {
            const increaseWidth = canvasWidth - elementWidth
            const increasePercentaje = (increaseWidth / elementWidth * 100) / 100
            canvasHeight = elementHeight * (0.9 - increasePercentaje);
            canvasHeight = canvasHeight > 1000 ? 1000 : canvasHeight;
            canvasWidth = canvasHeight / imageRatio;
        }
    }

    const widthMultiplayer = baseImageWidth / canvasWidth;
    const heightMultiplayer = baseImageHeight / canvasHeight;

    const dimensionImformation: DimensionType = {
        originalWidth: baseImageWidth,
        originalHegiht: baseImageHeight,
        widthMultiplayer: widthMultiplayer,
        heightMultiplayer: heightMultiplayer,
        canvasWidth: Math.round(canvasWidth),
        canvasHeight: canvasHeight,
        originalDeformerWidth: deformerImageDimensions.baseImageWidth,
        originalDeformerHeight: deformerImageDimensions.baseImageHeight,
    }
    return dimensionImformation;
}


export const canvasThumbnailDimensions = async (
    baseImage: string,
    deformerImage: string,
    width: number,
) => {

    const { baseImageWidth, baseImageHeight } = await loadImage(baseImage);
    const deformerImageDimensions = await loadImage(deformerImage);

    const imageRatio = baseImageHeight / baseImageWidth;
    const canvasWidth = width;
    const canvasHeight = canvasWidth * imageRatio;

    const widthMultiplayer = baseImageWidth / canvasWidth;
    const heightMultiplayer = baseImageHeight / canvasHeight;

    const dimensionImformation: DimensionType = {
        originalWidth: baseImageWidth,
        originalHegiht: baseImageHeight,
        widthMultiplayer: widthMultiplayer,
        heightMultiplayer: heightMultiplayer,
        canvasWidth: Math.round(canvasWidth),
        canvasHeight: canvasHeight,
        originalDeformerWidth: deformerImageDimensions.baseImageWidth,
        originalDeformerHeight: deformerImageDimensions.baseImageHeight,
    }

    return dimensionImformation;
}

export const appSetup = (width: number, height: number, canvas_container: HTMLDivElement, preview?: boolean) => {
    const app = new PIXI.Application({
        backgroundColor: 0x000,
        backgroundAlpha: 0,
        resolution: preview ? 2 : window.devicePixelRatio ? window.devicePixelRatio : 1,
        autoDensity: true,
        width,
        height,
        preserveDrawingBuffer: true
    });

    if (canvas_container) {
        canvas_container.appendChild(app.view);
    }

    return app;
}

export const getTextureNames = (id?: number) => {
    return {
        base: `base${id ? `_${id}` : ""}`,
        displacement: `displacement${id ? `_${id}` : ""}`,
        logoMask: `logoMask${id ? `_${id}` : ""}`,
        colorMask: `colorMask${id ? `_${id}` : ""}`,
        shadowsAndLights: `shadowsAndLights${id ? `_${id}` : ""}`,
        deformer: `deformer${id ? `_${id}` : ""}`,
        placeholder: `placeholder${id ? `_${id}` : ""}`,
        shadowsAndLightsWatermark: `shadowsAndLightsWatermark${id ? `_${id}` : ""}`,
        specialWatermark: `specialWatermark${id ? `_${id}` : ""}`
    }
}

const sortFunction = (a: any, b: any) => {
    return a.order - b.order
}

export const loadTextures = (
    loader: PIXI.Loader,
    mockupLayers: MockupLayers,
    backgroundSelected: number,
    isSuscribed: boolean,
    id?: number,
    uploadedImage?: string,
    shadowsAndLightsWatermark?: string,
    watermarkImage?: string,
) => {
    return new Promise<void>((resolve, reject) => {
        const textureNames = getTextureNames(id)
        if (isSuscribed) {
            loader
                .add(textureNames.base, mockupLayers.baseImages[backgroundSelected])
                .add(textureNames.displacement, mockupLayers.displacementImage)
                .add(textureNames.logoMask, mockupLayers.maskImage[0])
                .add(textureNames.shadowsAndLights, mockupLayers.shadowsAndLightsImage)
                .add(textureNames.placeholder, uploadedImage ? uploadedImage : placeholder)

            const logomask = mockupLayers.maskImage.map((image, index) => {
                return {
                    order: mockupLayers.maskImage.length > 1 ? Number(image.substring(image.lastIndexOf("/") + 1, image.lastIndexOf("-")).split("logomask")[1]) : 1,
                    url: image
                }
            }).sort(sortFunction)

            const dots = mockupLayers.deformerImage.map((image, index) => {
                return {
                    order: mockupLayers.deformerImage.length > 1 ? Number(image.substring(image.lastIndexOf("/") + 1, image.lastIndexOf("-")).split("dots")[1]) : 1,
                    url: image
                }
            }).sort(sortFunction)

            const userImages = mockupLayers.userImage.map((image, index) => {
                return {
                    order: mockupLayers.userImage.length > 1 ? Number(image.substring(image.lastIndexOf("/") + 1, image.lastIndexOf("-")).split("userimage")[1]) : 1,
                    url: image
                }
            }).sort(sortFunction)

            dots.forEach((dot, i) => {
                loader.add(textureNames.deformer + "-" + i, dot.url)
            })

            userImages.forEach((userImage, i) => {
                loader.add(textureNames.placeholder + "-" + i, userImage.url)
            })

            logomask.forEach((logomask, i) => {
                loader.add(textureNames.logoMask + "-" + i, logomask.url)
            })

            if (mockupLayers.colorLayer[0]) {
                loader.add(textureNames.colorMask, mockupLayers.colorLayer[0])
            }

            if (shadowsAndLightsWatermark) {
                loader.add(textureNames.shadowsAndLightsWatermark, shadowsAndLightsWatermark)
            }

            if (watermarkImage) {
                loader.add(textureNames.specialWatermark, watermarkImage)
            }

            loader.load((loader, resources) => {
                resolve();
            })

            loader.onLoad.add((loader, resources) => {
                console.log("Asset loaded")
            })
            loader.onError.add((err, loader, resources) => {
                console.log("Asset error")
                reject(err)
            })
        }
    })
}

export const addBaseLayer = (stage: PIXI.Container, baseResource: PIXI.LoaderResource, width: number, height: number) => {
    const baseSprite = new PIXI.Sprite(baseResource.texture);
    baseSprite.width = width;
    baseSprite.height = height;
    stage.addChild(baseSprite);
    return baseSprite;
}

export const addHighlightLayer = (stage: PIXI.Container, width: number, height: number, mask: PIXI.Sprite) => {
    const highlightSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    highlightSprite.width = width;
    highlightSprite.height = height;
    highlightSprite.tint = 0x008aff;
    highlightSprite.alpha = 0;
    highlightSprite.mask = mask;
    stage.addChild(highlightSprite);
    return highlightSprite;
}

export const addShadowsAndLightsLayer = (stage: PIXI.Container, baseResource: PIXI.LoaderResource, width: number, height: number) => {
    const lightsAndShadowsSprite = new PIXI.Sprite(baseResource.texture);
    lightsAndShadowsSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
    lightsAndShadowsSprite.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.ON;
    lightsAndShadowsSprite.width = width;
    lightsAndShadowsSprite.height = height;
    lightsAndShadowsSprite.alpha = layerValuesConfiguration.lightsAndShadowsOpacity;

    stage.addChild(lightsAndShadowsSprite);

    return lightsAndShadowsSprite;
}

export const addWatermarkLayer = (stage: PIXI.Container, baseResource: PIXI.LoaderResource, width: number, height: number) => {
    const texture2 = new PIXI.Texture(baseResource.texture.baseTexture, new PIXI.Rectangle(0, 0, width, height));
    const watermarkSprite = new PIXI.Sprite(texture2);
    watermarkSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
    watermarkSprite.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.ON;
    watermarkSprite.width = width;
    watermarkSprite.height = height;

    // const texture2 = new PIXI.Texture(baseResource.texture.baseTexture, new PIXI.Rectangle(0, 0, width, height));
    // watermarkSprite.texture = texture2;

    stage.addChild(watermarkSprite);

    return watermarkSprite;

}

type DeformerCoordenatesType = {
    red: { x: number, y: number },
    blue: { x: number, y: number },
    black: { x: number, y: number },
    green: { x: number, y: number }
}

const get3DProjectionPoints = (
    canvasContainer: HTMLDivElement,
    dimensions: DimensionType,
    deformerResource: PIXI.LoaderResource
) => {
    const originalWidth = dimensions.originalDeformerWidth;
    const originalHeight = dimensions.originalDeformerHeight;
    const widthDifference = (dimensions.originalDeformerWidth - dimensions.originalWidth) / 2;
    const heightDifference = (dimensions.originalDeformerHeight - dimensions.originalHegiht) / 2;
    const canv = document.createElement("canvas") as HTMLCanvasElement;
    canv.setAttribute('width', originalWidth.toString());
    canv.setAttribute('height', originalHeight.toString());
    canv.style.display = 'none';
    canv.style.position = 'absolute';
    canv.style.left = '0';
    // canvasContainer.appendChild(canv);
    const ctx = canv.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, originalWidth, originalHeight);

    return new Promise<DeformerCoordenatesType>((resolve, reject) => {
        const deformerImageElement = new Image();
        deformerImageElement.crossOrigin = "Anonymous";
        deformerImageElement.src = deformerResource.url;
        deformerImageElement.onload = () => {
            ctx.drawImage(deformerImageElement, 0, 0, originalWidth, originalHeight);
            const imageData = ctx.getImageData(0, 0, originalWidth, originalHeight);
            const data = imageData.data;
            const ret: DeformerCoordenatesType = {
                red: { x: undefined, y: undefined },
                blue: { x: undefined, y: undefined },
                black: { x: undefined, y: undefined },
                green: { x: undefined, y: undefined }
            };
            for (var i = data.length; i >= 0; i -= 4) {

                if (data[i + 3] > 0) {
                    const r = data[i];
                    const g = data[i + 1];
                    const b = data[i + 2];
                    if (r <= 255 && r > 200 && b < r && g < r) {
                        const x = (i / 4) % originalWidth;
                        const y = Math.floor((i / 4) / originalWidth);
                        ret.red = { x: (x - widthDifference) / dimensions.widthMultiplayer, y: (y - heightDifference) / dimensions.heightMultiplayer }
                    }
                    if (g <= 255 && g > 200 && b < g && r < g) {
                        const x = (i / 4) % originalWidth;
                        const y = Math.floor((i / 4) / originalWidth);
                        ret.green = { x: (x - widthDifference) / dimensions.widthMultiplayer, y: (y - heightDifference) / dimensions.heightMultiplayer }
                    }
                    if (b <= 255 && b > 200 && r < b && g < b) {
                        const x = (i / 4) % originalWidth;
                        const y = Math.floor((i / 4) / originalWidth);
                        ret.blue = { x: (x - widthDifference) / dimensions.widthMultiplayer, y: (y - heightDifference) / dimensions.heightMultiplayer }
                    }
                    if (r <= 240 && g <= 240 && b <= 240 && r === g && g === b) {
                        const x = (i / 4) % originalWidth;
                        const y = Math.floor((i / 4) / originalWidth);
                        ret.black = { x: (x - widthDifference) / dimensions.widthMultiplayer, y: (y - heightDifference) / dimensions.heightMultiplayer }
                    }
                }
            }
            canv.remove();
            Object.keys(ret).forEach((color) => {
                if (ret[color].x === undefined || !ret[color].y === undefined) {
                    reject("Error: One or more points of the deformer where not found");
                    return;
                }
            })
            resolve(ret);
        }
    })
}

const createSquare = (x: number, y: number) => {
    const square = new PIXI.Sprite(PIXI.Texture.WHITE);
    square.tint = 0xff0000;
    square.anchor.set(0.5);
    square.position.set(x, y);
    square.visible = false;
    return square;
}

export const setupPlaceholderLayer = async (
    app: PIXI.Application,
    container: PIXI.Container,
    canvasContainer: HTMLDivElement,
    dimensions: DimensionType,
    deformerResource: PIXI.LoaderResource) => {
    try {
        const coordenates = await get3DProjectionPoints(canvasContainer, dimensions, deformerResource);
        const squares = [
            createSquare(coordenates.red.x, coordenates.red.y),
            createSquare(coordenates.green.x, coordenates.green.y),
            createSquare(coordenates.black.x, coordenates.black.y),
            createSquare(coordenates.blue.x, coordenates.blue.y),
        ];

        // const hitArea = new PIXI.Polygon([coordenates.red.x, coordenates.red.y, coordenates.green.x, coordenates.green.y, coordenates.black.x, coordenates.black.y, coordenates.blue.x, coordenates.blue.y]);

        squares.forEach((s) => { container.addChild(s); });
        const quad = squares.map((s) => s.position);
        squares.forEach((s) => { addInteraction(s) });

        const deformerBaseSprite = await createDeformerSpriteAndApply(container, app, quad);

        return deformerBaseSprite;
    } catch (e) {
        throw new Error(`Error generating placeholder: ${e}`);
    }
}

export const buildFilter = (stage: PIXI.Container, displacementResource: PIXI.LoaderResource, width: number, height: number, dimensions?: DimensionType) => {
    const displacementSprite = new PIXI.Sprite(displacementResource.texture);
    displacementSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
    displacementSprite.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.ON;
    displacementSprite.width = width;
    displacementSprite.height = height;

    const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
    if (dimensions) {
        // const fixedOriginalW = 2500;
        // const actualW = dimensions.originalWidth;
        // const ratio = 
        displacementFilter.scale.set(0, 6);
        displacementFilter.padding = 20;
    } else {
        displacementFilter.scale.set(0, layerValuesConfiguration.displacementMapScale);
        displacementFilter.padding = layerValuesConfiguration.displacementMapPadding;
    }

    stage.addChild(displacementSprite);

    return displacementFilter;
}

export const addMaskLayer = (stage: PIXI.Container, maskResource: PIXI.LoaderResource, width: number, height: number) => {
    const maskSprite = new PIXI.Sprite(maskResource.texture);
    maskSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
    maskSprite.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.ON;
    maskSprite.width = width;
    maskSprite.height = height;
    stage.addChild(maskSprite);

    return maskSprite;
}

export const addColorLayer = (stage: PIXI.Container, mask: PIXI.Sprite, width: number, height: number, color?: string) => {
    const colorSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    colorSprite.width = width;
    colorSprite.height = height;
    colorSprite.mask = mask;
    if (color) {
        colorSprite.tint = Number(color);
    } else {
        colorSprite.alpha = 0;
    }
    stage.addChild(colorSprite);

    return colorSprite;
}

export const buildContainer = (stage: PIXI.Container, width: number, height: number, mask: PIXI.Sprite) => {
    const container = new PIXI.Container();
    container.width = width;
    container.height = height;
    if (mask) {
        container.mask = mask;
    }

    stage.addChild(container);
    return container;
}

type projectionSprite2D = any

export const changePlaceholderImage = (
    uploadedImage: string,
    dinamycUserImageLoader: PIXI.Loader,
    placeholderSprite: PIXI.Sprite,
    deformerBaseSprite: projectionSprite2D,
    setDimensions?: boolean,
) => {
    return new Promise<number>((resolve, reject) => {
        try {
            if (dinamycUserImageLoader && dinamycUserImageLoader.resources.uploadedImage) {
                dinamycUserImageLoader.reset();
            }
            dinamycUserImageLoader.add('uploadedImage', uploadedImage);
            dinamycUserImageLoader.load((loadr, res) => {
                let scale = 1;
                if (setDimensions) {
                    scale = setDimanesionsOfPlaceholder(res.uploadedImage, placeholderSprite, deformerBaseSprite, uploadedImage);
                }
                placeholderSprite.texture = res.uploadedImage.texture;
                placeholderSprite.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.OFF;
                placeholderSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                resolve(scale);
            })
            dinamycUserImageLoader.onError.add((err, loader, resource) => {
                reject(err)
            })
        } catch (err) {
            console.error("Error changing placeholder image", err);
            Sentry.setTag("Source", "Mockup viewer - changePlaceholderImage")
            Sentry.captureException(err)
            reject(err)
        }
    })
}

export const changeBackgroundImage = (backgroundImage: string, dinamycBackgroundImageLoader: PIXI.Loader, baseSprite: PIXI.Sprite) => {
    if (baseSprite) {
        return new Promise<void>((resolve, reject) => {
            try {
                if (dinamycBackgroundImageLoader && dinamycBackgroundImageLoader.resources.backgroundImage) {
                    dinamycBackgroundImageLoader.reset();
                }
                dinamycBackgroundImageLoader.add('backgroundImage', backgroundImage);
                dinamycBackgroundImageLoader.load((loadr, res) => {
                    baseSprite.texture = res.backgroundImage.texture;
                    resolve()
                })
                dinamycBackgroundImageLoader.onError.add((err, loader, resource) => {
                    reject(err)
                })
            } catch (err) {
                console.error("Error changing background image", err);
                Sentry.setTag("Source", "Mockup viewer - changeBackgroundImage")
                Sentry.captureException(err)
                reject(err)
            }
        })
    }
}

export const isSafari = () => {
    const parsed = new UAParser()
    console.log(parsed.getBrowser())
    return parsed.getBrowser().name === "Safari"
}

const loadHiResImages = (mockup: MockupWithImagesViewModel, hiResLoader: PIXI.Loader, backgroundSelected: number, watermark: boolean) => {
    return new Promise<void>((resolve, reject) => {
        hiResLoader.reset();
        if (Object.keys(hiResLoader.resources).length === 0) {
            hiResLoader
                .add('base-hi', mockup.layers.baseImages[backgroundSelected])
                .add('shadowsAndLights-hi', mockup.layers.shadowsAndLightsImage)
            hiResLoader.load(() => {
                resolve();
            })
            hiResLoader.onError.add(() => {
                reject()
            })
        }
    })
}

const setHiResImages = (hiResLoader: PIXI.Loader, baseSprite: PIXI.Sprite, lightSahdowsSprite: PIXI.Sprite) => {
    baseSprite.texture = hiResLoader.resources['base-hi'].texture;
    lightSahdowsSprite.texture = hiResLoader.resources['shadowsAndLights-hi'].texture;
}

const setLowResImages = (loader: PIXI.Loader, dinamycBackgroundImageLoader: PIXI.Loader, baseSprite: PIXI.Sprite, lightSahdowsSprite: PIXI.Sprite) => {
    if (Object.keys(dinamycBackgroundImageLoader.resources).length > 0) {
        baseSprite.texture = dinamycBackgroundImageLoader.resources['backgroundImage'].texture;
    } else {
        baseSprite.texture = loader.resources['base'].texture;
    }
    lightSahdowsSprite.texture = loader.resources['shadowsAndLights'].texture;
}

export const generateThumb = (app: PIXI.Application) => {
    const pixiImageDataURL = app.view.toDataURL("image/png").replace("image/png", "image/octet-stream");
    return pixiImageDataURL;
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const downloadImage = (
    app: PIXI.Application,
    dimensions: DimensionType,
    filter: (typeof filters.DisplacementFilter) | any,
    mockup: MockupWithImagesViewModel,
    downloadSize: { width: number, height: number },
    handleErrorModal: () => void,
    hiResLoader: PIXI.Loader,
    loader: PIXI.Loader,
    dinamycBackgroundImageLoader: PIXI.Loader,
    backgroundSelected: number,
    baseSprite: PIXI.Sprite,
    lightSahdowsSprite: PIXI.Sprite,
    watermark: boolean
) => {
    return new Promise<void>(async (resolve, reject) => {
        if (app) {
            const isMobileDevice = isMobile(window.navigator).any;
            await loadHiResImages(mockup, hiResLoader, backgroundSelected, watermark);
            setHiResImages(hiResLoader, baseSprite, lightSahdowsSprite);
            if (!isMobileDevice) {
                app.renderer.resize(dimensions.originalWidth, dimensions.originalHegiht);
                app.stage.width = dimensions.originalWidth;
                app.stage.height = dimensions.originalHegiht;
                filter.scale.set(0, filter.scale.y * dimensions.heightMultiplayer);
                filter.padding = filter.padding * dimensions.widthMultiplayer;
            }
            const fileName = mockup.title.replace(" ", "-")
            setTimeout(async () => {
                try {
                    const pixiImageDataURL = app.view.toDataURL("image/png").replace("image/png", "image/octet-stream");
                    const cnv = document.createElement('canvas');
                    cnv.width = downloadSize.width;
                    cnv.height = downloadSize.height;
                    const cntx = cnv.getContext('2d');
                    const draw = (data: string) => {
                        return new Promise<void>((resolve) => {
                            const img = new Image();
                            img.onload = () => {
                                if (isSafari() && isMobileDevice) {
                                    const degrees = 180;
                                    cntx.save();
                                    cntx.translate(cnv.width / 2, cnv.height / 2);
                                    cntx.rotate(degrees * Math.PI / 180);
                                    cntx.scale(-1, 1)
                                    cntx.drawImage(img, -downloadSize.width / 2, -downloadSize.height / 2, downloadSize.width, downloadSize.height);
                                    cntx.restore();
                                } else {
                                    cntx.drawImage(img, 0, 0, downloadSize.width, downloadSize.height);
                                }
                                resolve();
                            }
                            img.src = data;
                        })
                    }

                    await draw(pixiImageDataURL)

                    const imageBase64 = await cnv.toDataURL("image/png");

                    const base64Response = await fetch(imageBase64);
                    const blob = await base64Response.blob();

                    var fileURL = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = `${fileName}-${downloadSize.width}x${downloadSize.height}.png`;
                    document.body.appendChild(a);
                    a.click();

                    setLowResImages(loader, dinamycBackgroundImageLoader, baseSprite, lightSahdowsSprite);

                    app.renderer.resize(dimensions.canvasWidth, dimensions.canvasHeight);
                    app.stage.width = dimensions.canvasWidth;
                    app.stage.height = dimensions.canvasHeight;
                    filter.scale.set(0, filter.scale.y / dimensions.heightMultiplayer);
                    filter.padding = filter.padding / dimensions.widthMultiplayer;

                    try {
                        await track('mg_download');
                    } catch (error) {
                        console.error(error)
                    }

                    resolve();
                } catch (err) {
                    console.error(err);
                    console.error("Error downloading mockup", err);
                    Sentry.setTag("Source", "Mockup viewer - downloadImage")
                    Sentry.captureException(err)
                    reject()
                }
            }, 100)
        } else {
            reject()
            handleErrorModal()
        }
    })
}

export const clean = (app: PIXI.Application, loader: PIXI.Loader, dinamycUserImageLoader: PIXI.Loader, dinamycBackgroundImageLoader: PIXI.Loader) => {
    if (
        app
        && loader
        && dinamycUserImageLoader
        && dinamycBackgroundImageLoader
    ) {
        PIXI.utils.clearTextureCache();
        const resources = loader.resources;
        Object.keys(resources).forEach((resourceKey) => {
            const resource = resources[resourceKey];
            if (resource.texture) {
                resource.texture.destroy(true);
            }
        })
        if (dinamycUserImageLoader.resources.uploadedImage && dinamycUserImageLoader.resources.uploadedImage.texture) {
            dinamycUserImageLoader.resources.uploadedImage.texture.destroy(true);
        }
        if (dinamycBackgroundImageLoader.resources.backgroundImage) {
            dinamycBackgroundImageLoader.resources.backgroundImage.texture.destroy(true);
        }
        app.destroy(true, {
            children: false,
            baseTexture: true,
            texture: true
        })
        loader.reset();
        dinamycUserImageLoader.reset();
        dinamycBackgroundImageLoader.reset();
        loader = null;
        dinamycUserImageLoader = null;
        dinamycBackgroundImageLoader = null;
    }
}

export const applyRotation = (placeholderSprite: PIXI.Sprite, imageRotation: number) => {
    try {
        placeholderSprite.angle = imageRotation;
    } catch (err) {
        console.error("Error rotate image", err);
        Sentry.setTag("Source", "Mockup viewer - applyRotation")
        Sentry.captureException(err)
    }
}

export const applyScale = (placeholderSprite: PIXI.Sprite, imageScale: number) => {
    try {
        const ratio = placeholderSprite.width / placeholderSprite.height;
        const newHeight = imageScale / ratio;
        placeholderSprite.width = imageScale;
        placeholderSprite.height = newHeight;
    } catch (err) {
        console.error("Error scaling image", err);
        Sentry.setTag("Source", "Mockup viewer - applyScale")
        Sentry.captureException(err)
    }

}

export const idleCallback = (timeout?: number) => {
    return new Promise((resolve, _reject) => {
        (window as any).requestIdleCallback(resolve, { timeout: timeout ? timeout : 100 })
    })
}


export const timeoutPromise = (timeout: number) => {
    return new Promise((resolve, _reject) => {
        setTimeout(resolve, timeout)
    })
}

export const setupFilters = (stage: PIXI.Container) => {
    const colorMatrix = [
        //R  G  B  A
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    ];
    const filterIg = new PIXI.filters.ColorMatrixFilter();
    const filterCtr = new CRTFilter();
    const filterOldFilm = new OldFilmFilter();
    filterIg.matrix = colorMatrix as any;
    stage.filters = [filterIg, filterCtr];
    filterIg.enabled = false;
    filterCtr.enabled = false;
    filterOldFilm.enabled = false;
    return {
        filterIg: filterIg,
        filterCtr: filterCtr,
        filterOldFilm: filterOldFilm
    }
}
