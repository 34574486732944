import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Sentry from "@sentry/browser"
import {
    isSafari
} from './SmartFunctions';
import { MockupWithImagesViewModel } from '../../api/clientInterfaces/MockupModel';
import { getThumnail } from './MockupThumnailService';

interface Props {
    uploadedImage: string;
    mockup: MockupWithImagesViewModel;
    ready: (renderedImage: string, inverted?: boolean) => void;
}

const MockupViewerWrapper = styled.article``




const MockupThumbnailViewer = (props: Props) => {
    useEffect(() => {
        if (props.uploadedImage) {
            const {
                cancel,
                result,
            } = getThumnail(props.uploadedImage, props.mockup)

            const setup = async () => {
                try {

                    const {
                        ret: renderedImage,
                        canceled,
                    } = await result
                    if (!canceled){
                        if (isSafari()) {
                            props.ready(renderedImage, true);
                        } else {
                            props.ready(renderedImage);
                        }
                    }
                } catch (error) {
                    console.error("Error generating mockup thumbnail", error);
                    Sentry.setTag("Source", "Mockup thumbnail viewer")
                    Sentry.captureException(error)
                    props.ready(props.mockup.thumbnailsLayers.baseImages[0]);
                }
            }

            setup();
            return () => {
                cancel()
            }
        } else {
            props.ready(props.mockup.thumbnailsLayers.baseImages[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mockup, props.uploadedImage])
    return (
        <MockupViewerWrapper />
    )
}

export default MockupThumbnailViewer;