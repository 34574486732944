import React, { useRef } from "react";
import styled from "styled-components";
import UploadImageList from "../toolbar/UploadImage/UploadImageList";
import gsap from 'gsap';

const UploadImageControlWrapper = styled.div`
    .upload-tooltip {
        opacity: 0;
        position: absolute;
        top: 50vh;
        margin: 0 auto;
        left: 0;
        right: 0;
        font-size: 16px;
        color: white;
        width: 200px;
        background: #00000082;
        backdrop-filter: blur(9px);
        box-shadow: 0 9px 16px -8px black;
        text-align: center;
        padding: 5px;
        border-radius: 10px;
        border: solid 1px #ffffff69;
    }
`;

const UploadImageControl = () => {
    const tooltip = useRef<HTMLDivElement|null>(null);
    const handleShowTooltip = () => {
        gsap.fromTo(tooltip.current, {opacity: 0}, {opacity: 1, duration: 1.6});
        setTimeout(()=>{
            gsap.fromTo(tooltip.current, {opacity: 1}, {opacity: 0, duration: 1.6});
        }, 4000)
    }
    return (
        <UploadImageControlWrapper>
            <div className="upload-tooltip" ref={tooltip}>Tap your image to edit</div>
            <UploadImageList showTooltip={handleShowTooltip} />
        </UploadImageControlWrapper>
    )
}

export default UploadImageControl;