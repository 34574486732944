import * as Sentry from '@sentry/browser'
import { EnvHelper } from './EnvHelper'


if (EnvHelper.isProduction) {
    Sentry.init({
        dsn: EnvHelper.sentryDSN,
        release: EnvHelper.buildName,
        environment: EnvHelper.envType,
    })
} else {
    console.warn("RemoteDiagnosticsSentry Disabled")
}