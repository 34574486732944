import {
  EventTypes,
  MockupState,
  ModalType,
  mockupState,
} from "../Recoil/RecoilState";
import React, { useEffect, useState } from "react";

import { ApiClient } from "../api/ApiClient";
import EditorZone from "../components/ui/editorZone/EditorZone";
import { Filters } from "../components/smartCanvas/FiltersFunctions";
import Header from "../components/ui/header/Header";
import { Helmet } from "react-helmet";
import MobileControlls from "../components/ui/mobileControls/MobileControlls";
import MockupsBar from "../components/ui/mockupsBar/MockupsBar";
import ModalNew from "../components/ui/modal/ModalNew";
import Toolbar from "../components/ui/toolbar/Toolbar";
import TouchInstructions from "../components/ui/mobileControls/TouchInstructions";
import { appConfig } from "../utils/Config";
import isMobile from "ismobilejs";
import produce from "immer";
import styled from "styled-components";
import { useGetRequest } from "../api/useGetRequest";
import { useRecoilState } from "recoil";
import { useSiteRouteMatch } from "../utils/Router/useSiteRouteMatch";
import { useWindowSize } from "../utils/CustomHooks/useWindowSize";

const EditorWrapper = styled.section`
  height: ${`${window.innerHeight}px`};
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: var(--color-gray);
`;

const Editor: React.FC = () => {
  const mockupId = useSiteRouteMatch();
  const [selectedMockupId, setSelectedMockupId] = useState<number>(mockupId);
  const [mockupGeneralState, setMockupGeneralState] =
    useRecoilState(mockupState);
  const [openMockupBar, setOpenMockupBar] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const windowSize = useWindowSize();

  const { error, data, loading } = useGetRequest({
    key: mockupId + "",
    fetcher: () => ApiClient.getMockup({ mockupId: mockupId }),
  });

  const {
    error: categoriesError,
    data: categoriesData,
    loading: categoriesLoading,
  } = useGetRequest({
    key: "Categories",
    fetcher: () => ApiClient.getCategories(),
  });

  const loadUserData = async () => {
    try {
      setIsLoadingUser(true);
      const { userName, userAvatar, userPlanType, watermark, isSubscriptor, hasMockupsAccess, hasRemainingDownloads } =
        await ApiClient.getUserInfo();
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.user = {
            loggedIn: true,
            token: "",
            name: userName,
            avatar: userAvatar,
            planType: userPlanType,
            watermark,
            isSubscriptor,
            hasMockupsAccess,
            hasRemainingDownloads,
          };
        })
      );
      setIsLoadingUser(false);
    } catch (e) {
      setIsLoadingUser(false);

      console.error(e);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await loadUserData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.categories = {
          data: categoriesData,
          error: categoriesError,
          loading: categoriesLoading,
        };
      })
    );
  }, [
    categoriesData,
    categoriesError,
    categoriesLoading,
    setMockupGeneralState,
  ]);

  useEffect(() => {
    if (
      !mockupGeneralState.user.loggedIn &&
      mockupGeneralState.events.length > appConfig.maxEvents
    ) {
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.modal = {
            open: true,
            type: ModalType.login,
          };
        })
      );
    }
  }, [
    mockupGeneralState.events,
    mockupGeneralState.user.loggedIn,
    setMockupGeneralState,
  ]);

  useEffect(() => {
    if (windowSize.width < 1280 && openMockupBar) {
      setOpenMockupBar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  useEffect(() => {
    setSelectedMockupId(mockupId);
    if (data) {
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.mockup.mockupLoaded = true;
          draftState.events.push(EventTypes.mockupChange);
          draftState.mockup.premium = data.premium && !mockupGeneralState.user.hasMockupsAccess;
        })
      );
    }

    return () =>
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.filter = Filters[0];
          draftState.selectedImage = undefined;
          draftState.isUploading = false;
          draftState.mockup = {
            ...draftState.mockup,
            // images: {},
            baseImageIndex: 0,
            mockupLoaded: false,
            mockupReady: false,
            originalSize: {
              height: undefined,
              width: undefined,
            },
          };
        })
      );
    // Queremos que se ejecute cuando el cleanup cuando se desmonta el componente y que se setee en el estado general cuando se trae los datos de la api
  }, [mockupId, data, setMockupGeneralState]);

  const setMockupReady = () => {
    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.mockup.mockupReady = true;
      })
    );
  };

  const handleCloseModal = () => {
    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.modal = {
          open: false,
          type: undefined,
        };
      })
    );
  };

  return (
    <>
      {data && data.title && data.description && (
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
        </Helmet>
      )}
      <EditorWrapper>
        {isMobile(window.navigator).any && <TouchInstructions />}
        <Header
          isLoadingUser={isLoadingUser}
          isEditing={mockupGeneralState.mobileState.isEditing}
          isMobile={isMobile(window.navigator).any}
        />
        {!isMobile(window.navigator).any && (
          <Toolbar
            selectedMockup={data}
            loading={loading}
            mockupReady={mockupGeneralState.mockup.mockupReady}
          />
        )}
        <EditorZone
          isMobile={isMobile(window.navigator).any}
          loading={loading}
          error={error}
          mockup={data}
          setMockupReady={setMockupReady}
          mockupBarOpen={openMockupBar}
          setMockupBarOpen={setOpenMockupBar}
          mockupReady={mockupGeneralState.mockup.mockupReady}
        />
        {!isMobile(window.navigator).any && (
          <MockupsBar
            selectedMockupId={selectedMockupId}
            mockupReady={mockupGeneralState.mockup.mockupReady}
            mockupBarOpen={openMockupBar}
            setMockupBarOpen={setOpenMockupBar}
            error={error}
          />
        )}
        {isMobile(window.navigator).any && (
          <MobileControlls
            loading={loading}
            mockup={data}
            selectedMockupId={selectedMockupId}
          />
        )}
        <ModalNew
          onClose={handleCloseModal}
          isOpen={mockupGeneralState.modal.open}
          type={mockupGeneralState.modal.type}
          mockupMiniature={
            data
              ? data.thumbnailsLayers.baseImages[
                  mockupGeneralState.mockup.baseImageIndex
                ]
              : undefined
          }
          disableClickOverlay={
            mockupGeneralState.modal.type === ModalType.login
          }
        />
      </EditorWrapper>
    </>
  );
};

export default Editor;
