import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import InputFileButton from "../../buttons/InputFileButton"
import ImagePreview from "./ImagePreview"
import { useRecoilState } from "recoil"
import { mockupState, MockupState, UserUploadType, SELECTED_SOURCE, UserImageType } from "../../../../Recoil/RecoilState"
import produce from "immer"
import { screenSize } from "../../../../stylesGlobal/Variables"
import { sendEvent } from "../../../../utils/Events"
import isMobile from 'ismobilejs';
import { ReactComponent as UploadSvg } from "../../../../assets/icons/upload.svg";
import { useLocation } from "react-router-dom"

interface Props {
    userUpload: UserUploadType,
    userUploads: { [id: string]: UserUploadType },
    index: number,
    isSelected: boolean,
    imageStock: UserImageType[],
    showTooltip?: () => void
}

type UploadImageWrapperProps = {
    isMobile?: boolean
}


type MockupSize = {
    name: string,
    percentaje: number,
}

const mockupSizeList: {
    [key: string]: MockupSize
} = {
    "original": {
        name: "Original size",
        percentaje: 100,
    },
    "large": {
        name: "Large",
        percentaje: 80,

    },
    "big": {
        name: "Big",
        percentaje: 60,

    },
    "medium": {
        name: "Medium",
        percentaje: 40,

    },
    "small": {
        name: "Small",
        percentaje: 20,
    }
}

const UploadImageWrapper = styled.section<UploadImageWrapperProps>`
    display: inline-block;
    width:100%;

    ${(props) => props.isMobile ? css`
        scroll-snap-align: start;
        text-align: center;
    `: null}

    .uploadImage__title{
        font-size:1.2rem;
        color: #000000;
        margin-bottom:1.5rem;
        margin-top:1.5rem;
        ${(props) => props.isMobile ? css`
        font-size:16px;
        font-weight: 500;
    `: null}
    }

    button{
        margin:0 auto;
    }

    .uploadImage__content {
        display: flex;
        gap: 0.5em;
        svg {
            width: 25px;
            path {
                fill: white;
            }
        }
    }
    

    @media (min-width: ${screenSize.size_s}){
        .uploadImage__title{
            font-size:1.6rem;
        }
    }

`

const UploadImage: React.FC<Props> = (props) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)
    const [selectedSize, setSelectedSize] = useState<MockupSize>(mockupSizeList.original)

    const readImage = async (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            let fileAsDataURL: string;
            reader.onloadend = () => {
                fileAsDataURL = (reader.result as string);

                const uploadedImageElement = new Image();

                uploadedImageElement.src = fileAsDataURL;
                uploadedImageElement.onload = () => {
                    let imgw = uploadedImageElement.width;
                    let imgh = uploadedImageElement.height;
                    if (imgw > 3000 || imgh > 3000) {
                        imgw = imgw * 0.7;
                        imgh = imgh * 0.7;
                    }
                    const canv = document.createElement("canvas") as HTMLCanvasElement;
                    canv.setAttribute('width', (imgw + 40).toString());
                    canv.setAttribute('height', (imgh + 40).toString());
                    canv.style.display = 'none';
                    canv.style.position = 'absolute';
                    canv.style.left = '0';
                    const ctx = canv.getContext("2d");
                    ctx.drawImage(uploadedImageElement, 20, 20, imgw, imgh);
                    const imageData = canv.toDataURL("image/png");
                    setTimeout(() => {
                        canv.remove();
                        resolve(imageData)
                    }, 2000)
                    canv.remove();
                }
            };
            reader.onabort = (ev) => {
                console.error("file reading was aborted")
                setLoading(false)
                reject(ev)
            };
            reader.onerror = (ev) => {
                console.error("file reading was aborted")
                setLoading(false)
                reject(ev)
            };
            reader.readAsDataURL(file);
        })
    }


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const image = queryParams.get('image');

        const color = queryParams.get('color') || '0xFFFFFF';
        const background = queryParams.get('background') || 0;

        const mockupSizeOption = queryParams.get('mockupSize');
        const mockupSize = mockupSizeList[mockupSizeOption || 'original'];

        const forceDownload = queryParams.get('forceDownload');
        
        if (image) {
            setSelectedSize(mockupSize);

            const placeImage = async (url: string) => {
                const response = await fetch(url);
                const blob = await response.blob();
                const file = new File([blob], "image.png", { type: "image/png" });
                const imageString = await readImage(file)
                setMockupGeneralState(
                    produce((draftState: MockupState) => {
                        draftState.userUploads[props.userUpload.id].userImage = {
                            imageFile: file,
                            base64: imageString,
                        }
                        draftState.mockup.images[props.userUpload.id].centerImage = true
                        draftState.mockup.images[props.userUpload.id].rotationDegrees = 0
                        draftState.mockup.images[props.userUpload.id].defaultScale = undefined
                        draftState.mockup.images[props.userUpload.id].imageScale = undefined
                        draftState.mockup.color = color
                        draftState.mockup.baseImageIndex = Number(background)
                    })
                );
            }

            const getMockupDimensions = (percentaje: number) => {
                const { width, height } = mockupGeneralState.mockup.originalSize
                const newWidht = width * (percentaje / 100)
                const newHeight = height * (percentaje / 100)
        
                return {
                    width: Math.round(newWidht),
                    height: Math.round(newHeight),
                }
            }
    
            placeImage(image).then(() => {
                setTimeout(() => {
                    if (forceDownload){
                        const downloadSize = getMockupDimensions(selectedSize.percentaje)
                        setMockupGeneralState(
                            produce((draftState: MockupState) => {
                                draftState.download = {
                                    startDownload: true,
                                    size: {
                                        width: downloadSize.width,
                                        height: downloadSize.height,
                                    }
                                }
                            })
                        );
                        // Dispatch custom event to start download
                        const event = new CustomEvent('startDownload', {
                            detail: {
                                message: 'startDownload',
                                time: new Date(),
                            },
                        });
                        window.dispatchEvent(event);
                    }
                }, 500)
            });
        }

    }, [location.search])

    const handleUploadImage = async (image: File) => {
        setLoading(true)
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.isUploading = true
                draftState.highlightedImage = undefined
            })
        )
        const imageString = await readImage(image)

        setMockupGeneralState(
            produce((draftState: MockupState) => {

                const newImagesStock = draftState.uploadedImagesStock.slice(0);
                newImagesStock.push({
                    imageFile: image,
                    base64: imageString,
                })
                if (!isMobile(window.navigator).any) {
                    draftState.selectedImage = {
                        id: props.userUpload.id,
                        source: SELECTED_SOURCE.CANVAS,
                        bounds: undefined
                    }
                }
                if (!isMobile(window.navigator).any) draftState.uploadedImagesStock = newImagesStock;
                draftState.isUploading = false
                draftState.userUploads[props.userUpload.id].userImage = {
                    imageFile: image,
                    base64: imageString,
                }
                draftState.mockup.images[props.userUpload.id].centerImage = true
                draftState.mockup.images[props.userUpload.id].rotationDegrees = 0
                draftState.mockup.images[props.userUpload.id].defaultScale = undefined
                draftState.mockup.images[props.userUpload.id].imageScale = undefined
            })
        );

        sendEvent('MG - Upload Image', 'uploadImage')

        setLoading(false)

        if (props.showTooltip) {
            props.showTooltip();
        }
    }

    const handleSelectedImage = (image: UserImageType) => {
        sendEvent('MG - Upload Image', 'uploadExistingImage')
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.isUploading = false
                draftState.userUploads[props.userUpload.id].userImage = {
                    imageFile: image.imageFile,
                    base64: image.base64,
                }
                draftState.selectedImage = {
                    id: props.userUpload.id,
                    source: SELECTED_SOURCE.CANVAS,
                    bounds: undefined
                }
                draftState.mockup.images[props.userUpload.id].centerImage = true
                draftState.mockup.images[props.userUpload.id].rotationDegrees = 0
                draftState.mockup.images[props.userUpload.id].defaultScale = undefined
                draftState.mockup.images[props.userUpload.id].imageScale = undefined
                draftState.highlightedImage = undefined;
            })
        )
    }

    const handleDeleteImage = () => {
        sendEvent('MG - Upload Image', 'deleteImage')
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.userUploads[props.userUpload.id].userImage.imageFile = null
                draftState.userUploads[props.userUpload.id].userImage.base64 = ""
                draftState.mockup.images[props.userUpload.id].centerImage = true
                draftState.mockup.images[props.userUpload.id].rotationDegrees = 0
                draftState.mockup.images[props.userUpload.id].defaultScale = undefined
                draftState.mockup.images[props.userUpload.id].imageScale = undefined
            })
        )
    }

    const handleMouseOver = () => {
        if (!isMobile(window.navigator).any) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.highlightedImage = props.userUpload.id;
                })
            )
        }
    }

    const handleMouseLeave = () => {
        if (!isMobile(window.navigator).any) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.highlightedImage = undefined;
                })
            )
        }
    }

    const handleSelectImage = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.selectedImage = {
                    id: props.userUpload.id,
                    source: SELECTED_SOURCE.CANVAS,
                    bounds: undefined
                };
            })
        )
    }

    return (
        <UploadImageWrapper onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} isMobile={isMobile(window.navigator).any}>
            <p className="uploadImage__title">Image {props.index + 1}</p>
            {(!props.userUpload.userImage.imageFile && !loading)
                && <InputFileButton
                    onSelectedImage={handleSelectedImage}
                    imageStock={props.imageStock}
                    userUploads={props.userUploads}
                    id={props.userUpload.id}
                    onChange={handleUploadImage}
                    disabled={!mockupGeneralState.mockup.mockupReady}
                >
                    <div className="uploadImage__content">
                        <UploadSvg /> Upload
                    </div>
                </InputFileButton>
            }
            <ImagePreview
                selectImage={handleSelectImage}
                onSelectedImage={handleSelectedImage}
                isSelected={props.isSelected}
                userUploads={props.userUploads}
                imageStock={props.imageStock}
                image={props.userUpload.userImage.base64}
                file={props.userUpload.userImage.imageFile}
                onDelete={handleDeleteImage}
                onChange={handleUploadImage}
                loading={loading}
            />
        </UploadImageWrapper>
    )
}

export default UploadImage