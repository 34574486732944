import produce from 'immer';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { MockupState, mockupState, SCALE_STATE } from '../../../Recoil/RecoilState';
import tick from '../../../assets/icons/tick.svg';

const TouchControllsWrapper = styled.div`
    position: absolute;
    bottom: 0;
    height: 24vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    background: linear-gradient(0deg, black, transparent);
    z-index: 3;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    color: white;

    button {
        background-color: var(--color-green);
        font-weight: bold;
        color: white;
        border-radius: 62px;
        border: 1px solid #ffffff96;
        backdrop-filter: blur(12px);
        box-shadow: 0 7px 22px -5px #00000063;
        width: 62px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`


const TouchControlls = () => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)

    const handleZoomOut = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mobileState.isEditing = false;
                draftState.mockup.scaleState = SCALE_STATE.stop;
            })
        )
    }

    return (
        <TouchControllsWrapper>
            <button onClick={handleZoomOut}><img src={tick} alt="Tick" /></button>
            <p>use gestures to adjust</p>
        </TouchControllsWrapper>
    )
}

export default TouchControlls;