import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from './Spinner';

interface Props {
    label?: string;
    overlay?: boolean,
}

type DownloadSpinnerWrapperProps = {
    overlay: boolean
}

const DownloadSpinnerWrapper = styled.div<DownloadSpinnerWrapperProps>`
    

    .spinner-box{
        padding: 10px;
        border-radius: 10px;
        background: #00000066;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    ${props => props.overlay && css`
        .spinner-box{
            position:absolute;
            top:calc(50% - 43px);
            left:calc(50% - 23px);
            z-index:var(--zIndex-medium);
        }
        .spinner-background{
            position:absolute;
            top:0;
            left:0;
            z-index:0;
            width:100%;
            height:100%;
            background:rgba(175,175,175,.5);
        }
    `}

    p {
        margin-top: 10px;
        color: white;
    }
`;

const DownloadSpinner: React.FC<Props> = (props) => {
    return (
        <DownloadSpinnerWrapper overlay={props.overlay}>
            <div className="spinner-box">
                <Spinner color="white" />
                <p>{props.label}</p>
            </div>
            {props.overlay && <div className="spinner-background" />}
        </DownloadSpinnerWrapper>
    )
}

export default DownloadSpinner;