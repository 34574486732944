import React, { useState } from "react"
import styled from "styled-components"
import Socials from "./Socials"
import Separator from "./Separator"
import LoginForm from "./LoginForm"
import SpinnerVexels from "../ui/spinner/SpinnerVexels"
import { screenSize } from "../../stylesGlobal/Variables"

const LoginModalWrapper = styled.section`
    min-width: 40em;
    max-width: calc(100vh - 5em);
    @media (min-width: ${screenSize.size_s}){
        min-width: 30em;
    }
    .content {
        display: grid;
        gap: 2em;
    }
    .d-none{
        display: none;
    }
    .title {
        color: gray;
        font-size: 2.4em;
        font-weight: 500;
    }
`

type Props = {
    onClose: () => void,
    isLogin?: boolean
}

const LoginModal = ({ isLogin: isDefaultLogin }: Props) => {
    const [isLogin, setIsLogin] = useState(isDefaultLogin ?? true);
    const [isLoading, setIsLoading] = useState(false);

    const handleLoading = (isLoading: boolean) => {
        setIsLoading(isLoading);
    }
    return (
        <LoginModalWrapper>
            {isLoading && <SpinnerVexels scale={0.3} />}
            <div className={`content ${isLoading && 'd-none'}`}>
                <p className="title">{isLogin ? 'Log in' : 'Sign up'}</p>
                <Socials isLogin={isLogin} />
                <Separator />
                <LoginForm handleLoading={handleLoading} handleChangeIsLogin={setIsLogin} isLogin={isLogin} />
            </div>
        </LoginModalWrapper>
    )
}

export default LoginModal