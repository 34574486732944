import { screenSize } from "../../../stylesGlobal/Variables";
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { GoogleLogout } from "react-google-login";
import planIcon from "../../../assets/icons/plan.svg";
import designsIcon from "../../../assets/icons/my-designs.svg";
import logoutIcon from "../../../assets/icons/logout.svg";

const ModalWrapper = styled(motion.div)`
  position: fixed;
  z-index: var(--zIndex-high);
  width: 100%;
  height: 100vh;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0px, 0px, 0px);

  .modal__container {
    position: absolute;
    z-index: var(--zIndex-high);
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    top: 5.4rem;
    right: 3rem;
    box-shadow: 0px 0 83px 0px #00000029;
    border-radius: 1rem;

    h3 {
      font-weight: 600;
      margin-bottom: 1.5rem;
      color: rgba(0, 0, 0, 0.68);
    }

    button {
      background: none;
      border: 1px solid transparent;
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--color-blue);
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 2.5rem;
        fill: var(--color-blue);
        margin-left: 2rem;
      }

      &:focus {
        outline: none;
        border: 1px solid var(--color-blue);
      }
      &.no-border {
        &:focus {
          border: none;
        }
      }
    }
  }

  @media (min-width: ${screenSize.size_m}) {
    .modal__container {
      width: 256px;
    }
  }
`;

const ProfileLink = styled.a`
  align-items: center;
  color: #121212;
  display: flex;
  font-weight: 400;
  gap: 1rem;
  justify-content: start;
  margin-bottom: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.8rem;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  aspect-ratio: 1;
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.a`
  align-items: center;
  color: #121212;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  gap: 1rem;
  justify-content: start;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
  &:hover {
    background-color: #e4e4e4;
    color: #434343;
  }
`;

const MenuDivider = styled.div`
  align-items: center;
  border-radius: 0 0 0.75rem 0.75rem;
  border-top: 1px solid #e4e4e4;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  gap: 1rem;
  justify-content: start;
  padding: 0.8rem 1.6rem;
  &:hover {
    background-color: #e4e4e4;
    color: #434343;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  color: #121212;
`;

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onLogout: () => void;
  user?: {
    loggedIn: boolean;
    token: string;
    name: string;
    avatar: string;
    planType: string;
    watermark: boolean;
    isSubscriptor: boolean;
    hasMockupsAccess?: boolean;
    hasRemainingDownloads?: boolean;
  };
};

const UserModal = (props: Props) => {
  const modalContainerRef = useRef<HTMLElement>();

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(ev.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContainerRef]);

  return (
    <AnimatePresence>
      {props.isOpen && (
        <ModalWrapper
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            duration: 0.2,
          }}
        >
          <motion.section className="modal__container" ref={modalContainerRef}>
            <ProfileLink
              href="https://www.vexels.com/profile/my-plan"
              target="_blank"
            >
              <ProfileImage
                src={props.user.avatar}
                alt={`${props.user.avatar} Avatar`}
              />
              <div style={{ fontSize: "16px" }}>Hi, {props.user.name}</div>
            </ProfileLink>
            <MenuList>
              <MenuItem
                href="https://www.vexels.com/profile/my-plan"
                target="_blank"
              >
                <Icon src={planIcon} alt="My Plan" />
                My Plan
              </MenuItem>
              <MenuItem
                href="https://www.vexels.com/profile/my-designs"
                target="_blank"
              >
                <Icon src={designsIcon} alt="My Designs" />
                My Designs
              </MenuItem>
              <MenuDivider>
                <Icon src={logoutIcon} alt="Log out" />
                <GoogleLogout
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  render={(props) => <p {...props}>Log out</p>}
                ></GoogleLogout>
              </MenuDivider>
            </MenuList>
          </motion.section>
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
};

export default UserModal;
