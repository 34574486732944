import React from 'react'
import styled from "styled-components"

const Svg = styled.svg`
    display: none;
`

export default function PickerRect({ id = '' }: { id: string }): JSX.Element {
    return (
        <Svg>
            <defs>
                <g id={id}>
                    <defs>
                        <filter id="Elipse_706" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse">
                            <feOffset />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood floodOpacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Elipse_706)">
                        <g id="Elipse_706-2" transform="translate(9 9)" fill="none" stroke="#fff" strokeWidth="2">
                            <circle cx="0" cy="0" r="8" stroke="none" />
                            <circle cx="0" cy="0" r="7" fill="none" />
                        </g>
                    </g>
                </g>
            </defs>
        </Svg>
    )
}
