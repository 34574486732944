import React, { useEffect } from 'react';

import { ApiClient } from "../api/ApiClient";
import { paths } from '../utils/Router/RoutePaths';
import history from '../utils/history';

const Home: React.FC = () => {
    useEffect(() => {
        const defaultMockupsFetcher = async () => {
            const data = await ApiClient.getDefaultMockups();
            const randomElement = data[Math.floor(Math.random() * data.length)];
            const defaultMockupData = {
              mockupTitle: randomElement.title,
              mockupId: randomElement.id
            }
            const newPath = paths.mockup(defaultMockupData.mockupTitle, defaultMockupData.mockupId);
            history.push(newPath);
          }
          defaultMockupsFetcher()
    }, [])
    
    return (<></>)
}

export default Home;