import React from "react";
import styled from "styled-components";
import { ReactComponent as Background } from "../../../../assets/icons/background.svg";
import { ReactComponent as Upload } from "../../../../assets/icons/upload.svg";
import { ReactComponent as Fill } from "../../../../assets/icons/fill.svg";
import { ReactComponent as Filter } from "../../../../assets/icons/filter.svg";
import TabIcon from "./TabIcon";
import { MockupWithImagesViewModel } from "../../../../api/clientInterfaces/MockupModel";
import { TabType } from "../Toolbar";

const TabsWrapper = styled.section`
  width: 5rem;
  min-height: 100vh;
  border-right: solid 2px var(--color-soft-gray);
  ul {
    display: flex;
    flex-direction: column;
  }
`;


type Props = {
  activeTab: TabType;
  onTabChange: (tabtype: TabType) => () => void;
  selectedMockup: MockupWithImagesViewModel;
  mockupReady: boolean;
};

const Tabs = (props: Props) => {
  return (
    <TabsWrapper>
      <ul>
        <TabIcon
          active={props.activeTab === TabType.uploadImage}
          onClick={props.onTabChange(TabType.uploadImage)}
          disabled={!props.mockupReady || !props.selectedMockup}
        >
          <Upload />
        </TabIcon>
        <TabIcon
          active={props.activeTab === TabType.customizeColor}
          onClick={props.onTabChange(TabType.customizeColor)}
          disabled={
            !props.mockupReady ||
            (props.selectedMockup && !props.selectedMockup.layers.colorLayer)
          }
        >
          <Fill />
        </TabIcon>
        <TabIcon
          active={props.activeTab === TabType.selectBackground}
          onClick={props.onTabChange(TabType.selectBackground)}
          disabled={
            !props.mockupReady ||
            (props.selectedMockup &&
              props.selectedMockup.thumbnailsLayers.baseImages.length === 1)
          }
        >
          <Background />
        </TabIcon>
        <TabIcon
          active={props.activeTab === TabType.filters}
          onClick={props.onTabChange(TabType.filters)}
          disabled={!props.mockupReady || !props.selectedMockup}
        >
          <Filter />
        </TabIcon>
      </ul>
    </TabsWrapper>
  );
};

export default Tabs;
