import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { screenSize } from "../../../../stylesGlobal/Variables"
import SelectedCategory from "./SelectedCategory"
import { CategoryViewModel } from "../../../../api/clientInterfaces/CategoryModel"
import CategoryTags from "./CategoryTags"
import isMobile from 'ismobilejs';
import MockupsGrid from "../MockupsGrid"
import { ApiClient } from "../../../../api/ApiClient"
import { useRecoilState } from "recoil"
import { categoriesCache } from "../../../../Recoil/RecoilState"
import SpinnerVexels from "../../spinner/SpinnerVexels"

type MockupCategoriesWrapperProps = {
    isMobile?: boolean
}

const MockupCategoriesWrapper = styled.div<MockupCategoriesWrapperProps>`

    width:100%;
    min-height: 60rem;
    overflow-x:hidden;
    overflow-y:hidden;

    .mockupsCategories__error {
        font-size:1.4rem;
        padding:1rem 5rem;
        color:var(--color-white);
        display:flex;
        justify-content:center;
        align-items:center;
        width: 100%;
        background-color: var(--color-button-red-hover);
    }

    .mockupsCategories__loadMore {
        height: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mockupsCategories__loading{
        margin-top:5rem;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    @media (min-width: ${screenSize.size_2xl}){
        padding:0 1rem;
    }

    @media (min-width: ${screenSize.size_3xl}){
        padding:0 4rem;
    }
`

type Props = {
    selectedCategory: CategoryViewModel,
    cleanSelectedCategory: () => void,
    selectedMockupId: number,
}


const CategoryMockups = (props: Props) => {

    const [category, setCategory] = useRecoilState(categoriesCache(props.selectedCategory.id));
    const [updateLoad, setUpdateLoad] = useState(0);
    const updateLoadRef = useRef(updateLoad);
    const loadingRef = useRef(category.loading);
    const loadButtonRef = useRef<HTMLDivElement|null>(null);

    const loadMockups = async () => {
        try {
            setCategory({ ...category, loading: true, error: false });
            loadingRef.current = true;
            const mockups = await ApiClient.getCategoryMockups({ categoryId: props.selectedCategory.id, tags: category.selectedTags.join(','), limit: 15, page: category.lastPage + 1 });
            setCategory({ ...category, loading: false, error: false, mockups: category.mockups.concat(mockups), lastPage: category.lastPage + 1, noMoreMockups: mockups.length === 0 ? true : false })
            loadingRef.current = false;
        } catch (e) {
            console.error(e);
            loadingRef.current = false;
            setCategory({ ...category, loading: false, error: true })
        }
    }

    useEffect(() => {
        loadMockups();
    }, [updateLoad])

    useEffect(() => {
        loadMockups();
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting && !loadingRef.current){
                    setUpdateLoad(updateLoadRef.current + 1);
                    updateLoadRef.current = updateLoadRef.current + 1;
                }
            });
        })
        if(loadButtonRef.current){
            observer.observe(loadButtonRef.current)
        }
    }, [])

    const onChangeTag = (e: React.FormEvent<HTMLInputElement>) => {
        const { value, checked } = e.currentTarget;
        const newSelectedTags = checked ? [...category.selectedTags, value] : category.selectedTags.filter((tags) => !tags.includes(value));
        setCategory({ ...category, selectedTags: newSelectedTags, lastPage: 0, mockups: [] });
    };

    useEffect(() => {
        loadMockups();
    }, [category.selectedTags])

    const renderError = () => {
        return (
            <div className="mockupsCategories__error">Error</div>
        )
    }

    const loadMore = () => {
        return <div className="mockupsCategories__loadMore" ref={loadButtonRef}><SpinnerVexels scale={0.2} /></div>
    }

    return (
        <MockupCategoriesWrapper isMobile={isMobile(window.navigator).any}>
            <SelectedCategory
                cleanSelectedCategory={props.cleanSelectedCategory}
                category={props.selectedCategory}
            />
            <CategoryTags
                selectedTags={category.selectedTags}
                tags={props.selectedCategory.searchable_tags}
                onChangeTag={onChangeTag}
            />
            <MockupsGrid items={category.mockups} selectedMockupId={props.selectedMockupId} />
            {category.error && renderError()}
            {!category.noMoreMockups && loadMore()}
        </MockupCategoriesWrapper>
    )
}

export default CategoryMockups