import { MockupWithImagesViewModel } from "../../api/clientInterfaces/MockupModel"
import { makeCancelable, CancelationToken } from "../../utils/CancelableHelper"
import { 
    idleCallback, 
    getTextureNames, 
    canvasThumbnailDimensions, 
    loadTextures, 
    appSetup, 
    addBaseLayer, 
    addMaskLayer, 
    addColorLayer, 
    buildContainer, 
    setupPlaceholderLayer, 
    buildFilter, 
    addShadowsAndLightsLayer, 
    generateThumb, 
    clean } from "./SmartFunctions"
import { addPlaceholderLayer } from "./SmartFunctionsPixiNoTypes"
import { notInteracting } from "../../utils/InteractingService"
import { Loader } from "pixi.js"





const {
    get,
    set,
} = (() => {
    const thumnails = new Map<string, Map<number, string>>()

    const get = (uploadedImage: string, mockupId: number) => {
        const thumnailsOfUploaded = thumnails.get(uploadedImage)
        if (thumnailsOfUploaded) {
            return thumnailsOfUploaded.get(mockupId)
        } else {
            return undefined
        }
    }

    const set = (uploadedImage: string, mockupId: number, image: string) => {
        let thumnailsOfUploaded = thumnails.get(uploadedImage)
        if (!thumnailsOfUploaded) {
            thumnailsOfUploaded = new Map<number, string>()
            thumnails.set(uploadedImage, thumnailsOfUploaded)
        }
        thumnailsOfUploaded.set(mockupId, image)
    }

    return {
        get,
        set,
    }
})()



const getMockupPreview = makeCancelable(
    async (
        ct: CancelationToken,
        uploadedImage: string,
        mockup: MockupWithImagesViewModel
    ) => {

        const waitAndCancelIfNeeded = async (maxWait?: number) => {
            await notInteracting()
            await idleCallback(maxWait)
            ct.checkCancelation()
        }

        const canvasContainer = document.createElement("div")
        const loader = new Loader();
        const dinamycUserImageLoader = new Loader();
        const dinamycBackgroundImageLoader = new Loader();
        const textureNames = getTextureNames(mockup.id);
        const relativeElementWidth = 200;
        const dimensions = await canvasThumbnailDimensions(mockup.layers.baseImages[0], mockup.layers.deformerImage[0], relativeElementWidth);
        await waitAndCancelIfNeeded()
        const dimensionAlias = dimensions;

        await loadTextures(loader, mockup.thumbnailsLayers, 0, true, mockup.id, uploadedImage);

        await waitAndCancelIfNeeded()
        const app = appSetup(dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, canvasContainer, true);
        try {
            await waitAndCancelIfNeeded()
            addBaseLayer(app.stage, loader.resources[textureNames.base], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
            await waitAndCancelIfNeeded()
            const logoMask = addMaskLayer(app.stage, loader.resources[textureNames.logoMask], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
            const colorMask = addMaskLayer(app.stage, loader.resources[textureNames.colorMask], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
            addColorLayer(app.stage, colorMask, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
            await waitAndCancelIfNeeded()
            const userImageContainer = buildContainer(app.stage, dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, logoMask);
            await waitAndCancelIfNeeded()
            const deformerAndHitArea = await setupPlaceholderLayer(app, userImageContainer, canvasContainer, dimensionAlias, loader.resources[textureNames.deformer + '-0']);
            await waitAndCancelIfNeeded()
            const filter = buildFilter(app.stage, loader.resources[textureNames.displacement], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight, dimensionAlias);
            await waitAndCancelIfNeeded()
            addPlaceholderLayer(deformerAndHitArea, loader.resources[textureNames.placeholder], filter, canvasContainer, undefined, undefined, uploadedImage);
            await waitAndCancelIfNeeded()
            addShadowsAndLightsLayer(app.stage, loader.resources[textureNames.shadowsAndLights], dimensionAlias.canvasWidth, dimensionAlias.canvasHeight);
            app.render();
            await waitAndCancelIfNeeded(1000)
            const renderedImage = generateThumb(app);
            return renderedImage
        } finally {
            clean(app, loader, dinamycUserImageLoader, dinamycBackgroundImageLoader);
        }
    })


export const getThumnailFromCache = (
    uploadedImage: string,
    mockup: MockupWithImagesViewModel
) => {
    const maybeRet = get(uploadedImage, mockup.id)
    if (maybeRet) {
        return maybeRet
    } else {
        return null
    }
}

export const getThumnail = (
    uploadedImage: string,
    mockup: MockupWithImagesViewModel
) => {
    const maybeRet = get(uploadedImage, mockup.id)
    if (maybeRet) {
        return {
            cancel: () => void (0),
            result: new Promise<{
                ret: string,
                canceled: boolean
            }>(resolve => {
                resolve({
                    canceled: false,
                    ret: maybeRet,
                })
            })
        }
    }


    const {
        cancel,
        result,
    } = getMockupPreview(uploadedImage, mockup)


    const processPreview = async () => {
        const {
            canceled,
            ret,
        } = await result

        if (!canceled && ret) {
            set(uploadedImage, mockup.id, ret)
        }
    }


    processPreview()


    return {
        cancel,
        result,
    }

}