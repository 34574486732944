import React, { useState } from "react"
import styled, { css } from "styled-components"
import Button, { ButtonColor } from "../buttons/Button"
import { ReactComponent as Download } from "../../../assets/icons/download.svg"
import { useRecoilState } from "recoil"
import { mockupState, MockupState, ModalType } from "../../../Recoil/RecoilState"
import check from "../../../assets/icons/check.svg"
import produce from "immer"
import { screenSize } from "../../../stylesGlobal/Variables"
import { sendEvent } from "../../../utils/Events"
import isMobile from 'ismobilejs';
import { dataLayerPush } from "./.././../../utils/DataLayer";
import { ApiClient } from "../../../api/ApiClient"

type StylesProps = {
    isMobile?: boolean
}

const DownloadModalWrapper = styled.div<StylesProps>`
    padding-top:5%;
    margin:0 auto;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .modal__title{
        font-size:2.8rem;
        font-weight:500;
        margin-bottom:.5rem;
        color:var(--color-black);
        ${(props) => props.isMobile ? css`
         font-size: 2.5rem;
        `: null}
    }

    .modal__subtitle{
        font-size:1.5rem;
        font-weight:400;
        color:#9C9C9C;
    }

    .modal__sizeList{
        display:flex;
        justify-content:space-between;
        align-items: flex-end;
        width:80%;
        padding:5% 0;
        ${(props) => props.isMobile ? css`
         display:grid;
         grid-template-columns: 1fr 1fr;
         padding-left: 19px;
         padding-right: 19px;
        `: null}
    }

    .modal__listItem{
        min-width:10.8rem;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        position:relative;

        h4{
            font-size:1.8rem;
            font-weight:500;
            margin-bottom:.5rem;
            margin-top: 2rem;
            color:var(--color-black);
            ${(props) => props.isMobile ? css`
            font-size: 1.5rem;
        `: null}
        }

        p{
            font-size:1.6rem;
            font-weight:400;
            color:#B8B8B8;
        }

        .selected{
            position:absolute;
            background-color:var(--color-blue);
            width:3rem;
            height:3rem;
            display:flex;
            justify-content:center;
            align-items:center;
            border-radius:50%;

            img{
                max-width:100%;
            }

            &.original{
                top: -15px;
                right: 15px;    
            }

            &.large{
                top: -15px;
                right: 15px;
            }
            &.big{
                top: -15px;
                right: 18px;
            }
            &.medium{
                top: -18px;
                right: 22px;
            }
            &.small{
                top: -20px;
                right: 25px;
            }
        }
    }

    .mockup__size{
        background:rgba(154,154,154,.3);
        border:2px solid #C7C7C7;
        border-radius:.5rem;

        &.original{
            width:47px;
            height:39px;
        }
        &.large{
            width:39px;
            height:31px;
        }
        &.big{
            width:29px;
            height:25px;
        }
        &.medium{
            width:25px;
            height:21px;
        }
        &.small{
            width:17px;
            height:15px;
        }
    }

    @media (min-width: ${screenSize.size_m}){
        .modal__title{
            font-size:3.5rem;
            margin-bottom:1rem;
        }

        .modal__subtitle{
            font-size:1.9rem;
        }
    }
    @media (min-width: ${screenSize.size_3xl}){
        .modal__sizeList{
            width:60%;
        }
    }
    @media (max-width: 320px) {
        .modal__sizeList{
            width: 100%;
        }
     }
    
`

type MockupSize = {
    name: string,
    percentaje: number,
}

const mockupSizeList: {
    [key: string]: MockupSize
} = {
    "original": {
        name: "Original size",
        percentaje: 100,
    },
    "large": {
        name: "Large",
        percentaje: 80,

    },
    "big": {
        name: "Big",
        percentaje: 60,

    },
    "medium": {
        name: "Medium",
        percentaje: 40,

    },
    "small": {
        name: "Small",
        percentaje: 20,
    }
}

type Props = {
    mockupMiniature: string,
}

const DownloadModal = (props: Props) => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)
    const [selectedSize, setSelectedSize] = useState<MockupSize>(mockupSizeList.large)

    const getMockupDimensions = (percentaje: number) => {
        const { width, height } = mockupGeneralState.mockup.originalSize
        const newWidht = width * (percentaje / 100)
        const newHeight = height * (percentaje / 100)

        return {
            width: Math.round(newWidht),
            height: Math.round(newHeight),
        }
    }

    const getLabelForGA = (name: string) => {
        if (name.indexOf(" ") > -1) {
            return name.split(" ").join("")
        } else {
            return name
        }
    }

    const handleDownload = async () => {
        const isPremium = mockupGeneralState.mockup.premium && !mockupGeneralState.user.hasMockupsAccess;
        const hasWatermark = mockupGeneralState.user.watermark || !mockupGeneralState.user.hasRemainingDownloads || isPremium;
        if (!hasWatermark && !await ApiClient.registerDownload()) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.user.hasRemainingDownloads = false;
                    draftState.user.watermark = true;
                    draftState.modal = {
                        open: true,
                        type: ModalType.noMoreDownloads
                    };
                })
            );
            return;
        }

        dataLayerPush('mg_downloads', { downloadSize: { clickItem: selectedSize.name, watermark: hasWatermark } })
        sendEvent('MG - Download', `download${getLabelForGA(selectedSize.name)}`)
        sendEvent('MG - Download', 'download')
        const downloadSize = getMockupDimensions(selectedSize.percentaje)
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.download = {
                    startDownload: true,
                    size: {
                        width: downloadSize.width,
                        height: downloadSize.height,
                    }
                }
                draftState.modal = {
                    open: false,
                    type: undefined
                }
            })
        )
    }

    const handleSelectSize = (mockupSize: MockupSize) => () => {
        setSelectedSize(mockupSize)
    }

    return (
        <>
            <div className="modal__header">
                <div className="modal__mockupmini">
                    <img src={props.mockupMiniature} alt="mockup miniature" />
                </div>
                <div className="modal__title">
                    <h2>Looking good!</h2>
                    <h3>Choose a download size to continue.</h3>
                </div>
            </div>



            <DownloadModalWrapper isMobile={isMobile(window.navigator).any}>
                <h2 className="modal__title">Choose the size</h2>
                <h4 className="modal__subtitle">You'll get a PNG file</h4>
                <ul className="modal__sizeList">
                    {Object.keys(mockupSizeList).map(m => {
                        const downloadSize = getMockupDimensions(mockupSizeList[m].percentaje)
                        return (
                            <li
                                onClick={handleSelectSize(mockupSizeList[m])}
                                className="modal__listItem"
                                key={m}
                            >
                                <div className={`mockup__size ${m}`} />
                                <h4>{mockupSizeList[m].name}</h4>
                                <p>{`${downloadSize.width}x${downloadSize.height}`}</p>
                                {
                                    selectedSize.name === mockupSizeList[m].name
                                    && <span className={`selected ${m}`}>
                                        <img src={check} alt="Selected size" />
                                    </span>
                                }
                            </li>
                        )
                    })}
                </ul>

                <Button
                    color={ButtonColor.green}
                    iconSVG={<Download />}
                    label="Download"
                    onClick={handleDownload}
                    large={true}
                    disabled={mockupGeneralState.download.startDownload}
                    loading={mockupGeneralState.download.startDownload}
                />
            </DownloadModalWrapper>

        </>
    )
}

export default DownloadModal