import React from "react"
import styled from "styled-components"
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error.svg"

const ErrorModalWrapper = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    svg{
        width:7rem;
        height:7rem;
        margin-bottom:1.5rem;
    }

    p{
        font-size:2.1rem;
        margin-bottom:1rem;
    }

    button{
        margin-top:2rem;
    }

`

const ErrorModal = () => {

    return (
        <ErrorModalWrapper>
            <ErrorIcon />
            <p>An unexpected error ocurred</p>
            <p>Please, try again</p>
        </ErrorModalWrapper>
    )
}

export default ErrorModal