import {
  AuthenticationModel,
  AuthenticationModelGoogle,
  RegisterModel,
} from "./clientInterfaces/AuthenticationModel";

import { ApiFunctions } from "./ApiConfig";
import { CategoryBindingModel } from "./clientInterfaces/CategoryModel";
import { MockupBindingModel } from "./clientInterfaces/MockupModel";
import categoryImage from "../assets/categoryImage.png";
import { mockupsList } from "../mockups/beta";
import test_avatar from "../assets/test_avatar.png";

const timeoutPromise = (timeout: number) => {
  return new Promise((resolve, _reject) => {
    setTimeout(resolve, timeout);
  });
};

export class MockClient implements ApiFunctions {
  private static instance: MockClient;

  private constructor() {}

  static getInstance(): MockClient {
    if (!MockClient.instance) {
      MockClient.instance = new MockClient();
      return MockClient.instance;
    } else {
      return MockClient.instance;
    }
  }

  getMockup = async (query: MockupBindingModel) => {
    await timeoutPromise(500);
    const mockupResponse = mockupsList.find((m) => m.id === query.mockupId);
    return mockupResponse;
  };

  getRelatedMockups = async (_query: MockupBindingModel) => {
    await timeoutPromise(500);
    return mockupsList;
  };

  getCategories = async () => {
    await timeoutPromise(500);
    return [
      {
        id: 1,
        amount: 5,
        name: "Brochure",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
      {
        id: 2,
        amount: 10,
        name: "Seasonals",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
      {
        id: 3,
        amount: 15,
        name: "Vintage Art Desk Monckups",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
      {
        id: 4,
        amount: 7,
        name: "Device Mockup Pack",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
      {
        id: 5,
        amount: 3,
        name: "Logo mockup pack",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
      {
        id: 6,
        amount: 20,
        name: "Vintage style",
        thumb: categoryImage,
        slug: "slug",
        searchable_tags: [""],
      },
    ];
  };

  getCategoryMockups = async (_query: CategoryBindingModel) => {
    await timeoutPromise(500);
    return mockupsList;
  };

  getUserInfo = async () => {
    await timeoutPromise(500);
    return {
      ab_main_url: "",
      ab_option: "",
      ab_template: "",
      afterLoginJs: "",
      afterLoginJsArgs: "",
      countryCode: "",
      countryName: "",
      currentLang: "",
      error: "",
      globalMessage: "",
      globalMessageTitle: "",
      hasCredit: null,
      hasCreditDaily: "",
      hasCreditMonthly: "",
      hasLicence: null,
      iStockLink: "",
      idPlan: null,
      idUser: "",
      idVector: "",
      isLogged: true,
      isSubscriptor: null,
      isVectorMerchOnly: null,
      isVectorPremium: null,
      isVectorPrintReady: null,
      licence: null,
      licenceType: "",
      paymentFailure: null,
      planName: "",
      pormptPopUp: null,
      pormptPopUpClosable: null,
      pormptPopUpId: "",
      pormptPopUpText: "",
      pormptPopUpTitle: "",
      post: [],
      randomIStockIndex: null,
      token: "",
      userAvatar: test_avatar,
      userName: "Zeppelin",
      userNameFull: "",
      userPlanType: "",
      userTitle: "",
      vectorIsValid: null,
      watermark: false,
      hasMockupsAccess: true,
      hasRemainingDownloads: true,
    };
  };

  registerDownload = async () => {
    await timeoutPromise(500);
    return true;
  }

  getDefaultMockups = async () => {
    await timeoutPromise(500);
    return mockupsList;
  };

  login = async (query: AuthenticationModel) => {
    await timeoutPromise(500);
    return {
      error: false,
      message: "",
      accessToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ",
    };
  };

  loginGoogle = async (token: AuthenticationModelGoogle) => {
    return { error: false, message: "succes auth google" };
  };

  loginFacebook = async (token: string) => {
    return { error: false, message: "succes auth facebook" };
  };

  signUp = async (query: RegisterModel) => {
    await timeoutPromise(500);
    return { error: false, message: "mockup client, what is it?", data: null };
  };

  getCommonTranslations = async () => {
    await timeoutPromise(500);
    return {
      profile: { signup: { main: { emailInUse: "Email already in use" } } },
    };
  };

  getMockupSearch = async (_query: any) => {
    await timeoutPromise(500);
    return { results: mockupsList, count: 100, page: 1 };
  };
}
