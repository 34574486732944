import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import MockupViewer, { PlaceholderBoundsType } from '../../smartCanvas/MockupViewer';
import { mockupState, MockupState, ModalType, SCALE_STATE, SELECTED_SOURCE } from "../../../Recoil/RecoilState";
import ContextBar from '../contextBar/ContextBar';
import { MockupWithImagesViewModel } from '../../../api/clientInterfaces/MockupModel';
import DownloadSpinner from '../spinner/DownloadSpinner';
import { useRecoilState } from "recoil";
import produce from 'immer';
import ErrorBox from '../spinner/ErrorBox';
import { screenSize } from '../../../stylesGlobal/Variables';
import Hammer from 'hammerjs';
import { transform } from 'framer-motion';
import gsap from 'gsap';

type EditorZoneWrapper = {
    isMobile: boolean,
    mockupBarOpen: boolean
}

const EditorZoneWrapper = styled.section<EditorZoneWrapper>`
    height: calc(100% - 6.3rem);
    margin-top: 5.4rem;
    background-color:var(--color-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    flex-direction: column;
    width: ${props => props.isMobile ? "100%" : props.mockupBarOpen ? "58%" : "68%"};
    transition: width .1s linear;
    will-change:width;

    .viewer-container {
        margin-top: ${props => props.isMobile ? 0 : "6.3rem"};
        display: flex;
        justify-content: center;
        align-items: center;
        position:relative;
        transition:margin-left .2s;
        will-change:margin-left;
    }

    .zoomable-container {
        transition: transform 0.1s;
    }

    @media (min-width: ${screenSize.size_m}){
        width: ${props => props.mockupBarOpen ? "63%" : "73%"};
        .viewer-container {
            margin-left:0;
        }
    }

    @media (min-width: ${screenSize.size_2xl}){
        width: ${props => props.mockupBarOpen ? "65%" : "75%"};
    }

    @media (min-width: ${screenSize.size_3xl}){
        width: ${props => props.mockupBarOpen ? "70%" : "78%"};
    }
`;

type Props = {
    isMobile: boolean,
    loading: boolean,
    error: boolean,
    mockup: MockupWithImagesViewModel,
    setMockupReady: () => void,
    mockupBarOpen: boolean,
    mockupReady: boolean,
    setMockupBarOpen: (value: boolean) => void
}

const EditorZone = (props: Props) => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState);
    const mockupContainerRef = useRef<HTMLElement>();
    const gesturesElementRef = useRef<HTMLDivElement | null>(null);
    const canvasReference = useRef<HTMLDivElement | null>(null);

    const initialCanvasScale = useRef<number>(0);
    const scaleLastRef = useRef<number>(1);
    const isEditingRef = useRef<boolean>(false);

    const adjustRotation = useRef<number>(0);
    const currentScale = useRef<number>(1);
    const currentRotation = useRef<number>(0);
    const currentDeltaX = useRef<number>(0);
    const currentDeltaY = useRef<number>(0);
    const adjustDeltaX = useRef<number>(0);
    const adjustDeltaY = useRef<number>(0);

    const lastImageScale = useRef<number>(1);
    const startImageScale = useRef<number>(1);
    const maxWidth = useRef<number>(0);
    const minWidth = useRef<number>(0);

    const velocity = useRef<number>(0);
    const getTouchVelocity = useRef(() => {
        return velocity.current;
    });

    useEffect(() => {
        if (mockupGeneralState.selectedImage && props.isMobile) {
            maxWidth.current = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale * 3;
            minWidth.current = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale / 3;
            lastImageScale.current = transform(
                mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].imageScale
                    ? mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].imageScale
                    : mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale,
                [minWidth.current, maxWidth.current], [0, 4]
            );
        }
    }, [mockupGeneralState.mockup.images, mockupGeneralState.selectedImage, props.isMobile])

    useEffect(() => {
        if (mockupGeneralState.selectedImage && mockupGeneralState.selectedImage?.bounds && props.isMobile) {
            const bounds = mockupGeneralState.selectedImage.bounds;
            const container = canvasReference.current;
            const contW = container.clientWidth;
            const contH = container.clientHeight;
            const PivotX = bounds.x + (bounds.width / 2);
            const PivotY = bounds.y + (bounds.height / 2);
            const amountX = PivotX > (contW / 2) ? ((PivotX - (contW / 2)) * -1) : ((contW / 2) - PivotX);
            const amountY = PivotY > (contH / 2) ? ((PivotY - (contH / 2)) * -1) : ((contH / 2) - PivotY);
            gsap.to(canvasReference.current, {
                transform: `scale(${1}) translate(${amountX}px, ${amountY}px)`,
                ease: "power1.inOut",
                duration: 0.5
            })
            scaleLastRef.current = 1;
            currentScale.current = 1;
            adjustDeltaX.current = amountX;
            adjustDeltaY.current = amountY;
        }
    }, [mockupGeneralState.selectedImage])


    useEffect(() => {
        if (props.isMobile) {
            isEditingRef.current = mockupGeneralState.mobileState.isEditing;

            if (isEditingRef.current) {
                adjustRotation.current = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].rotationDegrees;
                currentRotation.current = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].rotationDegrees;
            }
        }
    }, [mockupGeneralState.mobileState.isEditing, props.isMobile])


    const setCanvasRef = (canvasRef: HTMLDivElement) => {
        if (props.isMobile) {
            canvasReference.current = canvasRef;
            if (canvasReference.current && initialCanvasScale.current === 0) {
                const trans = window.getComputedStyle(canvasReference.current).transform;
                if (trans !== "none") {
                    const numberPattern = /-?\d+\.?\d*/g;
                    const values = trans.match(numberPattern);
                    initialCanvasScale.current = Number(values[0]);
                    scaleLastRef.current = initialCanvasScale.current;
                    currentScale.current = initialCanvasScale.current;
                }
            }
        }
    }

    useEffect(() => {
        if (gesturesElementRef.current && !props.loading && props.isMobile) {
            const hammertime = new Hammer.Manager(gesturesElementRef.current);
            const pinch = new Hammer.Pinch();
            const rotate = new Hammer.Rotate();
            const pan = new Hammer.Pan();

            hammertime.add([pinch, rotate, pan]);
            hammertime.get('pinch').set({ enable: true });
            hammertime.get('rotate').set({ enable: true });

            document.body.addEventListener('touchstart', (e) => {
                velocity.current = 0;
            })

            hammertime.on("rotatestart pinchstart panstart", (e) => {
                velocity.current = 0;
                if (isEditingRef.current) {
                    adjustRotation.current -= e.rotation;
                    startImageScale.current = lastImageScale.current;
                }
            });

            hammertime.on("pinchmove rotatemove panmove", (ev) => {
                if (scaleLastRef.current * ev.scale < 2 && !isEditingRef.current) {
                    currentScale.current = scaleLastRef.current * ev.scale;
                }
                if (!isEditingRef.current) {
                    currentDeltaX.current = adjustDeltaX.current + (ev.deltaX / currentScale.current);
                    currentDeltaY.current = adjustDeltaY.current + (ev.deltaY / currentScale.current);
                    gsap.set(canvasReference.current, {
                        transform: `scale(${currentScale.current}) translate(${currentDeltaX.current}px, ${currentDeltaY.current}px)`
                    })
                    velocity.current = ev.overallVelocity;
                }
                if (isEditingRef.current) {
                    if (maxWidth.current !== 0 && minWidth.current !== 0) {
                        const newValue = transform(startImageScale.current * ev.scale, [0, 4], [minWidth.current, maxWidth.current]);
                        currentRotation.current = adjustRotation.current + ev.rotation;
                        setMockupGeneralState(produce((draftState: MockupState) => {
                            draftState.mockup.images[draftState.selectedImage.id].imageScale = newValue;
                            draftState.mockup.scaleState = SCALE_STATE.changeing;
                            draftState.mockup.images[draftState.selectedImage.id].rotationDegrees = currentRotation.current;
                        }))
                    }
                }
            });

            hammertime.on("pinchend rotateend panend", (ev) => {
                if (!isEditingRef.current) {
                    adjustDeltaX.current = 0;
                    adjustDeltaY.current = 0;

                    if (scaleLastRef.current * ev.scale > 2) {
                        scaleLastRef.current = 2;
                        adjustDeltaX.current = currentDeltaX.current;
                        adjustDeltaY.current = currentDeltaY.current;
                    } else if (scaleLastRef.current * ev.scale <= initialCanvasScale.current) {
                        gsap.to(canvasReference.current, {
                            transform: `scale(${initialCanvasScale.current}) translate(${0}px, ${0}px)`,
                            duration: 0.16
                        })
                        scaleLastRef.current = initialCanvasScale.current;
                        adjustDeltaX.current = 0;
                        adjustDeltaY.current = 0;
                        currentDeltaX.current = 0;
                        currentDeltaY.current = 0;
                    } else {
                        scaleLastRef.current = scaleLastRef.current * ev.scale;
                        adjustDeltaX.current = currentDeltaX.current;
                        adjustDeltaY.current = currentDeltaY.current;
                    }
                }
                if (isEditingRef.current) {
                    adjustRotation.current = currentRotation.current;
                }
            });
        }
    }, [props.isMobile, props.loading, setMockupGeneralState])

    const handleEndCenterImage = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                if (draftState.selectedImage) {
                    draftState.mockup.images[draftState.selectedImage.id].centerImage = false
                }
            })
        )
    }

    const handleEndDownload = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.download = {
                    startDownload: false,
                    size: {
                        width: undefined,
                        height: undefined,
                    }
                }
            })
        )
        window.dispatchEvent(new Event('finishDownload'));
    }

    const setOriginalSize = (width: number, height: number) => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.originalSize = {
                    width: width,
                    height: height,
                }
            })
        )
    }

    const setIsBackgroundChanging = (isChanging: boolean) => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.isBackgroundChanging = isChanging
            })
        )
    }

    const registerDragEvent = () => {
        // setMockupGeneralState(
        //     produce((draftState: MockupState) => {
        //         if (draftState.userImage.base64 !== "") {
        //             draftState.events.push(EventTypes.moveImage)
        //         }
        //     })
        // )
    }

    const handleErrorModal = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.modal = {
                    open: true,
                    type: ModalType.error
                }
            })
        )
    }


    const renderSpinner = () => {
        if (mockupGeneralState.isUploading) {
            return <DownloadSpinner label="Uploading image" overlay={true} />
        } else if (mockupGeneralState.mockup.isBackgroundChanging) {
            return <DownloadSpinner label="Updating background" overlay={true} />
        }
    }

    const setDefaultScale = (defaultScale: number) => {
        if (mockupGeneralState.selectedImage) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    if (
                        draftState.selectedImage
                        && draftState.mockup.images[draftState.selectedImage.id]
                        && !draftState.mockup.images[draftState.selectedImage.id].imageScale
                        && !draftState.mockup.images[draftState.selectedImage.id].defaultScale) {
                        draftState.mockup.images[draftState.selectedImage.id].imageScale = defaultScale
                        draftState.mockup.images[draftState.selectedImage.id].defaultScale = defaultScale
                    }
                })
            )
        }
    }

    const handleDeformerImagesProcessed = (ids: string[]) => {
        const newDeformerImages = {};
        const newImageAtributes = {};
        const oldUploads = Object.keys(mockupGeneralState.userUploads);
        ids.forEach((id, index) => {
            if (oldUploads[index]) {
                newDeformerImages[id] = {
                    id,
                    userImage: {
                        imageFile: mockupGeneralState.userUploads[oldUploads[index]].userImage.imageFile,
                        base64: mockupGeneralState.userUploads[oldUploads[index]].userImage.base64
                    }
                }
            } else {
                newDeformerImages[id] = {
                    id,
                    userImage: {
                        imageFile: null,
                        base64: ""
                    }
                }
            }
            newImageAtributes[id] = {
                id,
                centerImage: false,
                rotationDegrees: 0,
                imageScale: undefined,
                defaultScale: undefined,
            }
        })
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                if (!props.isMobile) {
                    draftState.selectedImage = {
                        id: Object.keys(newDeformerImages)[0],
                        source: SELECTED_SOURCE.CANVAS,
                        bounds: undefined
                    };
                }
                draftState.userUploads = newDeformerImages;
                draftState.mockup.images = newImageAtributes;
            })
        )
    }

    const handleSlectedImageId = (id: string, defaultScale: number, placeholderBounds: PlaceholderBoundsType) => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mobileState.isEditing = true;
                draftState.selectedImage = id ? {
                    id: id,
                    source: SELECTED_SOURCE.CANVAS,
                    bounds: placeholderBounds
                } : undefined;
                if (id && !draftState.mockup.images[id].imageScale && !draftState.mockup.images[id].defaultScale) {
                    draftState.mockup.images[id].imageScale = defaultScale
                    draftState.mockup.images[id].defaultScale = defaultScale
                }
            })
        )
    }

    const getImageScale = () => {
        if (mockupGeneralState.selectedImage && mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id]) {
            return mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].imageScale
        }
        return 1;
    }

    const getImageRotation = () => {
        if (mockupGeneralState.selectedImage && mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id]) {
            return mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].rotationDegrees
        }
        return 0;
    }

    const getImageCenter = () => {
        if (mockupGeneralState.selectedImage && mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id]) {
            return mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].centerImage
        }
        return false;
    }

    const getHexValueForPixi = (hex: string) => {
        const noHash = hex.split("#")[1];
        return `0x${noHash}`
    }

    const handleIsEditing = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mobileState.isEditing = true;
            })
        )
    }

    const renderMockupViewer = () => {
        if (props.loading) {
            return <DownloadSpinner label="Loading mockup" />
        } else if (props.error) {
            return <ErrorBox label="Error on loading mockup" />
        } else if (!props.loading && props.mockup && mockupContainerRef.current) {
            const isPremium = mockupGeneralState.mockup.premium && !mockupGeneralState.user.hasMockupsAccess;
            const hasWatermark = mockupGeneralState.user.watermark || !mockupGeneralState.user.hasRemainingDownloads || isPremium;
            return (
                <React.Fragment>
                    {!props.isMobile && mockupGeneralState.selectedImage && mockupGeneralState.selectedImage.source === SELECTED_SOURCE.CANVAS && <ContextBar
                        setMockupBarOpen={() => props.setMockupBarOpen(!props.mockupBarOpen)}
                        mockupBarOpen={props.mockupBarOpen}
                    />}
                    <div className="viewer-container" ref={gesturesElementRef}>
                        {renderSpinner()}
                        <div className="zoomable-container">
                            <MockupViewer
                                getTouchVelocity={getTouchVelocity.current}
                                setCanvasRef={setCanvasRef}
                                setIsEditing={handleIsEditing}
                                isEditing={mockupGeneralState.mobileState.isEditing}
                                isMobile={props.isMobile}
                                filterPower={mockupGeneralState.filterIntensity}
                                filter={mockupGeneralState.filter}
                                baseColor={props.mockup.basecolor ? getHexValueForPixi(props.mockup.basecolor) : props.mockup.basecolor}
                                selectedImageId={mockupGeneralState.selectedImage}
                                highlightedImage={mockupGeneralState.highlightedImage}
                                setSelectedImageId={handleSlectedImageId}
                                deformerImagesLoaded={handleDeformerImagesProcessed}
                                mockupReady={props.mockupReady}
                                setDefaultScale={setDefaultScale}
                                centerImage={getImageCenter()}
                                endCenterImage={handleEndCenterImage}
                                startDownload={mockupGeneralState.download.startDownload}
                                endDownload={handleEndDownload}
                                imageRotation={getImageRotation()}
                                imageScale={getImageScale()}
                                imageScaleState={mockupGeneralState.mockup.scaleState}
                                uploadedImages={mockupGeneralState.userUploads}
                                relativeElement={mockupContainerRef.current}
                                mockup={props.mockup}
                                backgroundSelected={mockupGeneralState.mockup.baseImageIndex}
                                backgroundColor={mockupGeneralState.mockup.color}
                                downloadSize={
                                    {
                                        width: mockupGeneralState.download.size.width,
                                        height: mockupGeneralState.download.size.height,
                                    }
                                }
                                setOriginalSize={setOriginalSize}
                                setIsBackgroundChanging={setIsBackgroundChanging}
                                setMockupReady={props.setMockupReady}
                                registerDragEvent={registerDragEvent}
                                handleErrorModal={handleErrorModal}
                                watermark={hasWatermark}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }
    }
    return (
        <EditorZoneWrapper
            isMobile={props.isMobile}
            mockupBarOpen={props.mockupBarOpen}
            ref={mockupContainerRef}
        >
            {renderMockupViewer()}
        </EditorZoneWrapper>
    )
}

export default EditorZone;