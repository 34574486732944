


const isProduction = process.env.NODE_ENV === 'production'
export const isDevelopment = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV
const requestTestMockups= process.env.REACT_APP_API_REQUEST_TEST_MOCKUPS === "true"

const envType =
    (
        (isProduction && (process.env.REACT_APP_ENV_TYPE || "prod"))
        || (isDevelopment && "dev-local")
    ) as string

const buildId = process.env.REACT_APP_VERSION || "Neutral"

const buildName =
    envType + "-" + buildId

const apiMock = process.env.REACT_APP_API_MOCK === "true"

const sentryDSN = "https://32e8fba4403a4990b541b2c459a9db6d@o256449.ingest.sentry.io/5271874"

export const EnvHelper = {
    isProduction,
    isDevelopment,
    buildName,
    buildId,
    sentryDSN,
    envType,
    apiMock,
    requestTestMockups
}