import React from "react"
import styled from "styled-components"
import TabTitle from "../TabTitle"
import ColorPicker from "./ColorPicker"
import Spinner from "../../spinner/Spinner"
import ColorsList from '../ColorsList/ColorsList';
import isMobile from 'ismobilejs';

const CustomizeColorWrapper = styled.section`
    display: grid;
    gap: 2.5em;

    .colorPicker__loading{
        margin-top:5rem;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    button{
        margin:0 auto;
    }
`

const Hr = styled.hr`
  border: none;
  border-bottom: 1px solid #DEDEDE;
`;

type Props = {
    loading: boolean,
}

const CustomizeColor = (props: Props) => {

    const renderColorPicker = () => {
        if (props.loading) {
            return (
                <div className="colorPicker__loading">
                    <Spinner />
                </div>
            )
        } else {
            return (
                <ColorPicker />
            )
        }

    }

    return (
        <CustomizeColorWrapper>
            <TabTitle
                title="Customize color"
            />
            {renderColorPicker()}
            {!isMobile(window.navigator).any && <Hr />}
            <ColorsList />
        </CustomizeColorWrapper>
    )
}

export default CustomizeColor