import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import arrow from "../../../assets/icons/arrow.svg"
import { motion } from "framer-motion"
import MockupList from './MockupList'
import Categories from './categories/Categories'
import { screenSize } from '../../../stylesGlobal/Variables'
import { useWindowSize } from '../../../utils/CustomHooks/useWindowSize'
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg"
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg"
import { ReactComponent as SearchEmptyIcon } from "../../../assets/icons/search-empty.svg"
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import { CategoryViewModel } from '../../../api/clientInterfaces/CategoryModel'
import { sendEvent } from '../../../utils/Events'
import { ApiClient } from "../../../api/ApiClient"
import MockupsGrid from "./MockupsGrid"
import SpinnerVexels from "../spinner/SpinnerVexels"
import { debounce } from 'lodash';
import { dataLayerPush } from '../../../utils/DataLayer'

type MockupsBarWrapperProps = {
    open: boolean,
    openBrowseMockups: boolean
}

const MockupsBarWrapper = styled(motion.aside) <MockupsBarWrapperProps>`
    min-height:100vh;
    background-color:var(--color-white);
    position:absolute;
    z-index:var(--zIndex-low);
    right:0;
    display:flex;
    align-items:center;
    flex-direction:column;
    padding-top:${props => props.open ? "6.3rem" : "13.8rem"};
    z-index:var(--zIndex-medium);
    box-shadow: -1px 0 31px 9px #0000001A;

    &::after{
        content:"";
        display:block;
        position:absolute;
        z-index:var(--zIndex-low);
        top:0;
        left:-.1rem;
        width:.2rem;
        height:100vh;
        background:var(--color-blue);
        opacity:0;
        transition:opacity .2s;
    }

    &:hover{
        &::after{
            opacity:1;
        }
        .mockupsBar__openButton{
            background-color:var(--color-blue);
            img{
                filter:invert(1);
            }
        }
    }

    .mockupsBar__openButton{
        border:none;
        background-color:var(--color-white);
        position:absolute;
        z-index:var(--zIndex-low-medium);
        top:calc(50vh - 1.4rem);
        left:-1.4rem;
        border-radius:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        width:2.8rem;
        height:2.8rem;
        transition:transform .1s,background-color .1s;
        box-shadow: -6px 0 12px -1px #0000001A;

        img {
            width:.6rem;
        }

        &:focus{
            outline:none;
        }
    }

    .mockupsBar__loading{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(255,255,255,.5);
        cursor:not-allowed;
    }

    .mockupsBar__title{
        width:100%;
        text-align:center;
        font-size:${props => props.openBrowseMockups ? '1.8rem' : '1.2rem'};
        font-weight:500;
        margin-top: 1.5rem;
        margin-bottom:2rem;
        position: relative;

        span {
            display: inline-block;
        }

        .mockupsBar__arrow {
            width: 30px;
            display: inline-block;
            left: 14px;
            position: absolute;
            background: #F2F2F2;
            border-radius: 100%;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                width: 6px;
                transform: rotate(180deg);
            }

            svg path {
                stroke: #707070;
            }
        }
    }

    .mockupsBar-container{
        position: relative;
        overflow-y: auto;
        height: 91vh;
        padding:0 2rem;
        width: 100%;
        overflow-x: hidden;

        &.--collapsed{
            overflow:hidden;
            user-select:none;
        }
        .mockupsBar__noResults{
            text-align: center;
            padding: 1rem 0;
            color: #3D3D3D;
        }
        .mockupsBar__noResultsTitle{
            font-size: 1.8rem;
            font-weight: 500;
        }
        .mockupsBar__noResultsSubtitle{
            font-size: 1.5rem;
        }
    }

    .preventClick{
        position:absolute;
        top:0;
        left:0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: transparent;
        user-select:none;
        cursor:pointer;
    }

    .searchBar_container{
        display: flex;
        justify-content: center;
        width:100%;
        max-width: 500px;
        margin: 0 auto;
        position: relative;
        .searchBar{
            width:100%;
            padding: 12px 20px;
            margin-bottom:2rem;
            border-radius: 36px;
            border: 1px solid #E8E8E8;
            &:focus{
                outline: none;
            }
        }
        .searchIcon{
            position: absolute;
            right: 16px;
            top: 8px;
            &.gray{
                cursor: pointer;
                top: 16px;
                path{
                    fill: #707070;
                }
            }
        }
    }

    @media (min-width: ${screenSize.size_m}){
        .mockupsBar__title{
            font-size:${props => props.openBrowseMockups ? '1.8rem' : '1.4rem'};
        }
    }
    
`

type Props = {
    selectedMockupId: number,
    mockupReady: boolean,
    mockupBarOpen: boolean,
    setMockupBarOpen: React.Dispatch<React.SetStateAction<boolean>>,
    error: boolean,
}

const MockupsBar = (props: Props) => {
    const [searchText, setSearchText] = useState('')
    const [page, setPage] = useState(1)
    const [searchResult, setSearchResult] = useState([])
    const [openBrowseMockups, setOpenBrowseMockups] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<CategoryViewModel>(undefined)
    const windowSize = useWindowSize();
    const [updateLoad, setUpdateLoad] = useState(0);
    const updateLoadRef = useRef(updateLoad);
    const [loading, setLoading] = useState(false)
    const [hasMoreMockups, setHasMoreMockups] = useState(true)
    const loadButtonRef = useRef<HTMLDivElement | null>(null);
    const searchInputRef = useRef(null);
    const [observerDefined, setObserverDefined] = useState(false)

    useEffect(() => {
        setOpenBrowseMockups(false)
    }, [props.selectedMockupId])

    const handleToggleBar = () => {
        if (openBrowseMockups) {
            setOpenBrowseMockups(false)
        } else {
            props.setMockupBarOpen(prevState => !prevState)
        }
    }

    const handleBrowseAllMockups = (arrow?: boolean) => {
        if (arrow) {
            sendEvent('MG - Related Column', 'openArrow');
        }
        sendEvent('MG - Related Column', 'browseCategories');
        setOpenBrowseMockups(!openBrowseMockups)
    }

    const getMockupBarWidth = () => {
        if (windowSize.width < 1920) {
            return "17%"
        } else {
            return "15%"
        }
    }

    const getMockupBarTranslateX = () => {
        if (windowSize.width < 1920) {
            return "58%"
        } else {
            return "53%"
        }
    }

    const mockupBarVariants = {
        expanded: {
            x: "0%",
            width: getMockupBarWidth(),
        },
        collapsed: {
            x: getMockupBarTranslateX(),
            width: getMockupBarWidth(),
        },
        expandedBrowseMockups: {
            x: "0%",
            width: "70%"
        },
    }

    const buttonVariants = {
        expanded: {
            transform: "rotate(0deg)",
        },
        collapsed: {
            transform: "rotate(180deg)",
        }
    }

    const renderButton = () => {
        return (
            <motion.button
                className={`mockupsBar__openButton`}
                variants={buttonVariants}
                initial={openBrowseMockups ? "expanded" : "collapsed"}
                animate={openBrowseMockups ? "expanded" : "collapsed"}
                onClick={() => handleBrowseAllMockups(true)}
            >
                <img src={arrow} alt="toggle toolbar" />
            </motion.button>
        )
    }

    const debounceMockupsSearch = useCallback(debounce((nextValue) => {
        dataLayerPush('mg_search_mockups', { search: { term: nextValue } });
        mockupsSearch(nextValue)
    }, 300), [])

    const changeHandler = async (event) => {
        const newText = event.target.value;
        debounceMockupsSearch(newText);
    }

    const mockupsSearch = async (newText) => {
        setSearchText(newText);
        startNewSearch();
        if (newText.length) {
            setSearchResult([]);
            const { results, count } = await loadMockups({ newText, newPage: 1 });
            setSearchResult(results);
            setHasMoreMockups(count > results.length);
        }
    }

    const startNewSearch = () => {
        setPage(1);
        setObserverDefined(false);
    }

    const loadNextPageMockups = async () => {
        try {
            const newPage = page + 1;
            const { results, count, page: loadedPage } = await loadMockups({ newPage });
            setPage(loadedPage);
            const newResults = [...searchResult, ...results]
            setSearchResult(newResults);
            setLoading(false);
            setHasMoreMockups(count > newResults.length);
        } catch (e) {
            console.error(e)
        }
    }

    const loadMockups = async ({ newPage, newText }: { newPage?: number, newText?: string }) => {
        setLoading(true);
        const { results, count, page: loadedPage } = await ApiClient.getMockupSearch({ mockup: newText || searchText, page: newPage || page });
        setLoading(false);
        return { results, count, page: loadedPage };
    }

    const clearSearch = async () => {
        setSearchText('');
        setSearchResult([]);
        startNewSearch();
        setHasMoreMockups(true);
        searchInputRef.current.value = '';
    }

    useEffect(() => {
        if (updateLoad) {
            loadNextPageMockups()
        }
    }, [updateLoad])

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !loading) {
                    setUpdateLoad(updateLoadRef.current + 1);
                    updateLoadRef.current = updateLoadRef.current + 1;
                }
            });
        })
        if (loadButtonRef.current && !observerDefined) {
            observer.observe(loadButtonRef.current);
            setObserverDefined(true);
        }
    }, [searchResult.length]);

    const loadMore = () => {
        return <div className="mockupsCategories__loadMore" ref={loadButtonRef}><SpinnerVexels scale={0.2} /></div>
    }

    const noResults = <div className="mockupsBar__noResults">
        <SearchEmptyIcon />
        <p className="mockupsBar__noResultsTitle">Sorry, we couldn't find matches for {searchText}.</p>
        <p className="mockupsBar__noResultsSubtitle">Check these categories you might like:</p>
    </div>

    return (
        <MockupsBarWrapper
            variants={mockupBarVariants}
            initial={openBrowseMockups ? "expandedBrowseMockups" : props.mockupBarOpen ? "expanded" : "collapsed"}
            animate={openBrowseMockups ? "expandedBrowseMockups" : props.mockupBarOpen ? "expanded" : "collapsed"}
            transition={{ ease: "linear", duration: .1 }}
            open={props.mockupBarOpen}
            openBrowseMockups={openBrowseMockups}
        >
            {renderButton()}
            <div className={`mockupsBar-container ${!props.mockupBarOpen ? "--collapsed" : ""}`}>
                {openBrowseMockups && (
                    <>
                        <h2 className="mockupsBar__title">
                            {selectedCategory && <div onClick={() => setSelectedCategory(undefined)} className="mockupsBar__arrow"><ArrowIcon /></div>}<span>Search mockups</span>
                        </h2>
                        <div className="searchBar_container">
                            <input
                                ref={searchInputRef}
                                className="searchBar"
                                type="text"
                                placeholder="Search mockups..."
                                onChange={changeHandler}
                            />
                            {searchText ? <Remove onClick={clearSearch} className="searchIcon gray" /> : <SearchIcon className="searchIcon" />}
                        </div>
                        {searchResult.length === 0 && loading
                            ? <SpinnerVexels scale={0.2} />
                            : searchResult.length > 0 && searchText ?
                                <>
                                    <MockupsGrid items={searchResult} selectedMockupId={props.selectedMockupId} />
                                    {hasMoreMockups && loadMore()}
                                </>
                                : <>
                                    {searchText && !loading && searchResult.length === 0 && noResults}
                                    <Categories
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={setSelectedCategory}
                                        selectedMockupId={props.selectedMockupId}
                                    />
                                </>
                        }
                    </>
                )}
                <MockupList
                    open={props.mockupBarOpen}
                    openBrowseMockups={openBrowseMockups}
                    selectedMockupId={props.selectedMockupId}
                    handleBrowseAllMockups={() => handleBrowseAllMockups()}
                />
            </div>
            {!props.error && !props.mockupReady && <div className="mockupsBar__loading" />}
            {!props.mockupBarOpen && !openBrowseMockups && <div className="preventClick" onClick={handleToggleBar} />}
        </MockupsBarWrapper>
    )
}

export default MockupsBar