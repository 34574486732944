import React from 'react';
import ReactDOM from 'react-dom';
import './utils/SetupSentry';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RecoilRoot } from "recoil";
import ErrorBoundary from './components/error/ErrorBoundary';
import 'requestidlecallback-polyfill';
import "./utils/InteractingService";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { isDevelopment } from './utils/EnvHelper';


if (!isDevelopment) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID
  })
} else {
  console.log("Development")
}


ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
