import React, { useState } from "react";
import styled from "styled-components";
import ColorsList from "../toolbar/ColorsList/ColorsList";
import ColorPicker from "../toolbar/CustomizeColor/ColorPicker";
import TabTitle from "../toolbar/TabTitle";

const ColorControllWrapper = styled.div`

    .color-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 285px;
        margin: 0 auto;

        .color-tab {
            flex: 1;
            border-bottom: 1px solid #00000038;
            text-align: center;
            font-size: 12px;
            padding: 11px;
        }

        .color-active {
            border-bottom: 2px solid var(--color-blue);
        }
    }
`;

const ColorComponentWrapper = styled.div`
        padding: 2em;
`;

const ColorControll = () => {
    const [tab, setTab] = useState(0);
    const colorComponent = () => {
        if (tab === 0) {
            return <ColorPicker />
        } else {
            return <ColorsList />
        }
    }
    return (
        <ColorControllWrapper>
            <TabTitle
                title="Color"
            />
            <div className="color-tabs">
                <div onClick={() => setTab(0)} className={`color-tab ${tab === 0 && ' color-active'}`}>Customize Color</div>
                <div onClick={() => setTab(1)} className={`color-tab ${tab === 1 && ' color-active'}`}>Amazon Colors</div>
            </div>
            <ColorComponentWrapper>
                {colorComponent()}
            </ColorComponentWrapper>
        </ColorControllWrapper>
    )
}

export default ColorControll;