import React from "react"
import styled, { css } from "styled-components"
import Spinner from "../../spinner/Spinner"
import { CategoryViewModel } from "../../../../api/clientInterfaces/CategoryModel"
import { screenSize } from "../../../../stylesGlobal/Variables"
import CategoryMockups from "./CategoryMockups"
import ErrorMessage from "../../../error/ErrorMessage"
import EmptyState from "../../../emptyState/EmptyState"
import { ApiClient } from "../../../../api/ApiClient"
import { useGetRequest } from "../../../../api/useGetRequest"
import { useRecoilValue } from "recoil"
import { mockupState } from "../../../../Recoil/RecoilState"
import isMobile from 'ismobilejs';
import { dataLayerPush } from "../../../../utils/DataLayer";

type MockupCategoriesWrapperProps = {
    isMobile?: boolean
}

const MockupCategoriesWrapper = styled.div<MockupCategoriesWrapperProps>`

    width:100%;
    overflow-x:hidden;
    padding:${props => props.isMobile ? '0' : '0 1rem'};
    min-height:20rem;
    height: auto;

    .categories__loading{
        margin-top:5rem;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .categories__list{
        display:flex;
        flex-wrap:wrap;
        margin:2rem auto 0 auto;
        justify-content: ${props => props.isMobile ? 'center' : 'initial'};

        ${props => !props.isMobile && css`
            &:after {
                content: "";
                flex: auto;
            }`
    } 
        
    }

    .categories__item{
        width:${props => props.isMobile ? '40%' : '23%'};
        margin:0 1% 2rem 1%;
        cursor:pointer;
        box-shadow: 0px 0px 17px #0000001A;
        border-radius: 5px;
        transition:transform .2s;
        min-height:16rem;
        ${(props) => props.isMobile ? css`
        margin: 0 2% 4% 2%;
        border-radius: 5px;
        min-height:15rem;
        `: null}
        img{
            width: 100%;
        ${(props) => props.isMobile ? css`
        border-radius: 5px;
        `: null} 
        }

        .placeholder{
            border-radius: 5px;
            background-color:#FBFBFB;
            width:100%;
            height:14rem;
        }

        &:hover{
            transform:translateY(-5px);
        }
    }

    .categories__itemInfo{
        padding: .8rem;
        h2{
            font-size:1.2rem;
            font-weight: 500;
            margin-bottom:.5rem;
            ${(props) => props.isMobile ? css`
            font-size:1.2rem;
            font-weight: 600;
            `: null} 
        }
        p{
            font-size:1rem;
            color:#B9B9B9;
        }
    }

    @media (min-width: ${screenSize.size_xl}){
        .categories__itemInfo{
            padding: .8rem;
            h2{
                font-size:1.4rem;
                font-weight: 500;
            }
            p{
                font-size:1.2rem;
            }
        }
    }

    @media (min-width: ${screenSize.size_2xl}){
        padding:0 1rem;
        .categories__itemInfo{
            padding: 1rem;
            h2{
                font-size:1.5rem;
                font-weight: 600;
            }
            p{
                font-size:1.4rem;
            }
        }
    }

    @media (min-width: ${screenSize.size_3xl}){
        padding:0 4rem;
        .categories__itemInfo{
            padding:2rem;
            h2{
                font-size:1.6rem;
                font-weight: 600;
            }
            p{
                font-size:1.4rem;
            }
        }
    }
`

export type CategoriesResponse = {
    error: boolean,
    loading: boolean,
    data: CategoryViewModel[],
}

type Props = {
    selectedMockupId: number,
    selectedCategory: CategoryViewModel,
    setSelectedCategory: (category: CategoryViewModel) => void
}

const Categories = (props: Props) => {

    const mockupGeneralState = useRecoilValue(mockupState)

    const { handleRetry } = useGetRequest({
        key: "Categories",
        fetcher: () => ApiClient.getCategories()
    })

    const handleClickCategory = (category: CategoryViewModel) => () => {
        dataLayerPush('mg_choose_category', {
            clickCategory: {
                clickItem: category.name
            }
        })
        props.setSelectedCategory(category)
    }

    const cleanSelectedCategory = () => {
        props.setSelectedCategory(undefined)
    }

    const renderCategories = () => {
        const { loading, error, data } = mockupGeneralState.categories
        if (loading) {
            return (
                <div className="categories__loading">
                    <Spinner />
                </div>
            )
        } else if (error) {
            return <ErrorMessage
                label="Error loading categories"
                handleRetry={handleRetry}
            />
        } else if (!loading && data && data.length === 0) {
            return (
                <EmptyState
                    message="No categories available"
                />
            )
        }
        else if (!loading && data && data.length > 0) {
            return (
                <ul className="categories__list">
                    {data.map(c => {
                        return (
                            <li
                                key={c.id}
                                className="categories__item"
                                onClick={handleClickCategory(c)}
                            >
                                {c.thumb
                                    ? <img src={c.thumb} alt={c.name} />
                                    : <div className="placeholder" />
                                }
                                <div className="categories__itemInfo">
                                    <h2>{c.name}</h2>
                                    <p>{`${c.amount} ${c.amount > 1 ? "designs" : "design"}`}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            )
        } else {
            return null
        }
    }

    return (
        <MockupCategoriesWrapper isMobile={isMobile(window.navigator).any}>
            {props.selectedCategory
                ? <CategoryMockups
                    selectedCategory={props.selectedCategory}
                    cleanSelectedCategory={cleanSelectedCategory}
                    selectedMockupId={props.selectedMockupId}
                />
                : renderCategories()}
        </MockupCategoriesWrapper>
    )
}

export default Categories