import {
  ENUM_FILTER,
  Filters,
} from "../components/smartCanvas/FiltersFunctions";
import { atom, atomFamily } from "recoil";

import { CategoryViewModel } from "../api/clientInterfaces/CategoryModel";
import { MockupWithImagesViewModel } from "../api/clientInterfaces/MockupModel";
import { PlaceholderBoundsType } from "../components/smartCanvas/MockupViewer";

export type FILTER = {
  name: string;
  filterType: ENUM_FILTER;
};

export enum ModalType {
  download = "DOWNLOAD",
  subscription = "SUBSCRIPTION",
  error = "ERROR",
  login = "LOGIN",
  signUp = "SIGN_UP",
  mockupsAccess = "MOCKUPS",
  noMoreDownloads = "NO_MORE_DOWNLOADS",
}

export enum EventTypes {
  mockupChange = "MOCKUP_CHANGE",
  scaleImage = "SCALE_IMAGE",
  rotateImage = "ROTATE_IMAGE",
  moveImage = "MOVE_IMAGE",
  colorChange = "COLOR_CHANGE",
  backgroundChange = "BACKGROUND_CHANGE",
}

export enum SCALE_STATE {
  start = "START",
  changeing = "CHANGEING",
  stop = "STOP",
}

export type UserImageType = {
  imageFile: File;
  base64: string;
};

type MockupImagesAttributesType = {
  id: string;
  centerImage: boolean;
  rotationDegrees: number;
  imageScale: number;
  defaultScale: number;
};

export type UserUploadType = {
  id: string;
  userImage: UserImageType;
};

export enum SELECTED_SOURCE {
  CANVAS = "CANVAS",
  OTHER = "OTHER",
}

export type MockupState = {
  mobileState: {
    isEditing: boolean;
    canvasZoom: number;
    canvasPan: { x: number; y: number };
  };
  filter: FILTER;
  filterIntensity: number;
  uploadedImagesStock: UserImageType[];
  highlightedImage: string;
  selectedImage: {
    id: string;
    source: SELECTED_SOURCE;
    bounds: PlaceholderBoundsType;
  };
  userUploads: { [id: string]: UserUploadType };
  isUploading: boolean;
  download: {
    startDownload: boolean;
    size: {
      width: number;
      height: number;
    };
  };
  mockup: {
    images: { [id: string]: MockupImagesAttributesType };
    baseImageIndex: number;
    isBackgroundChanging: boolean;
    scaleState: SCALE_STATE;
    color: string;
    mockupLoaded: boolean;
    mockupReady: boolean;
    originalSize: {
      width: number;
      height: number;
    };
    premium: boolean;
  };
  modal: {
    open: boolean;
    type: ModalType;
  };
  events: EventTypes[];
  categories: {
    data: CategoryViewModel[];
    loading: boolean;
    error: boolean;
  };
  user: {
    loggedIn: boolean;
    token: string;
    name: string;
    avatar: string;
    planType: string;
    watermark: boolean;
    isSubscriptor: boolean;
    hasMockupsAccess?: boolean;
    hasRemainingDownloads?: boolean;
  };
  translations: { [key: string]: any };
};

export type CategoryData = {
  id: number;
  selectedTags: string[];
  mockups: MockupWithImagesViewModel[];
  loading: boolean;
  error: boolean;
  lastPage: number;
  noMoreMockups: boolean;
};

export const getDefaultState = () => {
  const defaultState: MockupState = {
    mobileState: {
      isEditing: false,
      canvasZoom: 1,
      canvasPan: { x: 0, y: 0 },
    },
    filter: Filters[0],
    filterIntensity: 100,
    uploadedImagesStock: [],
    highlightedImage: undefined,
    selectedImage: undefined,
    isUploading: false,
    userUploads: {},
    download: {
      startDownload: false,
      size: {
        height: undefined,
        width: undefined,
      },
    },
    mockup: {
      images: {},
      isBackgroundChanging: false,
      baseImageIndex: 0,
      scaleState: SCALE_STATE.start,
      color: undefined,
      mockupLoaded: false,
      mockupReady: false,
      originalSize: {
        height: undefined,
        width: undefined,
      },
      premium: false,
    },
    modal: {
      open: false,
      type: undefined,
    },
    events: [],
    user: {
      loggedIn: false,
      token: undefined,
      name: undefined,
      avatar: undefined,
      planType: undefined,
      watermark: true,
      isSubscriptor: false,
      hasMockupsAccess: false,
      hasRemainingDownloads: false,
    },
    categories: {
      data: null,
      loading: false,
      error: false,
    },
    translations: {},
  };
  return defaultState;
};

export const mockupState = atom({
  key: "mockupState",
  default: getDefaultState(),
});

export const categoriesCache = atomFamily<CategoryData, number>({
  key: "categoriesCache",
  default: (id) => {
    return {
      id,
      selectedTags: [],
      mockups: [],
      loading: false,
      error: false,
      lastPage: 0,
      noMoreMockups: false,
    };
  },
});
