
import React from "react"
import styled from "styled-components"

interface SpinnerVexelsProps {
    scale?: number,
}

const SpinnerVexelsWrapper = styled.div`
.vexels-loading-container {
    width: 80px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .wrapper {
    height: 200px;
    width: 200px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: scale(${(props: SpinnerVexelsProps) => props.scale ? `${props.scale}` : '0.4'});
  }
  .outer-square {
    height: 120px;
    width: 120px;
    border: 20px solid var(--color-blue);
    border-radius: 44px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
    animation-name: squareAnim;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.6, 0.01, 0.26, 1);
    animation-iteration-count: infinite;
    box-sizing: content-box;
  }
  .stick-wrapper {
    width: 20px;
    height: 80px;
    background-color: #2488ed;
    top: 0;
    left: -10px;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: top center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stick {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #2488ed;
  }
  .ball {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: #2488ed;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    animation-name: ballAnim;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  @keyframes squareAnim {
    0% {
      transform: translate(-50%, -50%) rotate(-245deg) scale(0);
    }
    20% {
      transform: translate(-50%, -50%) rotate(-240deg) scale(0);
    }
    50% {
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }
    62% {
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-645deg) scale(0);
    }
  }
  @keyframes ballAnim {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(1.5);
    }
    35% {
      transform: scale(1);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }


`

const SpinnerVexels: React.FC<SpinnerVexelsProps> = (props) => {
    return (
        <SpinnerVexelsWrapper {...props}>
            <div className="vexels-loading-container">
                <div className="wrapper">
                    <div className="outer-square">
                        <div className="stick-wrapper">
                            <div className="stick"></div>
                        </div>
                    </div>
                    <div className="ball"></div>
                    </div>
                </div>
        </SpinnerVexelsWrapper>
    )
}

export default SpinnerVexels
