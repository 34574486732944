import React from 'react'
import styled from "styled-components"

const ErrorMessageWrapper = styled.div`
    color: red;
    font-size: 13px;
`;

type Props = {
    message: string,
    className?: string
}

export default function ErrorMessage({ message, className }: Props) {
    return (
        <ErrorMessageWrapper className={className}>{message}</ErrorMessageWrapper>
    )
}
