import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { MockupWithImagesViewModel } from '../../../../api/clientInterfaces/MockupModel';
import RelatedMockup from './RelatedMockup';
import jimp from 'jimp';
import { useRecoilValue } from "recoil"
import { mockupState } from "../../../../Recoil/RecoilState"

interface Props {
    relatedMockupsList: MockupWithImagesViewModel[],
    uploadedImage?: string,
    selectedMockupId: number,
}

export enum RENDER_STATE {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    RENDERED = 'RENDERED',
}

export type MockupViewModelWithLoading = MockupWithImagesViewModel & {
    state: RENDER_STATE
}

const RelatedMockupsWrapper = styled.div`
    width: 100%;
`;

const RelatedMockups: React.FC<Props> = (props) => {
    const [uploadedImageLowRes, setuploadedImageLowRes] = useState(props.uploadedImage);
    const { user: { hasMockupsAccess } } = useRecoilValue(mockupState);

    const initList = () => {
        return props.relatedMockupsList.map((m) => {
            return {
                ...m,
                state: RENDER_STATE.IDLE,
            }
        })
    }

    const [mockupsRenderState, setMockupsRenderState] = useState<MockupViewModelWithLoading[]>(initList());

    useEffect(() => {
        if (props.relatedMockupsList) {
            setMockupsRenderState(initList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.relatedMockupsList])

    const setLowResImage = async () => {
        if(props.uploadedImage){
            const image = await jimp.read(props.uploadedImage);
            const sw = 200;
            image.resize(sw, jimp.AUTO);
            const imageBase64 = await image.getBase64Async("image/png");
            setuploadedImageLowRes(imageBase64);
        } else {
            setuploadedImageLowRes(props.uploadedImage);
        }
        setMockupsRenderState(() => {
            return mockupsRenderState.map((m, index) => {
                return {
                    ...m,
                    state: index === 0 ? RENDER_STATE.LOADING : RENDER_STATE.IDLE,
                }
            })
        })
    }

    useEffect(() => {
        setLowResImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.uploadedImage])

    const onMockupFinishedSetup = (mockupId: number) => {
        setMockupsRenderState(() => {
            return mockupsRenderState.map((m) => {
                return {
                    ...m,
                    state: m.id === mockupId ? RENDER_STATE.RENDERED : m.state,
                }
            })
        })
    }

    const renderList = () => {
        return mockupsRenderState.map((m, index) => {
            return <RelatedMockup
                isSelected={m.id === props.selectedMockupId}
                key={m.id}
                mockup={m}
                previousMockupState={mockupsRenderState[index - 1] ? mockupsRenderState[index - 1].state : undefined}
                uploadedImage={uploadedImageLowRes}
                onReady={onMockupFinishedSetup}
                hasMockupsAccess={hasMockupsAccess} />
        })
    }

    return (
        <RelatedMockupsWrapper>
            {renderList()}
        </RelatedMockupsWrapper>
    )
}

export default RelatedMockups;