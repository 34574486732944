import {
    MockupState,
    ModalType,
    mockupState,
} from "../../../Recoil/RecoilState";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import Button from "../buttons/Button";
import { ButtonColor } from "../buttons/Button";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import SpinnerVexels from "../spinner/SpinnerVexels";
import UserModal from "./UserModal";
import isMobile from "ismobilejs";
import logo from "../../../assets/logo.png";
import produce from "immer";
import { removeToken } from "../../../utils/AuthCookies";
import { screenSize } from "../../../stylesGlobal/Variables";
import { useRecoilState } from "recoil";

interface Props {
    isMobile: boolean;
    isEditing: boolean;
    isLoadingUser?: boolean;
}

const HeaderWrapper = styled.header<Props>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--zIndex-high);
    width: 100%;
    height: 5.4rem;
    background-color: var(--color-white);
    box-shadow: 0 3px 21px rgba(0, 0, 0, 0.1);
    display: flex;
    ${(props) =>
        props.isMobile
            ? css`
                  justify-content: space-between;
                  height: 59px;
              `
            : null}

    .overlay {
        position: absolute;
        width: 100%;
        height: 16vh;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, #000000b8, transparent);
        display: ${(props) => (props.isEditing ? "block" : "none")};
    }

    .header__leftCol {
        width: 25%;
        min-width: 280px;
        display: flex;
        align-items: center;

        img {
            margin-right: 1.5rem;
            padding-left: 1.2em;
            ${(props) =>
                props.isMobile
                    ? css`
                          max-width: 100%;
                          padding: 1rem 0 1rem 2rem;
                      `
                    : null}
        }

        h1 {
            font-size: 1.2rem;
            font-weight: 300;
            color: #9d9d9d;
            padding-right: 0.7rem;
            ${(props) =>
                props.isMobile
                    ? css`
                          display: none;
                      `
                    : null}
        }
    }
    .header__rightCol {
        width: 75%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        padding-right: 2rem;
        ${(props) =>
            props.isMobile
                ? css`
                      width: auto;
                      text-align: right;
                  `
                : null}

        .login-container {
            display: flex;
            gap: 3em;

            .login-item {
                text-decoration: none;
                font-size: 1.6rem;
                font-weight: 500;
                color: var(--color-blue);
                cursor: pointer;
                ${(props) =>
                    props.isMobile
                        ? css`
                              font-size: 13px;
                              color: #727272;
                              font-weight: 500;
                          `
                        : null}
            }
        }

        .header_userAvatar {
            display: block;
            width: 4rem;
            height: 4rem;
            overflow: hidden;
            border-radius: 100%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            background-color: var(--color-light-gray);
            border: 1px solid transparent;

            &:focus {
                outline: none;
                border: 1px solid var(--color-blue);
            }
        }

        .header__downloadSet {
            display: flex;
            align-items: center;
            flex-direction: row;
            ${(props) =>
                props.isMobile
                    ? css`
                          display: none;
                      `
                    : null}

            p {
                font-size: 1.2rem;
                max-width: 28rem;
                margin-left: 1rem;
                text-transform: uppercase;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                    "Helvetica Neue", sans-serif;
                opacity: 0;
                animation: text;
                animation-delay: 5s;
                animation-duration: 0.16s;
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;

                a {
                    color: var(--color-blue);
                    text-decoration: underline;
                }
            }

            @keyframes text {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }

    .button__container {
        display: flex;
        align-items: center;
        gap: 1em;

        .link {
            text-decoration: underline;
            color: var(--color-blue);
            font-size: 1.3rem;
            font-weight: 500;
            padding: 0.5em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    @media (min-width: ${screenSize.size_m}) {
        .header__leftCol {
            width: 20%;
            img {
                max-height: 30px;
            }
        }
        .header__rightCol {
            width: 80%;
        }
    }
    @media (min-width: ${screenSize.size_2xl}) {
        .header__leftCol {
            width: 18%;
        }
        .header__rightCol {
            width: 82%;
        }
    }
    @media (min-width: ${screenSize.size_3xl}) {
        .header__leftCol {
            width: 15%;
        }
        .header__rightCol {
            width: 85%;
        }
    }
`;

const Header: React.FC<Props> = (props) => {
    const [mockupGeneralState, setMockupGeneralState] =
        useRecoilState(mockupState);
    const [openUserModal, setOpenUserModal] = useState(false);

    const handleDownload = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                const isPremium = draftState.mockup.premium && !draftState.user.hasMockupsAccess;
                draftState.modal = {
                    open: true,
                    type: draftState.user.loggedIn
                        ? isPremium
                            ? ModalType.mockupsAccess
                            : draftState.user.hasRemainingDownloads
                                ? ModalType.download
                                : ModalType.noMoreDownloads
                        : ModalType.login,
                };
            })
        );
    };

    const handleLogout = () => {
        removeToken();
        setOpenUserModal(false);
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.user = {
                    loggedIn: false,
                    token: undefined,
                    name: null,
                    avatar: null,
                    planType: null,
                    watermark: true,
                    isSubscriptor: false,
                    hasMockupsAccess: false,
                    hasRemainingDownloads: false,
                };
            })
        );
        sessionStorage.removeItem("user");
    };

    const toggleUserModal = () => {
        setOpenUserModal((prevState) => !prevState);
    };
    const handleLogin = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.modal = {
                    open: true,
                    type: ModalType.login,
                };
            })
        );
    };
    const handleSignUp = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.modal = {
                    open: true,
                    type: ModalType.signUp,
                };
            })
        );
    };
    const renderUserInfo = (isLoadingUser?: boolean) => {
        if (isLoadingUser) {
            return <SpinnerVexels scale={0.2} />;
        } else if (mockupGeneralState.user.loggedIn) {
            return (
                <button className="header_userAvatar" onClick={toggleUserModal}>
                    {mockupGeneralState.user.avatar && (
                        <img
                            src={mockupGeneralState.user.avatar}
                            alt={`${mockupGeneralState.user.name} Avatar`}
                        />
                    )}
                </button>
            );
        } else {
            return (
                <div className="login-container">
                    <span className="login-item" onClick={handleSignUp}>
                        Sign up
                    </span>
                    <span onClick={handleLogin} className="login-item">
                        Log in
                    </span>
                </div>
            );
        }
    };

    return (
        <>
            <HeaderWrapper
                isMobile={isMobile(window.navigator).any}
                isEditing={props.isEditing}
            >
                {props.isMobile && <div className="overlay" />}
                <a
                    href={"https://www.vexels.com/"}
                    target={"blank"}
                    className="header__leftCol"
                >
                    <img src={logo} alt="Vexels logo" />
                </a>
                <div className="header__rightCol">
                    <span className="header__downloadSet">
                        {!props.isMobile && (
                            <div className="button__container">
                                <Button
                                    color={ButtonColor.blue}
                                    borderRadius={"19px"}
                                    padding={"9px 37px"}
                                    gap={"7.71px"}
                                    iconSVG={<Download />}
                                    label="download"
                                    onClick={handleDownload}
                                    large={true}
                                    disabled={
                                        !mockupGeneralState.mockup.mockupReady
                                    }
                                />
                                {mockupGeneralState.user.isSubscriptor && (
                                    <a
                                        className="link"
                                        href="https://vexels.nolt.io/trending?s=eyJmaWx0ZXJzIjpbeyJ0eXBlIjoiRklFTEQiLCJpcyI6IjZhNjRmMDA3LWY4NDktNGVjNi1iZjc4LTBkMzQxYWY5ZWIyYiJ9XX0%3D"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        FEEDBACK & IDEAS
                                    </a>
                                )}
                            </div>
                        )}
                        {mockupGeneralState.user.watermark && (
                            <p>
                                To remove watermarks, consider one of our merch
                                plans.{" "}
                                <a
                                    href={"http://vexels.com/plans/mg"}
                                    target={"blank"}
                                >
                                    See Plans
                                </a>
                            </p>
                        )}
                    </span>
                    {renderUserInfo(props.isLoadingUser)}
                </div>
            </HeaderWrapper>
            {openUserModal && (
                <UserModal
                    isOpen={openUserModal}
                    onClose={toggleUserModal}
                    onLogout={handleLogout}
                    user={mockupGeneralState.user}
                />
            )}
        </>
    );
};

export default Header;
