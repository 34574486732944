import React from 'react';
import { useRecoilState } from "recoil";
import { mockupState } from "../../../../Recoil/RecoilState";
import UploadImage from './UploadImage';
import isMobile from 'ismobilejs';
import styled, { css } from 'styled-components';

interface Props {
    showTooltip?: () => void
}

type StyleProps = {
    isMobile: boolean
}

const UploadImageListWrapper = styled.div<StyleProps>`
    ${(props) => props.isMobile ? css`
        white-space: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    `: null}
`

const UploadImageList: React.FC<Props> = (props) => {
    const [mockupGeneralState] = useRecoilState(mockupState);

    const renderUploadInputs = () => {
        return Object.keys(mockupGeneralState.userUploads).map((id, index) => {
            const upload = mockupGeneralState.userUploads[id];
            return <UploadImage
            showTooltip={props.showTooltip}
                isSelected={mockupGeneralState.selectedImage && mockupGeneralState.selectedImage.id === id}
                key={id}
                userUpload={upload}
                userUploads={mockupGeneralState.userUploads}
                imageStock={mockupGeneralState.uploadedImagesStock}
                index={index} />
        })
    }

    return (
        <UploadImageListWrapper isMobile={isMobile(window.navigator).any}>
            {renderUploadInputs()}
        </UploadImageListWrapper>
    )
}

export default UploadImageList;