import React from "react";
import styled from "styled-components";
import { MockupWithImagesViewModel } from "../../../api/clientInterfaces/MockupModel";
import SelectBackground from "../toolbar/SelectBackground/SelectBackground";

type Props = {
    loading: boolean,
    mockup: MockupWithImagesViewModel
}

const BackgroundControlWrapper = styled.div``;

const BackgroundControl = (props: Props) => {
    return (
        <BackgroundControlWrapper>
            <SelectBackground 
                selectedMockup={props.mockup}
                loading={props.loading}
            />
        </BackgroundControlWrapper>
    )
}

export default BackgroundControl;