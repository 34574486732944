import React from "react"
import styled from "styled-components"
import RotateImage from "./RotateImage"
import ScaleImage from "./ScaleImage"
import CenterImage from "./CenterImage"
import { useRecoilValue } from "recoil"
import { mockupState } from "../../../Recoil/RecoilState"
import Expand from "./Expand"
import SelectedImage from "./SelectedImage"

const ContextBarWrapper = styled.div`
    width:100%;
    height:5.5em;
    position:absolute;
    top:0;
    left:0;
    z-index:var(--zIndex-low);
    background-color:var(--color-black);
    display:flex;
    justify-content:center;
    align-items:center;
    user-select:none;
    transition:margin-left .2s;
    will-change:margin-left;

    .contextBar__loading{
        width:100%;
        height:100%;
        position:absolute;
        z-index:var(--zIndex-low);
        background:transparent;
        top:0;
        left:0;
        user-select:none;
        cursor:not-allowed;
    }
`

type Props = {
    mockupBarOpen: boolean,
    setMockupBarOpen: () => void
}
const ContextBar = (props: Props) => {
    const mockupGeneralState = useRecoilValue(mockupState);

    return (
        <ContextBarWrapper {...props}>
            {
                mockupGeneralState.selectedImage && mockupGeneralState.userUploads[mockupGeneralState.selectedImage.id].userImage.imageFile && <SelectedImage />
            }
            <CenterImage />
            <ScaleImage />
            <RotateImage />
            {(
                !mockupGeneralState.mockup.mockupReady
                || mockupGeneralState.isUploading
            )
                && <div className="contextBar__loading" />
            }
            <Expand isOpen={props.mockupBarOpen} onClick={() => props.setMockupBarOpen()} />

        </ContextBarWrapper>
    )
}

export default ContextBar