import React from "react";
import styled, { css } from "styled-components";
import isMobile from "ismobilejs";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { ReactComponent as SelectTShirt } from "../../../assets/icons/select-t-shirt.svg";
import { ReactComponent as ConnectedBall } from "../../../assets/icons/connected-ball.svg";
import { ReactComponent as IdeaBulb } from "../../../assets/icons/idea-bulb.svg";
import { ReactComponent as Headphones } from "../../../assets/icons/headphones.svg";
import premiumMockupsModal from "../../../assets/premium-mockups-modal.png";

interface Props {
  handleDownloadWithWatermark: () => void;
  isNoMoreDownloads?: boolean;
}

type StyledProps = {
  isMobile?: boolean;
};

const PremiumModalWrapper = styled.div<StyledProps>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .t-shirt-maker__premium-models-modal-mobile {
    display: none;
    background-color: var(--color-blue);
    color: white;
    border-radius: 18px;
    height: auto;
    overflow: hidden;
    width: 390px;
  }

  .t-shirt-maker__premium-models-modal-mobile-container {
    display: block;
    width: 100%;
    margin: 30px;
  }

  ${(props) =>
    props.isMobile
      ? css`
          .t-shirt-maker__premium-models-modal-content {
            display: none;
          }

          .t-shirt-maker__premium-models-modal-mobile {
            display: flex;
          }

          * {
            font-family: "Poppins";
            font-weight: 400;
            text-align: left;
          }

          h1 {
            font-size: 27px;
            line-height: 1.45;
          }

          ul {
            margin-top: 10px;

            li {
              font-size: 13px;
              line-height: 1.37;
              display: flex;
              margin-top: 20px;
              align-items: center;

              span {
                margin-right: 10px;
                vertical-align: middle;
                box-sizing: unset;

                svg {
                  width: 25px;
                  height: 25px;

                  path {
                    fill: white;
                  }
                }
              }
            }
          }

          .accent-btn {
            margin-top: 60px;
            padding-left: 38px;
            padding-right: 38px;
            width: auto;
          }
        `
      : null}

  .t-shirt-maker__premium-models-modal-content {
    background-color: var(--color-blue);
    color: white;
    border-radius: 18px;
    ${(props) =>
      !props.isMobile
        ? css`
            display: flex;
          `
        : null}
    height: auto;
    overflow: hidden;
    width: 860px;
    z-index: 200;
  }
  .t-shirt-maker__premium-models-right-container {
    width: 1200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .t-shirt-maker__premium-models-right-container img {
    height: 100%;
    object-fit: cover;
    position: relative;
    height: 600px;
  }

  .t-shirt-maker__premium-models-top-section img {
    margin-top: 10px;
    width: 80px;
  }

  .t-shirt-maker__premium-models-left-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px;
  }

  .t-shirt-maker__premium-models-top-section {
    text-align: left;
  }

  .t-shirt-maker__premium-models-top-section h1 {
    font-size: 24px;
    line-height: 1.16;
  }

  .t-shirt-maker__premium-models-top-section p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.37;
  }

  .t-shirt-maker__premium-models-bottom-section {
    text-align: left;
    padding-top: 20px;
    flex-grow: 1;
    font-size: 13px;
  }

  .t-shirt-maker__premium-models__list-container {
    display: inline-block;
    text-align: left;
  }

  .t-shirt-maker__premium-models__list-container p {
    font-size: 13px;
    line-height: 1.37;
  }

  .t-shirt-maker__premium-models__list-container ol {
    line-height: 2;
  }

  .t-shirt-maker__premium-models-bottom-section li {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }

  .t-shirt-maker__premium-models-bottom-section li span {
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
    width: 24px;
    padding: 5px;
    box-sizing: unset;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: white;
      }
    }
  }

  .t-shirt-maker__premium-models__start-creating {
    background-color: white;
    border-radius: 18px;
    color: var(--color-blue);
    display: inline-block;
    font-family: "Poppins";
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    padding: 8px 25px;
    text-transform: uppercase;
  }

  .t-shirt-maker__premium-models__download {
    color: white;
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
  }

  .t-shirt-maker__premium-models-crown-container {
    background-color: white;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;

    span {
      box-sizing: unset;
      height: 25px;
      width: 25px;
    }

    p {
      width: 80%;
      font-size: 11px;
      margin-left: 10px;
      color: #707070;
    }
  }
`;

const PremiumModal: React.FC<Props> = (props) => {
  return (
    <PremiumModalWrapper isMobile={isMobile(window.navigator).any}>
      <div className="t-shirt-maker__premium-models-modal-mobile">
        <div className="t-shirt-maker__premium-models-modal-mobile-container">
          <div className="t-shirt-maker__premium-models-crown-container">
            {props.isNoMoreDownloads ? (
              <>
                <span>
                  <Download />
                </span>
                <p>
                  You have reached the maximum downloads allowed in your plan.
                </p>
              </>
            ) : (
              <>
                <span>
                  <Crown />
                </span>
                <p>
                  This model isn't compatible with your current subscription,
                  contemplate an upgrade.
                </p>
              </>
            )}
          </div>
          <div className="t-shirt-maker__premium-models-top-section">
            <h1>
              <b>Unlock all the features!</b>
            </h1>
            <p>
              Gain full access to Vexels Mockup Generator and other Vexels
              tools.
            </p>
          </div>
          <div className="t-shirt-maker__premium-models-bottom-section">
            <p>
              <b>With Merch Plan you'll get:</b>
            </p>
            <ul>
              <li>
                <span>
                  <SelectTShirt />
                </span>
                <p>
                  Full Access to T-shirt Maker, Mockup Generator and Quote
                  Generator
                </p>
              </li>
              <li>
                <span>
                  <ConnectedBall />
                </span>
                <p>
                  Design Request Credits to ask for custom design Monthly Merch
                </p>
              </li>
              <li>
                <span>
                  <IdeaBulb />
                </span>
                <p>Digest with inspiration and tips for merchers</p>
              </li>
              <li>
                <span>
                  <Headphones />
                </span>
                <p>Premium Support</p>
              </li>
            </ul>
          </div>
          <a
            target="_blank"
            href="https://www.vexels.com/merch-plans/mg"
            className="t-shirt-maker__premium-models__start-creating"
          >
            Upgrade Plan
          </a>
          <p
            className="t-shirt-maker__premium-models__download"
            onClick={() => props.handleDownloadWithWatermark()}
          >
            <u>Download with watermark</u>
          </p>
        </div>
      </div>
      <div className="t-shirt-maker__premium-models-modal-content">
        <div className="t-shirt-maker__premium-models-left-container">
          <div className="t-shirt-maker__premium-models-crown-container">
            {props.isNoMoreDownloads ? (
              <>
                <span>
                  <Download />
                </span>
                <p>
                  You have reached the maximum downloads allowed in your plan.
                </p>
              </>
            ) : (
              <>
                <span>
                  <Crown />
                </span>
                <p>
                  This model isn't compatible with your current subscription,
                  contemplate an upgrade.
                </p>
              </>
            )}
          </div>
          <div className="t-shirt-maker__premium-models-top-section">
            <h1>
              <b>Unlock all the features!</b>
            </h1>
            <p>
              Gain full access to Vexels Mockup Generator and other Vexels
              tools.
            </p>
          </div>
          <div className="t-shirt-maker__premium-models-bottom-section">
            <p>
              <b>With Merch Plan you'll get:</b>
            </p>
            <div className="t-shirt-maker__premium-models__list-container">
              <ol>
                <li>
                  <span>
                    <SelectTShirt />
                  </span>
                  <p>
                    Full Access to T-shirt Maker, Mockup Generator and Quote
                    Generator
                  </p>
                </li>
                <li>
                  <span>
                    <ConnectedBall />
                  </span>
                  <p>
                    Design Request Credits to ask for custom design Monthly
                    Merch
                  </p>
                </li>
                <li>
                  <span>
                    <IdeaBulb />
                  </span>
                  <p>Digest with inspiration and tips for merchers</p>
                </li>
                <li>
                  <span>
                    <Headphones />
                  </span>
                  <p>Premium Support</p>
                </li>
              </ol>
            </div>

            <a
              target="_blank"
              href="https://www.vexels.com/merch-plans/mg"
              className="t-shirt-maker__premium-models__start-creating"
            >
              Upgrade Plan
            </a>
            <p
              className="t-shirt-maker__premium-models__download"
              onClick={() => props.handleDownloadWithWatermark()}
            >
              <u>Download with watermark</u>
            </p>
          </div>
        </div>
        <div className="t-shirt-maker__premium-models-right-container">
          <img src={premiumMockupsModal} alt="Premium Mockups Modal" />
        </div>
      </div>
    </PremiumModalWrapper>
  );
};

export default PremiumModal;
