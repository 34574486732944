import React from "react"
import styled, { keyframes } from "styled-components"

interface Props {
    color?: string,
}

const loadingAnimation = keyframes`
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
`

const SpinnerWrapper = styled.div`
        display:flex;
        justify-content:center;
        align-items:center;
        width: 100%;

        .spinner{
            position: relative;
            height: 20px;
            width: 20px;
            display: inline-block;
            animation: ${loadingAnimation} 5.4s infinite;

        &::after,&::before{
            content: "";
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            border-width: 2px;
            border-color: ${(props: Props) => props.color ? `${props.color} ${props.color} transparent transparent` : '#333 #333 transparent transparent'};
            border-style: solid;
            border-radius: 20px;
            box-sizing: border-box;
            top: 0;
            left: 0;
            animation: ${loadingAnimation} 0.7s ease-in-out infinite;
        }
        &::after{
            animation: ${loadingAnimation} 0.7s ease-in-out 0.1s infinite;
            background: transparent;
        }
    }

`

const Spinner: React.FC<Props> = (props) => {
    return (
        <SpinnerWrapper {...props}>
            <div className="spinner" />
        </SpinnerWrapper>
    )
}

export default Spinner