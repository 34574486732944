import { MockupState, mockupState } from "../../Recoil/RecoilState";
import React, { useEffect } from "react";

import { ApiClient } from "../../api/ApiClient";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import produce from "immer";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const GoogleWrapper = styled.div`
  .button {
    --background-color: #ececec;
    box-shadow: none !important;
    border-radius: 30px !important;
    padding-left: 18px !important;
    background-color: var(--background-color) !important;
    padding: 1em !important;
    div {
      background-color: var(--background-color) !important;
    }
    div,
    span {
      padding: 0 !important;
    }
  }
`;

type Props = {
  isLogin: boolean;
};

const GoogleButton = (props: Props) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [mockupGeneralState, setMockupGeneralState] =
    useRecoilState(mockupState);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  async function onSuccess(res) {
    const { tokenId: googleTokenId } = res;
    try {
      await ApiClient.loginGoogle(googleTokenId);
    } catch (error) {
      console.error("GoogleButton/onSuccess", error);
      return;
    }

    try {
      const { userName, userAvatar, userPlanType, watermark, isSubscriptor, hasMockupsAccess, hasRemainingDownloads } =
        await ApiClient.getUserInfo();
      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.user = {
            loggedIn: true,
            token: "",
            name: userName,
            avatar: userAvatar,
            planType: userPlanType,
            watermark,
            isSubscriptor,
            hasMockupsAccess,
            hasRemainingDownloads,
          };
        })
      );
    } catch (error) {
      console.error("GoogleButton/GetUserInfo", error);
      return;
    }

    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.modal = {
          open: false,
          type: undefined,
        };
      })
    );
  }
  const onFailure = (err) => {
    return console.error(err);
  };
  return (
    <GoogleWrapper>
      <GoogleLogin
        clientId={clientId}
        className="button py-input my-2"
        buttonText={
          props.isLogin ? "Log in with Google" : "Sign up with Google"
        }
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
      />
    </GoogleWrapper>
  );
};

export default GoogleButton;
