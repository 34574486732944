import React from 'react'
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { paths } from "../../../utils/Router/RoutePaths"
import { MockupViewModel } from '../../../api/clientInterfaces/MockupModel'
import styled, { css, keyframes } from "styled-components"
import isMobile from 'ismobilejs';
import { useRecoilValue } from "recoil"
import { mockupState } from "../../../Recoil/RecoilState"
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg"

const placeholderShimmer = keyframes`
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
`

type MockupsGridWrapperProps = {
    isMobile: boolean;
}

const MockupsGridWrapper = styled(motion.aside) <MockupsGridWrapperProps>`
.mockupsCategories__list{
    display: grid;
    gap: 14px;
    grid-template-columns: 1fr 1fr 1fr;
    margin:2rem auto 0 auto;
    justify-content: ${props => props.isMobile ? 'center' : 'initial'};

    @media (max-width: 820px){
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 414px){
        grid-template-columns: 1fr;
    }

    ${props => !props.isMobile && css`
        &:after {
            content: "";
            flex: auto;
        }
    `}
}

.mockupsCategories__item{
    cursor:pointer;
    transition:transform .2s;
    display: flex;
    align-items: start;
    ${props => !props.isMobile && css`
        min-height:137px;
    `} 
    position:relative;

    ${(props) => props.isMobile ? css`
    margin: 0 2% 2rem 2%;
    `: null}
    a{
        position:relative;
        background: #f6f7f8;
        background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 800px 164px;
        display: inline-block;
        width:100%;
        ${props => !props.isMobile && css`
            height:90%;
            min-height:120px;
        `}
        border-radius: 5px;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${placeholderShimmer};
        animation-timing-function: linear;
    }

    .top-image{
        position:absolute;
        top:0;
        left:0;
        opacity:1;
        transition:opacity .3s;
        z-index:1;

        &:hover{
            opacity:0;
        }
    }

    img{
        width:100%;
        height:100%;
        border-radius: 5px;
    }

    .image-container{
        height:100%;
    }

    &.selected{
        img{
            border:solid 2px var(--color-blue);
        }
        .placeholder{
            border:solid 2px var(--color-blue);
        }
    }

    &:hover{
        transform:translateY(-5px);
    }
    .crown-container{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 10px;
        margin-right: 10px;
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 7px;
        z-index: 2;
    }
    .crown {
        width: 20px;
        height: 20px;
        margin: 5px;
        display: block;
    }
}
`

type Props = {
    items: MockupViewModel[];
    selectedMockupId: number;
}

const MockupsGrid = (props: Props) => {
    const { user: { hasMockupsAccess } } = useRecoilValue(mockupState);

    return (
        <MockupsGridWrapper isMobile={isMobile(window.navigator).any}>
            <motion.ul className="mockupsCategories__list">
                {props.items.map((m) => {
                    const isPremium = m.premium && !hasMockupsAccess;
                    return (
                        <motion.li key={m.id} className={`mockupsCategories__item ${m.id === props.selectedMockupId ? "selected" : ""}`}>
                            <Link to={paths.mockup(m.title, m.id)} className="image-container">
                                {m.preview1 && <img src={m.preview1} alt={m.title} loading="lazy" className="top-image" />}
                                {m.preview2 && <img src={m.preview2} alt={m.title} loading="lazy" />}
                            </Link>
                            {isPremium && 
                                <div className="crown-container">
                                  <Crown className="crown"/>
                                </div>
                            }
                        </motion.li>
                    )
                })}
            </motion.ul>
        </MockupsGridWrapper>
    )
}

export default MockupsGrid