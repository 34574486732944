import React from "react";
import styled from "styled-components"

const SeparatorWrapper = styled.div`
.separator-container{
    display: flex;
    justify-content: center;
    align-items: center;
    .join-separator {
        width: 100%;
        border: none;
        margin-right: 1rem;
        margin-left: 1rem;
        border-top: 1px solid var(--color-light-gray);
    }
    .separator-text {
        font-size: 1.6em;
        color: var(--color-light-gray);
    }
}
`

const Separator = () => {
    return (
        <SeparatorWrapper>
            <div className="separator-container">
                <hr className="join-separator w-100 mx-3" />
                <span className="separator-text">or</span>
                <hr className="join-separator w-100 mx-3" />
            </div>
        </SeparatorWrapper>
    );
};

export default Separator;
