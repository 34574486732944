import useSWR, { mutate, keyInterface } from "swr";

type Props<T> = {
  key: keyInterface;
  fetcher: () => Promise<T>;
  revalidateOnFocus?: boolean;
};

export const useGetRequest = <T>(props: Props<T>) => {
  const { data, isValidating, error } = useSWR<T>(props.key, props.fetcher, {
    revalidateOnFocus: props.revalidateOnFocus
      ? props.revalidateOnFocus
      : false,
    shouldRetryOnError: false,
  });

  const handleRetry = () => {
    mutate(props.key);
  };

  return {
    error: error ? true : false,
    data,
    loading: isValidating,
    handleRetry,
  };
};
