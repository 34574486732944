import Cookies from "universal-cookie";

const getTokenCookiesProperties = () => {
  const domain = process.env.REACT_APP_COOKIES_DOMAIN;
  const path = "/";
  return { domain, path };
};

export const setToken = (accessToken, strategy, userId) => {
  const cookies = new Cookies();
  const properties = getTokenCookiesProperties();
  cookies.set(`vx.token.${strategy}`, accessToken, properties);
  cookies.set("vx.strategy", strategy, properties);
  cookies.set("vx.user_id", userId, properties);
};

export const removeToken = () => {
  const cookies = new Cookies();
  const properties = getTokenCookiesProperties();
  cookies.remove("vx.strategy", properties);
  cookies.remove("vx.token.google", properties);
  cookies.remove("vx.token.facebook", properties);
  cookies.remove("vx.token.local", properties);
  cookies.remove("vx.user_id", properties);
};

export const getToken = () => {
  try {
    const cookies = new Cookies();
    const strategy = cookies.get("vx.strategy");
    const token = cookies.get(`vx.token.${strategy}`);
    return token;
  } catch (error) {
    return null;
  }
};
