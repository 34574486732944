import React from "react"
import styled, { css } from 'styled-components';
import TabTitle from "../TabTitle"
import { MockupWithImagesViewModel } from "../../../../api/clientInterfaces/MockupModel"
import { useRecoilState } from "recoil"
import { mockupState, MockupState } from "../../../../Recoil/RecoilState"
import Spinner from "../../spinner/Spinner"
import produce from "immer"
import { sendEvent } from "../../../../utils/Events"
import isMobile from 'ismobilejs'

type SelectBackgroundWrapperProps = {
    isMobile?: boolean,
}

const SelectBackgroundWrapper = styled.section<SelectBackgroundWrapperProps>`

    .background__list{
        display:flex;
        justify-content: ${props => props.isMobile ? 'flex-start' : 'center'};
        flex-direction: ${props => props.isMobile ? 'row' : 'column'};
        padding:2rem 0;
        overflow-x: ${props => props.isMobile ? 'auto' : 'initial'};
        flex-wrap: nowrap;
        ${(props) => props.isMobile ? css`
           align-items: flex-end;
           padding-left: 15px;
            `: null}

        .background__image{
            margin-bottom:2rem;
            cursor: pointer;
            min-height:${props => props.isMobile ? 7 : 14}rem;
            flex: ${props => props.isMobile ? '0 0 auto' : 'none'} ;
            width: ${props => props.isMobile ? 50 : 100}%;
            ${(props) => props.isMobile ? css`
            margin-bottom: 0;
            margin-right: 15px;
            width: 40%;
            `: null}

            img{
                --color: #8f8f8f;
                background-image: linear-gradient(45deg, var(--color) 25%, transparent 25%), linear-gradient(-45deg, var(--color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color) 75%), linear-gradient(-45deg, transparent 75%, var(--color) 75%);
                background-size: 20px 20px;
                background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
                border-radius:.5rem;
                border:2px solid white;
            }

            &.active{
                ${(props) => props.isMobile ? css`
                transform: scale(1.03);
                transition: transform 0.16s;
                `: null}
                img{
                    border:2px solid var(--color-blue);
                }
            }
        }
    }

    .background__loading{
        margin-top:5rem;
        display:flex;
        align-items:center;
        justify-content:center;
    }

`

type Props = {
    selectedMockup: MockupWithImagesViewModel,
    loading: boolean,
}

const SelectBackground = (props: Props) => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)

    const handleChangeBackground = (index: number) => () => {
        sendEvent('MG - Background', 'backgroundSelected')
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.baseImageIndex = index
                // if (draftState.userImage.base64 !== "") {
                // draftState.events.push(EventTypes.backgroundChange)
                // }
            })
        )
    }

    const renderBackgrounds = () => {
        if (props.loading) {
            return (
                <div className="background__loading">
                    <Spinner />
                </div>
            )
        } else if (!props.loading && props.selectedMockup) {
            return (
                <ul className="background__list">
                    {props.selectedMockup.thumbnailsLayers.baseImages.map((b, i) => {
                        const isActive = mockupGeneralState.mockup.baseImageIndex === i
                        return (
                            <li
                                className={`background__image ${isActive ? "active" : ""}`}
                                key={`${props.selectedMockup.id}-background-${i}`}
                                onClick={handleChangeBackground(i)}
                            >
                                <img src={b} alt={`mockup background ${i}`} />
                            </li>
                        )
                    })}
                </ul>

            )
        } else {
            return null
        }

    }

    return (
        <SelectBackgroundWrapper isMobile={isMobile(window.navigator).any}>
            <TabTitle
                title="Background"
            />
            {renderBackgrounds()}
        </SelectBackgroundWrapper>
    )
}

export default SelectBackground