import React, { useRef } from "react"
import styled from "styled-components"
import { motion, PanInfo } from "framer-motion"
import { useRecoilState } from "recoil"
import { mockupState, MockupState } from "../../../Recoil/RecoilState"
import { getRotateDegrees } from "./Helpers"
import produce from "immer"
import { sendEvent } from "../../../utils/Events"

const RotateImageWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:var(--color-white);

    .rotateImage__label{
        font-size:1.3rem;
        margin-right:1rem;
        font-weight:500;
    }

    .rotateImage__rotatorContainer{
        width:3rem;
        height:3rem;
        position: relative;
        margin-right:.5rem;
        margin-top: -.7rem;

        .rotateImage__innerCircle{
            width:2.6rem;
            height:2.6rem;
            border:3px solid #707070;
            position:absolute;
            top:.5rem;
            left:.5rem;
            background-color: transparent;
            border-radius:100%;
        }

        .rotateImage__marker{
            width:1rem;
            height:1rem;
            background-color:var(--color-white);
            position:absolute;
            user-select:none;
            cursor: pointer;
            left:calc(50% - 0.2rem);
            border-radius:100%;
            transform-origin:5px 18px;
        }
    }

    .rotateImage__degreesContainer{
        display:flex;
        position:relative;

        .rotateImage__degreesSymbol{
            margin-left:0.5rem;
            font-size:1.4rem;
            color:#636365;
        }

        .rotateImage__border{
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:.1rem;
            background-color:#636365;
        }
    }

    .rotateImage__degrees{
        font-size:1.3rem;
        min-width:0;
        max-width:2.2rem;
        -moz-appearance: textfield;
        background:none;
        border:none;
        color:#636365;
        text-align: right;

        &:focus{
            outline:none;
            &~.rotateImage__border{
                background-color:var(--color-white);
            }
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`

const RotateImage = () => {
    const rotatorContainerRef = useRef<HTMLDivElement>(null)
    const rotatorInputRef = useRef<HTMLInputElement>(null)
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)

    const handlePan = (
        _event: MouseEvent | TouchEvent | PointerEvent,
        info: PanInfo
    ) => {
        const offsetLeft = rotatorContainerRef.current.getBoundingClientRect().left
        const offsetTop = rotatorContainerRef.current.getBoundingClientRect().top
        const width = rotatorContainerRef.current.getBoundingClientRect().width
        const height = rotatorContainerRef.current.getBoundingClientRect().height

        const degrees = getRotateDegrees({
            offsetLeft,
            offsetTop,
            width,
            height,
            mouseX: info.point.x,
            mouseY: info.point.y
        })

        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.images[draftState.selectedImage.id].rotationDegrees = degrees
            })
        )
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const degrees = parseInt(event.currentTarget.value, 10)

        if (degrees > 360) {
            event.preventDefault()
        } else {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.mockup.images[draftState.selectedImage.id].rotationDegrees = degrees
                })
            )
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const degrees = parseInt(event.currentTarget.value, 10)
        if (isNaN(degrees)) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.mockup.images[draftState.selectedImage.id].rotationDegrees = 0
                    // if (draftState.userImage.base64 !== "") {
                    //     draftState.events.push(EventTypes.rotateImage)
                    // }
                })
            )
        } else {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    // if (draftState.userImage.base64 !== "") {
                    //     draftState.events.push(EventTypes.rotateImage)
                    // }
                })
            )
        }
    }

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.keyCode === 69 || event.keyCode === 187 || event.keyCode === 189) {
            event.preventDefault()
        }
    }

    const handlePanEnd = () => {
        rotatorInputRef.current.focus();
        sendEvent('MG - Toolbar', 'rotate');
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                // if (draftState.userImage.base64 !== "") {
                //     draftState.events.push(EventTypes.rotateImage)
                // }
            })
        )
    }

    const selectedImage = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id];

    return (
        <RotateImageWrapper>
            <p className="rotateImage__label">Rotate</p>
            <div
                className="rotateImage__rotatorContainer"
                ref={rotatorContainerRef}
            >
                <span
                    className="rotateImage__innerCircle"
                />
                <motion.span
                    className="rotateImage__marker"
                    onPan={handlePan}
                    onPanEnd={handlePanEnd}
                    style={{
                        transform: `rotate(${selectedImage.rotationDegrees}deg)`
                    }}
                />
            </div>
            <div className="rotateImage__degreesContainer">
                <input
                    type="number"
                    className="rotateImage__degrees"
                    value={selectedImage.rotationDegrees.toString()}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    min={0}
                    max={360}
                    ref={rotatorInputRef}
                />
                <span className="rotateImage__border" />
                <span className="rotateImage__degreesSymbol">°</span>
            </div>

        </RotateImageWrapper>
    )
}

export default RotateImage