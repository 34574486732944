import { CRTFilter } from "@pixi/filter-crt";
import { BLEND_MODES, Container, Sprite, Texture } from "pixi.js";
import { FILTER } from "../../Recoil/RecoilState";

export enum ENUM_FILTER {
    NONE = 'NONE',
    FILTER_1997 = 'FILTER_1997',
    FILTER_AMARO = 'FILTER_AMARO',
    FILTER_ASHBY = 'FILTER_ASHBY',
    FILTER_BROOKLYN = 'FILTER_BROOKLYN',
    FILTER_GINHAM = 'FILTER_GINHAM',
    FILTER_GINZA = 'FILTER_GINZA',
    FILTER_INKWELL = 'FILTER_INKWELL',
    FILTER_LOFI = 'FILTER_LOFI',
    FILTER_MAVEN = 'FILTER_MAVEN',
    FILTER_NASHVILLE = 'FILTER_NASHVILLE',
    FILTER_RISE = 'FILTER_RISE',
    FILTER_SKYLINE = 'FILTER_SKYLINE',
    FILTER_VALENCIA = 'FILTER_VALENCIA',
    FILTER_WALDEN = 'FILTER_WALDEN',
    FILTER_XPRO = 'FILTER_XPRO',
    FILTER_VEXELS_ONE = 'FILTER_VEXELS_ONE',
    FILTER_VEXELS_TWO = 'FILTER_VEXELS_TWO'
}

export const Filters: FILTER[] = [
    {
        name: 'No Filter',
        filterType: ENUM_FILTER.NONE
    },
    {
        name: '1997',
        filterType: ENUM_FILTER.FILTER_1997
    },
    {
        name: 'Amaro',
        filterType: ENUM_FILTER.FILTER_AMARO
    },
    {
        name: 'Ashby',
        filterType: ENUM_FILTER.FILTER_ASHBY
    },
    {
        name: 'Brooklyn',
        filterType: ENUM_FILTER.FILTER_BROOKLYN
    },
    {
        name: 'Ginham',
        filterType: ENUM_FILTER.FILTER_GINHAM
    },
    {
        name: 'Ginza',
        filterType: ENUM_FILTER.FILTER_GINZA
    },
    {
        name: 'Inkwell',
        filterType: ENUM_FILTER.FILTER_INKWELL
    },
    {
        name: 'Lo-fi',
        filterType: ENUM_FILTER.FILTER_LOFI
    },
    {
        name: 'Maven',
        filterType: ENUM_FILTER.FILTER_MAVEN
    },
    {
        name: 'Nashville',
        filterType: ENUM_FILTER.FILTER_NASHVILLE
    },
    {
        name: 'Rise',
        filterType: ENUM_FILTER.FILTER_RISE
    },
    {
        name: 'Skyline',
        filterType: ENUM_FILTER.FILTER_SKYLINE
    },
    {
        name: 'Valencia',
        filterType: ENUM_FILTER.FILTER_VALENCIA
    },
    {
        name: 'Walden',
        filterType: ENUM_FILTER.FILTER_WALDEN
    },
    {
        name: 'X Pro',
        filterType: ENUM_FILTER.FILTER_XPRO
    },
    {
        name: 'Vexels One',
        filterType: ENUM_FILTER.FILTER_VEXELS_ONE
    },
    {
        name: 'Vexels Two',
        filterType: ENUM_FILTER.FILTER_VEXELS_TWO
    }
]

//PIXI.filters.ColorMatrixFilter
export const applyFilter = (stage: Container, filter: FILTER, filterIg: any, filterCtr: CRTFilter, filterOverlayLayer: (layer: Sprite) => void, filterOverlayOpacity: (layer: number) => void) => {
    const sp = stage.getChildByName("overlay");
    if (sp) {
        sp.destroy();
    }
    filterIg.reset();
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', stage.width.toString());
    canvas.setAttribute('height', stage.height.toString());
    document.body.appendChild(canvas);
    let sprite;
    let gradient;
    filterCtr.enabled = false;
    filterIg.enabled = false;
    switch (filter.filterType) {
        case ENUM_FILTER.FILTER_1997:
            filterIg.enabled = true;
            filterIg.hue(330, false);
            filterIg.sepia(true);
            break;
        case ENUM_FILTER.FILTER_AMARO:
            filterIg.enabled = true;
            document.body.appendChild(canvas)
            const ctx_amaro = canvas.getContext('2d');
            ctx_amaro.fillStyle = '#c59e005c';
            ctx_amaro.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.MULTIPLY;
            filterOverlayOpacity(1);
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.5, false);
            filterIg.brightness(0.85, true);
            break;
        case ENUM_FILTER.FILTER_ASHBY:
            filterIg.enabled = true;
            const ctx_ash = canvas.getContext('2d');
            ctx_ash.fillStyle = '#c59e005c';
            ctx_ash.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.MULTIPLY;
            filterOverlayOpacity(1);
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.2, true);
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.3;
            filterCtr.vignettingBlur = 0.6;
            break;
        case ENUM_FILTER.FILTER_BROOKLYN:
            filterIg.enabled = true;
            const ctx_broo = canvas.getContext('2d');
            ctx_broo.fillStyle = '#7fbbe35e';
            ctx_broo.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.MULTIPLY;
            filterOverlayOpacity(1);
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.hue(5, false);
            filterIg.saturate(-0.1, true);
            filterIg.contrast(1.6, true);
            filterIg.brightness(0.4, true);
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.2;
            filterCtr.vignettingBlur = 0.6;
            break;
        case ENUM_FILTER.FILTER_GINHAM:
            filterIg.enabled = true;
            const ctx_ginham = canvas.getContext('2d');
            ctx_ginham.fillStyle = '#e6e6e6';
            ctx_ginham.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.MULTIPLY;
            filterOverlayOpacity(1);
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.5, true);
            filterIg.brightness(0.8, true);
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.2;
            filterCtr.vignettingBlur = 0.6;
            break;
        case ENUM_FILTER.FILTER_GINZA:
            filterIg.enabled = true;
            const ctx_ginza = canvas.getContext('2d');
            ctx_ginza.fillStyle = '#7d6918';
            ctx_ginza.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.MULTIPLY;
            sprite.alpha = 0.5;
            filterOverlayOpacity(0.5);
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.hue(360, true);
            filterIg.saturate(0.13, true);
            filterIg.contrast(1.5, true);
            filterIg.brightness(0.6, true);
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.2;
            filterCtr.vignettingBlur = 0.6;
            break;
        case ENUM_FILTER.FILTER_INKWELL:
            filterIg.enabled = true;
            filterIg.greyscale(0.3, false);
            break;
        case ENUM_FILTER.FILTER_LOFI:
            filterIg.enabled = true;
            filterIg.contrast(0.4, true);
            filterIg.brightness(0.8, true);
            break;
        case ENUM_FILTER.FILTER_MAVEN:
            filterIg.enabled = true;
            filterIg.greyscale(0.3, false);
            filterIg.contrast(0.7, true);
            filterIg.brightness(0.6, true);
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.2;
            filterCtr.vignettingBlur = 0.6;
            break;
        case ENUM_FILTER.FILTER_NASHVILLE:
            filterIg.enabled = true;
            const ctx_nash = canvas.getContext('2d');
            gradient = ctx_nash.createLinearGradient(0, 0, 0, stage.height);
            gradient.addColorStop(0, "#804e0f80");
            gradient.addColorStop(1, "#804e0fa6");
            ctx_nash.fillStyle = gradient;
            ctx_nash.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.4);
            sprite.alpha = 0.4;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            break;
        case ENUM_FILTER.FILTER_RISE:
            filterIg.enabled = true;
            const ctx_rise = canvas.getContext('2d');
            gradient = ctx_rise.createLinearGradient(0, 0, 0, stage.height);
            gradient.addColorStop(0, "#00000000");
            gradient.addColorStop(1, "#e6c13d40");
            ctx_rise.fillStyle = gradient;
            ctx_rise.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.7);
            sprite.alpha = 0.7;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.6, true);
            filterIg.brightness(0.6, true);
            filterIg.saturate(0.4, true);
            break;
        case ENUM_FILTER.FILTER_SKYLINE:
            filterIg.enabled = true;
            filterIg.technicolor(true);
            break;
        case ENUM_FILTER.FILTER_VALENCIA:
            filterIg.enabled = true;
            const ctx_valen = canvas.getContext('2d');
            ctx_valen.fillStyle = '#e6c13d1a';
            ctx_valen.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.6);
            sprite.alpha = 0.6;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.12, true);
            filterIg.brightness(1, true);
            break;
        case ENUM_FILTER.FILTER_WALDEN:
            filterIg.enabled = true;
            const ctx_walden = canvas.getContext('2d');
            ctx_walden.fillStyle = '#e5f08080';
            ctx_walden.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.3);
            sprite.alpha = 0.3;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            filterIg.contrast(0.1, true);
            filterIg.brightness(0.9, true);
            filterIg.saturate(0.1, true);
            break;
        case ENUM_FILTER.FILTER_XPRO:
            filterCtr.enabled = true;
            filterCtr.lineWidth = 0;
            filterCtr.vignetting = 0.2;
            filterCtr.vignettingAlpha = 0.5;
            filterCtr.vignettingBlur = 0.5;
            break;
        case ENUM_FILTER.FILTER_VEXELS_ONE:
            filterIg.enabled = true;
            const ctx_vexelsone = canvas.getContext('2d');
            gradient = ctx_vexelsone.createLinearGradient(0, 0, 0, stage.height);
            gradient.addColorStop(0, "#ffc722");
            gradient.addColorStop(1, "#ff0000");
            ctx_vexelsone.fillStyle = gradient;
            ctx_vexelsone.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.3);
            sprite.alpha = 0.3;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            break;
        case ENUM_FILTER.FILTER_VEXELS_TWO:
            filterIg.enabled = true;
            const ctx_vexelstwo = canvas.getContext('2d');
            gradient = ctx_vexelstwo.createRadialGradient(stage.width / 2, stage.height / 2, 0, stage.width / 2, stage.height / 2, stage.height);
            gradient.addColorStop(0, "#4c36ff");
            gradient.addColorStop(1, "#ffffff");
            ctx_vexelstwo.fillStyle = gradient;
            ctx_vexelstwo.fillRect(0, 0, stage.width, stage.height);
            sprite = new Sprite(Texture.from(canvas));
            sprite.name = "overlay";
            sprite.blendMode = BLEND_MODES.ADD;
            filterOverlayOpacity(0.3);
            sprite.alpha = 0.3;
            stage.addChild(sprite);
            filterOverlayLayer(sprite);
            break;
    }
    canvas.remove();
}