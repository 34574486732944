export const getRotateDegrees = (
    props: {
        offsetLeft: number,
        offsetTop: number,
        width: number,
        height: number,
        mouseX: number,
        mouseY: number,
    }
) => {
    //Ejemplo: https://codepen.io/graphilla/pen/MybXwy

    const R2D = 180 / Math.PI

    const centerX = props.offsetLeft + (props.width / 2)
    const centerY = props.offsetTop + (props.height / 2)

    const x = props.mouseX - centerX
    const y = props.mouseY - centerY

    const radians = Math.atan2(x, y)
    const theta = radians * R2D
    let degrees = Math.round((theta * -1) + 180)

    //sticky
    if (degrees > 355 || degrees < 5) {
        degrees = 0
    } else if (degrees > 85 && degrees < 95) {
        degrees = 90
    }
    else if (degrees > 175 && degrees < 185) {
        degrees = 180
    }
    else if (degrees > 265 && degrees < 275) {
        degrees = 270
    }

    return degrees
}