import { Interacting } from "../../utils/InteractingService";
import isMobile from 'ismobilejs';
import { Point } from "pixi.js";

export const addInteraction = (
    obj: any,
    registerDragEvent?: () => void,
    canvasContainer?: HTMLDivElement,
    id?: string,
    onSelect?: (id: string) => void,
    registerStartEvent?: () => void,
    getIsEditing?: () => boolean,
    getTouchVelocity?: () => number,
    getSelectedId?: () => string,
) => {
    const isMobilePhone = isMobile(window.navigator).any;

    if(isMobilePhone){
        obj.interactive = true;
        obj.interactable = false;
        document.body.addEventListener('touchstart', (e) => {
            if(getIsEditing && !getIsEditing()){
                let t = e.touches;
                if (t.length > 1) {
                    obj.interactable = false
                } else {
                    obj.interactable = true;
                }
            }
        })
    } else {
        obj.interactive = true;
    }

    obj
        .on('pointerover', () => onHover(canvasContainer, "grab"))
        .on('mouseout', () => onHover(canvasContainer, "default"))
        .on('pointerdown', (e) => {
            if(isMobilePhone){
                const t = e.data.originalEvent.targetTouches;
                if (!t || t.length > 1 || !obj.interactable || !getIsEditing()) {
                    return
                }
            }
            onDragStart(e, id, registerStartEvent); onHover(canvasContainer, "grabbing");
        })
        .on('pointerup', (e) => {
            if(isMobilePhone){
                if (getTouchVelocity && Math.abs(getTouchVelocity()) < 0.1 && obj.interactable) {
                    onDragEnd(e, id, registerDragEvent); onHover(canvasContainer, "grab")
                    if(getSelectedId() === ""){
                        onClick(id, onSelect);
                    }
                }  else {
                    obj.interactable = false;
                }
            } else {
                onDragEnd(e, id, registerDragEvent); onHover(canvasContainer, "grab")
                onClick(id, onSelect);
            }
        })
        .on('pointerupoutside', onDragEnd)
        .on('pointermove', (e) => {
            if (isMobilePhone && obj.interactable && getIsEditing() && getSelectedId() === id) {
                onDragMove(e, id)
            }
            if(!isMobilePhone){
                onDragMove(e, id)
            }
        })
}

export const addDeselectInteraction = (obj: any, onSelect: (id: string) => void) => {
    obj.interactive = true;
    obj.on('click', (e) => {
        if (onSelect) {
            onSelect(undefined);
        }
    })
}


const onClick = (id: string, onSelect: (id: string) => void) => {
    if (id) {
        onSelect(id);
    }
}

const onHover = (canvasContainer: HTMLDivElement, cursor: string) => {
    if (canvasContainer) {
        canvasContainer.style.cursor = cursor
    }
}

const onDragStart = (event: any, id: string, registerStartEvent?: () => void,) => {
    Interacting.start();
    const obj = event.currentTarget;
    obj.dragData = event.data;
    obj.dragging = 1;
    obj.dragPointerStart = event.data.getLocalPosition(obj.parent);
    obj.dragObjStart = new Point();
    obj.dragObjStart.copyFrom(obj.position);
    obj.dragGlobalStart = new Point();
    obj.dragGlobalStart.copyFrom(event.data.global);
    if (registerStartEvent) {
        registerStartEvent()
    }
}

const onDragEnd = (event: any, id: string, registerDragEvent?: () => void,) => {
    Interacting.stop();
    if (registerDragEvent) {
        registerDragEvent()
    }
    const obj = event.currentTarget;
    obj.dragging = 0;
    obj.dragData = null;
}

const onDragMove = (event: any, id: string) => {
    if ((event.data.originalEvent.targetTouches && event.data.originalEvent.targetTouches.length > 1)) {
        return;
    }
    const obj = event.currentTarget;
    if (!obj.dragging) return;
    const data = obj.dragData; // it can be different pointer!
    if (obj.dragging === 1) {
        // click or drag?
        if (Math.abs(data.global.x - obj.dragGlobalStart.x)
            + Math.abs(data.global.y - obj.dragGlobalStart.y) >= 3) {
            // DRAG
            obj.dragging = 2;
        }
    }
    if (obj.dragging === 2) {
        const dragPointerEnd = data.getLocalPosition(obj.parent);
        // DRAG
        obj.position.set(
            obj.dragObjStart.x + (dragPointerEnd.x - obj.dragPointerStart.x),
            obj.dragObjStart.y + (dragPointerEnd.y - obj.dragPointerStart.y),
        );
    }
}