import {
  MockupWithImagesViewModel,
  MockupBindingModel,
  MockupViewModel,
  MockupSearchModel,
  ApiResponse,
} from "./clientInterfaces/MockupModel";
import {
  CategoryViewModel,
  CategoryBindingModel,
} from "./clientInterfaces/CategoryModel";
import { EnvHelper } from "../utils/EnvHelper";
import { UserViewModel } from "./clientInterfaces/UserModel";
import {
  AuthenticationResponse,
  AuthenticationModel,
  AuthenticationModelGoogle,
} from "./clientInterfaces/AuthenticationModel";

const apiConfig = {
  baseURL: "https://makersapi.vexels.com/v1",
  getUserInfoURL: process.env.REACT_APP_VEXELS_API_URL + "user/me/",
  vexelsApiURL: process.env.REACT_APP_VEXELS_API_URL,
  vexelsV2ApiURL: process.env.REACT_APP_VEXELS_V2_API_URL,
  commonTranslationsURL:
    process.env.REACT_APP_TRANSLATIONS_URL + "en/common.json",
};

const addStagingVariable = (url: string, lastVar: boolean) => {
  if (EnvHelper.requestTestMockups) {
    return url.concat(
      lastVar ? "&env=M9kx5wZTNquE3HjGE" : "?env=M9kx5wZTNquE3HjGE"
    );
  } else {
    return url;
  }
};

export const apiUrl = {
  getMockup: (mockupId: number) =>
    addStagingVariable(
      `${apiConfig.baseURL}/mockups/mockup/${mockupId}`,
      false
    ),
  getRelatedMockups: (mockupId: number) =>
    addStagingVariable(
      `${apiConfig.baseURL}/mockups/mockup/${mockupId}/related`,
      false
    ),
  getCategories: addStagingVariable(
    `${apiConfig.baseURL}/mockups/category`,
    false
  ),
  getCategoryMockups: (
    categoryId: number,
    tags: string,
    limit: number,
    page: number
  ) =>
    addStagingVariable(
      `${apiConfig.baseURL}/mockups/category/${categoryId}/mockup?${
        limit ? "limit=" + limit : ""
      }&${page ? "page=" + page : ""}${tags ? "&tags=" + tags : ""}`,
      true
    ),
  getUserInfo: apiConfig.getUserInfoURL,
  getCommonTranslations: apiConfig.commonTranslationsURL,
  getDefaultMockups: addStagingVariable(
    `${apiConfig.baseURL}/mockups/mockup?shown_in_home=1`,
    true
  ),
  getPermissions: apiConfig.vexelsV2ApiURL + "permission/product/mockup-generator",
  registerDownload: apiConfig.baseURL + "/mockups/mockup/download/",
  login: apiConfig.vexelsApiURL + "auth/login/",
  loginGoogle: apiConfig.vexelsApiURL + "auth/login/google/",
  loginFacebook: apiConfig.vexelsApiURL + "auth/login/facebook/",
  signUp: apiConfig.vexelsApiURL + "auth/register/",
  getMockupSearch: (mockup: string, page: number) =>
    addStagingVariable(
      `${apiConfig.baseURL}/mockups/mockup/?search=${mockup}&page=${page}`,
      true
    ),
};

export interface ApiFunctions {
  getMockup(query: MockupBindingModel): Promise<MockupWithImagesViewModel>;
  getRelatedMockups(
    query: MockupBindingModel
  ): Promise<MockupWithImagesViewModel[]>;
  getCategories(): Promise<CategoryViewModel[]>;
  getCategoryMockups(
    query: CategoryBindingModel
  ): Promise<MockupViewModel[]>;
  getUserInfo(): Promise<UserViewModel>;
  getDefaultMockups(): Promise<MockupViewModel[]>;
  login(query: AuthenticationModel): Promise<AuthenticationResponse>;
  loginGoogle(token: AuthenticationModelGoogle);
  getMockupSearch(query: MockupSearchModel): Promise<ApiResponse>;
}
