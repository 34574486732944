import React, { useEffect } from "react"
import { mockupState, MockupState, ModalType } from "../../../Recoil/RecoilState"
import DownloadModal from "./DownloadModal"
import ErrorModal from "./ErrorModal"
import PremiumModal from "./PremiumModal";
import LoginModal from "../../login/LoginModal"
import ModalWrapper from './ModalWrapper'
import { useRecoilState } from "recoil";
import produce from "immer";

type Props = {
    onClose: () => void;
    isOpen: boolean;
    mockupMiniature: string;
    type: ModalType;
    disableClickOverlay?: boolean;
};

const Modal = (props: Props) => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState);

    const handleOpenDownloadModal = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.modal = {
                    open: true,
                    type: ModalType.download,
                };
            })
        );
    }
    useEffect(() => {
        const handleKeyUp = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                props.onClose();
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    });

    const renderModalContent = () => {
        switch (props.type) {
            case ModalType.download:
                return (
                    <DownloadModal mockupMiniature={props.mockupMiniature} />
                );
            case ModalType.error:
                return <ErrorModal />;
            case ModalType.login:
                return <LoginModal onClose={props.onClose} isLogin={true} />;
            case ModalType.signUp:
                return <LoginModal onClose={props.onClose} isLogin={false} />;
            case ModalType.mockupsAccess:
                return <PremiumModal handleDownloadWithWatermark={handleOpenDownloadModal} />;
            case ModalType.noMoreDownloads:
                return <PremiumModal handleDownloadWithWatermark={handleOpenDownloadModal} isNoMoreDownloads />;
            default:
                throw new Error(`Modal type dont exists ${props.type}`);
        }
    };

    return (
        props.isOpen && (
            <ModalWrapper
                onClose={props.onClose}
                isOpen={props.isOpen}
                type={props.type}
            >
                {renderModalContent()}
            </ModalWrapper>
        )
    );
};

export default Modal;
