import React, { useState, useRef } from "react"
import styled, { css } from 'styled-components';
import Spinner from "../../spinner/Spinner"
import { ReactComponent as Close } from "../../../../assets/icons/close.svg"
import { ReactComponent as Upload } from "../../../../assets/icons/upload-arrow.svg"
import { screenSize } from "../../../../stylesGlobal/Variables"
import { UserUploadType, UserImageType } from "../../../../Recoil/RecoilState"
import isMobile from 'ismobilejs';

type ImagePreviewWrapperProps = {
    image?: string,
    isSelected?: boolean,
    isMobile?: boolean
}

const ImagePreviewWrapper = styled.div<ImagePreviewWrapperProps>`
    --text-color: #545454;
    width:100%;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border: 2px solid var(--color-blue);
    border-radius: 27px;
    background-color:#F1F1F1;
    padding-right:5px;
    overflow: hidden;
    ${(props) => props.isMobile ? css`
        width: 90%;
        height: 60px;
        margin: 0 auto;
        margin-bottom: 1.5em;
        border: 2px solid var(--color-blue);
    `: null}
    

    .upload_button {
        cursor: pointer;
        font-size: 1.4rem;
        background: transparent;
        width: 100%;
        border: none;
        border-bottom: solid 1px #E5E5E5;
        padding: 15px;
        transition: background 0.16s ease-in-out;
        border-top-left-radius: .6rem;
        border-top-right-radius: .6rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        :hover {
            background: #F1F1F1
        }
    }

    .select_picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        border-radius: 0;
        overflow: hidden;

        :focus {
            border: none;
            outline: none;
        }

        :last-child {
            border-bottom: none;
            border-bottom-left-radius: .6rem;
            border-bottom-right-radius: .6rem;
        }

        img {
            width: 26%;
            height: 48px;
            object-fit: cover;
            display: block;
            background: #eaeaea;
        }

        p {
            font-size: 1.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap:break-word;
            flex: 1;
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .imagePreview__info{
        height:100%;
        display:flex;
        align-items:center;
        flex: 1 1;
        cursor: pointer;
        overflow: hidden;
    }

    .imagePreview__image{
        aspect-ratio: 1/1;
        height: 100%;
        background:url("${props => props.image}");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
    }

    .imagePreview__name{
        padding-left:1rem;
        font-size:1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap:break-word;
    }


    .imagePreview_uploadIcon {
        svg {
            width: 10px;
            height: 10px;
        }
    }

    .imagePreview__dropdown {
            top: 100%;
            position: absolute;
            background: white;
            border-radius:.6rem;
            border: 1px solid #E5E5E5;
            display: block;
            z-index: 999;
            box-shadow: 0px 3px 39px #00000038;
            width: 100%;

            input{
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                overflow: hidden;
                padding: 0;
                position: absolute !important;
                white-space: nowrap;
                width: 1px;
            }
        }

    .imagePreview__button{
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color);
        border:none;
        border-radius: 3px;
        width:36px;
        height:36px;
        cursor:pointer;
        position:relative;
        transition: background 0.16s ease-in-out;
        ${(props) => props.isMobile ? css`
        width: 28px;
        height: 28px;
        background: var(--color-blue);
        border-radius: 40px;
        `: null}

        :hover {
            background: #dedddd;
        }

        &:focus{
            outline:none;
        }

        svg{
            min-width:16px;
            min-height:16px;
            cursor:pointer;
            path {
                ${(props) => props.isMobile ? css`
                stroke: white;
                `: null}
                fill: var(--text-color);
            }
        }
    }

    .upload_icon svg {
        width: 16px;
        height: 16px;
        left: -12px;
        position: relative;
    }

    @media (min-width: ${screenSize.size_3xl}){
        .imagePreview__name{
            padding-right:1rem;
        }
    }
`
const RelativeContainer = styled.div`
    position: relative;
`;

type Props = {
    image: string,
    file: File,
    loading: boolean,
    onDelete: () => void,
    userUploads: { [id: string]: UserUploadType },
    isSelected: boolean,
    onChange: (image: File) => void,
    onSelectedImage: (image: UserImageType) => void,
    imageStock: UserImageType[],
    selectImage: () => void
}

const ImagePreview = (props: Props) => {
    const [dropdown, setdropdown] = useState(false);
    const [isFocus, setisFocus] = useState(false);
    const fileInput = useRef<HTMLInputElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            props.onChange(event.target.files[0])
            setisFocus(false)
            setdropdown(false)
        }
    }

    const renderDropdown = () => {
        return (
            <div className="imagePreview__dropdown">
                <input
                    ref={fileInput}
                    onFocus={() => setisFocus(true)}
                    type="file"
                    id={"imagePreviewUpload"}
                    onChange={handleChange}
                    accept="image/x-png,image/jpeg,application/.jpeg,.jpg,.png"
                />
                <label htmlFor={"imagePreviewUpload"} className="upload_button upload_icon"><Upload /> <p>Upload File</p></label>
                {
                    props.imageStock.map((image) => {
                        if (image.base64) {
                            return (
                                <button className="upload_button select_picture" onClick={() => { setdropdown(false); props.onSelectedImage(image) }}>
                                    <img src={image.base64} alt={image.imageFile.name} />
                                    <p>{image.imageFile.name}</p>
                                </button>
                            )
                        }
                        return null
                    })
                }
            </div>
        )
    }

    const handleSelect = () => {
        props.selectImage()
    }

    const renderImagePreview = () => {
        if (props.loading) {
            return (
                <ImagePreviewWrapper>
                    <Spinner />
                </ImagePreviewWrapper>
            )
        } else if (props.file) {
            return (
                <RelativeContainer>
                    <ImagePreviewWrapper image={props.image} isSelected={props.isSelected} onMouseLeave={() => { !isFocus && setdropdown(false) }} isMobile={isMobile(window.navigator).any}>
                        <div className="imagePreview__info" onClick={() => handleSelect()}>
                            <div className="imagePreview__image" />
                            <p className="imagePreview__name">
                                {props.file.name}
                            </p>
                        </div>
                        {!isMobile(window.navigator).any &&
                            <button className="imagePreview__button imagePreview_uploadIcon" onClick={() => setdropdown(true)}>
                                <Upload />
                            </button>
                        }

                        <button
                            className="imagePreview__button"
                            onClick={props.onDelete}
                        >
                            <Close />
                        </button>
                        {dropdown && renderDropdown()}
                    </ImagePreviewWrapper>
                </RelativeContainer>

            )
        } else {
            return null
        }
    }

    return (
        renderImagePreview()
    )
}

export default ImagePreview