import React, { useState } from 'react';
import styled from 'styled-components';
import touch_icon_one from '../../../assets/icons/touch_icon_one.svg';
import touch_icon_two from '../../../assets/icons/touch_icon_two.svg';
import touch_icon_three from '../../../assets/icons/touch_icon_three.svg';

type StyleProps = {
    show: boolean
}

const TouchInstructionsWrapper = styled.div<StyleProps>`
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0%;
    display: ${(props) => props.show ? 'flex' : 'none'};
    z-index: 100;
    background: #000000d1;

    .touchInstructions-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8vh;

        h1 {
            font-weight: normal;
            color: white;
            font-size: 19px;
        }

        div {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        button {
            border: none;
            outline: none;
            background-color: #3F80F1;
            color: white;
            border-radius: 33px;
            padding: 13px;
            min-width: 150px;
            font-size: 16px;
        }
    }
`;

const TouchInstructions = () => {
    const [show, setShow] = useState(true);
    return (
        <TouchInstructionsWrapper show={show}>
            <div className="touchInstructions-content">
                <h1>Use gesture controls</h1>
                <div>
                    <img src={touch_icon_one} alt="Move icon" />
                    <img src={touch_icon_two} alt="Rotate icon" />
                    <img src={touch_icon_three} alt="Resize icon" />
                </div>
                <button onClick={() => setShow(false)}>Got it</button>
            </div>
        </TouchInstructionsWrapper>
    )
}

export default TouchInstructions;