import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { UserUploadType, UserImageType } from "../../../Recoil/RecoilState"
import { ReactComponent as Upload } from "../../../assets/icons/upload-arrow.svg"
import isMobile from 'ismobilejs';

type InputFileButtonWrapperProps = {
    disabled: boolean;
    isMobile: boolean;
}


const InputFileButtonWrapper = styled.div<InputFileButtonWrapperProps>`
${(props) => props.isMobile ? css`
        display: flex;
        justify-content: center; 
    `: null}
    
    input{
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
    }

    .upload_button {
        cursor: pointer;
        font-size: 1.4rem;
        background: transparent;
        width: 100%;
        border: none;
        border-bottom: solid 1px #E5E5E5;
        padding: 15px;
        transition: background 0.16s ease-in-out;
        border-radius: 0;
        border-top-left-radius: .6rem;
        border-top-right-radius: .6rem;
        display: block;
        text-align: center;
        color: black;
        display: flex;

        :hover {
            background: #F1F1F1
        }
    }

    .select_picture {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        border-radius: 0;
        overflow: hidden;

        :focus {
            border: none;
            outline: none;
        }

        :last-child {
            border-bottom: none;
            border-bottom-left-radius: .6rem;
            border-bottom-right-radius: .6rem;
        }

        img {
            width: 26%;
            height: 48px;
            object-fit: cover;
            display: block;
            background: #eaeaea;
        }

        p {
            font-size: 1.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap:break-word;
            flex: 1;
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    label{
        border-radius: 2em;
        padding: 0.9rem 2rem;
        font-size:1.4rem;
        display:flex;
        align-items:center;
        justify-content:center;
        position: relative;
        ${(props) => props.isMobile ? css`
        border-radius:25px;
        margin-bottom: 2.5em;
        padding:.9rem 6rem;
        `: null}

        .inputFileButton__dropdown {
            top: 0;
            position: absolute;
            width: 200px;
            background: white;
            border-radius:.6rem;
            border: 1px solid #E5E5E5;
            display: block;
            z-index: 999;
            box-shadow: 0px 3px 39px #00000038;
        }
        

        ${props => props.disabled
        ? css`
            cursor:not-allowed;
            background-color:var(--color-button-disabled-background);
            color:var(--color-button-disabled-text);
        `
        : css`
            background:var(--color-blue);
            color:var(--color-white);
            transition:background-color .3s;
            cursor:pointer;

            &:hover{
                background-color:var(--color-button-blue-hover);
            }
        `}
    }

    .upload_icon svg {
        width: 15px;
        height: 15px;
        left: -12px;
        position: relative;
    }
`

type Props = {
    onChange: (image: File) => void,
    disabled?: boolean,
    id: string,
    userUploads: { [id: string]: UserUploadType },
    imageStock: UserImageType[],
    onSelectedImage: (image: UserImageType) => void,
    children: JSX.Element | string
}

const InputFileButton = (props: Props) => {
    const [dropdown, setdropdown] = useState(false);
    const [isFocus, setisFocus] = useState(false);
    const fileInput = useRef<HTMLInputElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
            props.onChange(event.target.files[0])
        }
    }

    const renderDropdown = () => {
        return (
            <div className="inputFileButton__dropdown" onMouseLeave={() => { !isFocus && setdropdown(false) }}>
                <input
                    ref={fileInput}
                    onFocus={() => setisFocus(true)}
                    type="file"
                    id={"inputFileButtonUpload"}
                    onChange={handleChange}
                    accept="image/x-png,image/jpeg,application/.jpeg,.jpg,.png"
                />
                <label htmlFor={"inputFileButtonUpload"} className="upload_button upload_icon"><Upload /> <p>Upload File</p></label>
                {
                    props.imageStock.map((image) => {
                        if (image.base64) {
                            return (
                                <button className="upload_button select_picture" onClick={() => props.onSelectedImage(image)}>
                                    <img src={image.base64} alt={image.imageFile.name} />
                                    <p>{image.imageFile.name}</p>
                                </button>
                            )
                        }
                        return null
                    })
                }
            </div>
        )
    }

    const handleDisplayDropdown = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
        e.preventDefault();
        setdropdown(true);
    }

    return (
        <InputFileButtonWrapper disabled={props.disabled} isMobile={isMobile(window.navigator).any}>
            <input
                type="file"
                id={props.id}
                onChange={handleChange}
                accept="image/x-png,image/jpeg,application/.jpeg,.jpg,.png"
            />
            <label htmlFor={props.id} onClick={(e) => props.imageStock.length > 0 && !dropdown && handleDisplayDropdown(e)}>
                {props.children}
                {dropdown && renderDropdown()}
            </label>
        </InputFileButtonWrapper>
    )
}

export default InputFileButton