import produce from 'immer';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { MockupWithImagesViewModel } from '../../../api/clientInterfaces/MockupModel';
import { mockupState, MockupState } from '../../../Recoil/RecoilState';
import BackgroundControl from './BackgroundControl';
import ColorControll from './ColorControll';
import FilterControl from './FilterControl';
import MockupsControl from './MockupsControl';
import TabBar from './TabBar';
import TouchControlls from './TouchControlls';
import UploadImageControl from './uploadImageControl';
import DownloadButton from './DownloadButton';

type StyleProps = {
    activeControll: controll;
}

export enum controll {
    NONE = "NONE",
    UPLOAD = "UPLOAD",
    COLOR = "COLOR",
    FILTER = "FILTER",
    BACKGROUND = "BACKGROUND",
    MOCKUPS = "MOCKUPS"
}

const MobileControllsWrapper = styled.nav<StyleProps>`
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    pointer-events: none;

    .controll-container {
        background-color: white;
        pointer-events: all;
        height: ${props => props.activeControll === controll.MOCKUPS ? "calc(100% - 14vh)" : "auto"};
        margin-top: 0;
    }

    .overlay {
        flex: 1;
        background-color: ${props => props.activeControll === controll.NONE ? "transparent" : "#00000085"};
        pointer-events: ${props => props.activeControll === controll.NONE ? "none" : "all"};
        display: ${props => props.activeControll === controll.MOCKUPS ? "none" : "block"};
        position: relative;
    }

    .overlay-out {
        background-color: transparent;
    }
`
type Props = {
    loading: boolean,
    mockup: MockupWithImagesViewModel,
    selectedMockupId: number
}

const MobileControlls = (props: Props) => {
    const [activeControll, setActiveControll] = useState(controll.NONE);
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState);

    const handleZoomOut = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                if (draftState.selectedImage) {
                    draftState.mobileState.isEditing = false;
                }
            })
        )
    }

    const getActiveControll = () => {
        switch(activeControll){
            case controll.COLOR:
                return <ColorControll />
            case controll.UPLOAD:
                return <UploadImageControl />
            case controll.FILTER:
                return <FilterControl loading={props.loading} mockup={props.mockup}/>
            case controll.BACKGROUND:
                return <BackgroundControl loading={props.loading} mockup={props.mockup} />
            case controll.MOCKUPS:
                return <MockupsControl selectedMockupId={props.selectedMockupId} closeEvent={() => setActiveControll(controll.NONE)}/>
        }
    }

    return (
        <MobileControllsWrapper activeControll={activeControll}>
            <div className={`overlay ${activeControll === controll.COLOR || activeControll === controll.FILTER ? ' overlay-out' : ''}`} onClick={() => setActiveControll(controll.NONE)}>
                <DownloadButton />
            </div>
            {mockupGeneralState.mobileState.isEditing && <TouchControlls />}
            <div className="controll-container">
                {getActiveControll()}
            </div>
            <TabBar
                onTabChange={(activeTab: controll) => {setActiveControll(activeTab); handleZoomOut()}}
                selectedMockup={props.mockup}
            />
        </MobileControllsWrapper>
    )
}

export default MobileControlls;