import React from "react";
import styled, { css } from "styled-components"
import isMobile from 'ismobilejs';

import { ReactComponent as Remove } from "../../../../assets/icons/remove.svg";

type TagsContainerProps = {
  isMobile?: boolean
}
type TagItem = {
  isMobile?: boolean
}

const TagsContainer = styled.form<TagsContainerProps>`
  width: 100%;
  display: flex;
  flex-wrap: ${props => props.isMobile ? 'nowrap' : 'wrap'};
  overflow-x: auto;

  ${(props) => props.isMobile ? css`
    padding-left: 3rem;
  `: null} 
`;

const TagItem = styled.div<TagItem>`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 8px 0 0;
  ${(props) => props.isMobile ? css`
   margin-bottom: 2rem;
    `: null} 

  label {
    color: #7d7d7d;
    border: 1px solid #7d7d7d;
    border-radius: 20px;
    font-weight: 500;
    position: relative;
    padding: 5px 15px 5px 15px;
    transition: ease-in 0.2s;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
    
    ${(props) => props.isMobile ? css`
    border-radius: 15px;
    border: 1px solid #D8D8D8;
    color:#888888;
    padding: 5px 25px 5px 25px;
    font-weight: 400;
    white-space: nowrap;
    `: null} 

    svg {
      position: absolute;
      height: 8px;
      top: 10px;
    right: 6px;
      transform: scale(0);
      transition: ease-in 0.2s;
    }
  }

  input {
    display: none;

    &:checked + label {
      color: #5e91ed;
      border-color: #5e91ed;
      padding: 5px 20px 5px 10px;

      svg {
        transform: scale(1)
      }
    }
  }
`;

type Props = {
  selectedTags: string[],
  tags: string[],
  onChangeTag: (e: React.FormEvent<HTMLInputElement>) => void;
};

const CategoryTags = (props: Props) => {

  if (props.tags.length > 0) {
    return (
      <TagsContainer isMobile={isMobile(window.navigator).any}>
        {props.tags.map((tag) => {
          return (
            <TagItem key={tag} isMobile={isMobile(window.navigator).any}>
              <input
                onChange={props.onChangeTag}
                type="checkbox"
                id={tag}
                name={tag}
                value={tag}
                checked={props.selectedTags.includes(tag)}
              />
              <label htmlFor={tag}>
                {tag}
                <Remove />
              </label>
            </TagItem>
          );
        })}
      </TagsContainer>
    );
  } else {
    return null;
  }
};

export default CategoryTags;
