import React from "react";
import styled from "styled-components";
import TabIcon from "../toolbar/Tabs/TabIcon";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Fill } from "../../../assets/icons/fill.svg";
import { ReactComponent as Background } from "../../../assets/icons/background.svg";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import { ReactComponent as Categories } from "../../../assets/icons/categories.svg";
import { controll } from "./MobileControlls";
import { MockupWithImagesViewModel } from "../../../api/clientInterfaces/MockupModel";

interface Props {
    onTabChange: (activeTab: controll) => void;
    selectedMockup: MockupWithImagesViewModel;
}

const TabBarWrapper = styled.ul`
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 60px;
    border-top: solid 1px #0000006b;
    justify-self: flex-end;
    width: 100%;
    background-color: white;
    pointer-events: all;
`

const TabBar: React.FC<Props> = (props) => {
    return (
        <TabBarWrapper>
            <TabIcon
                active={true}
                onClick={() => { props.onTabChange(controll.UPLOAD) }}
                disabled={false}
            >
                <Upload />
            </TabIcon>
            <TabIcon
                active={false}
                onClick={() => { props.onTabChange(controll.COLOR) }}
                disabled={false}
            >
                <Fill />
            </TabIcon>
            <TabIcon
                active={false}
                onClick={() => { props.onTabChange(controll.BACKGROUND) }}
                onClickDisabled={() => alert("This mockup has no additional backgrounds")}
                disabled={props.selectedMockup?.thumbnailsLayers.baseImages.length === 1}
            >
                <Background />
            </TabIcon>
            <TabIcon
                active={false}
                onClick={() => { props.onTabChange(controll.FILTER) }}
                disabled={false}
            >
                <Filter />
            </TabIcon>
            <TabIcon
                active={false}
                onClick={() => { props.onTabChange(controll.MOCKUPS) }}
                disabled={false}
            >
                <Categories />
            </TabIcon>
        </TabBarWrapper>
    )
}

export default TabBar;