import { getToken } from "../utils/AuthCookies";

export const track = async (eventName: string) => {
    const token = getToken();
    await fetch('https://api.vexels.com/v3/user/me/track', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key: eventName })
    });
}