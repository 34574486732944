import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { ModalType } from "../../../Recoil/RecoilState"
import { motion, AnimatePresence } from "framer-motion"
import { ReactComponent as Close } from "../../../assets/icons/close.svg"
import { screenSize } from "../../../stylesGlobal/Variables"
import isMobile from 'ismobilejs';

type ModalWrapperProps = {
    isMobile: boolean
    isDownload: boolean
    isPremium: boolean
}

const ModalWrapper = styled(motion.div) <ModalWrapperProps>`
    position:fixed;
    z-index:var(--zIndex-high);
    width:100%;
    height:100%;
    background:rgba(0,0,0,.50);
    display:flex;
    align-items:center;
    justify-content:center;
    transform:translate3d(0px,0px,0px);

    .modal__container{
        width:${props => props.isDownload ? "80%" : "auto"};
        padding:${props => props.isDownload ? "0 0 7% 0" : props.isPremium ? "0" : "3%"};
        background-color:${props => props.isPremium ? "initial" : "#F6F6F6"};
        position:relative;
        border-radius: 15px;
    }

    .modal__closeButtonContainer{
        position:absolute;
        top: 0rem;
        right: -4rem;
        ${(props) => props.isMobile ? css`
        top: -2rem;
        right: -2rem;
        `: null}

        .modal__closeButton{
            border:none;        
            background-color:var(--color-gray);
            border-radius:100%;
            width:3.2rem;
            height:3.2rem;
            cursor:pointer;
            position:relative;
            border:2px solid transparent;
            transition:background-color .2s;
    
            &:focus{
                outline:none;
                border:2px solid var(--color-blue);
            }
    
            &:hover{
                background-color:var(--color-dark-gray);
            }
            svg{
                position:absolute;
                top:calc(50% - 1.6rem);
                left:calc(50% - 1.5rem);
                cursor:pointer;
                width: 2.4rem;
                height: 2.4rem;
                top: 0.2rem;
                left: 0.15rem;

                path{
                    fill: white;
                }

                &:hover{
                    .modal__closeButton{
                        background-color:var(--color-dark-gray);
                    }
                }
            }
        }
 
    }



    .modal__header{
        display:flex;
        box-shadow:0 3px 21px rgba(0,0,0,.1);

        .modal__mockupmini{
            width:10%;
            img{
                max-width:100%;
                vertical-align: middle;
            }
            ${(props) => props.isMobile ? css`
            width: 60%;
            `: null}
        }

        .modal__title{
            display:flex;
            align-items:center;
            padding:0 3rem;
            color:#545454;
            ${(props) => props.isMobile ? css`
            flex-direction: column;
            align-items: start;
            padding: 0 1rem;
            `: null}

            h2{
                font-size:2.4rem;
                font-weight:500;
                margin-right:2rem;
                ${(props) => props.isMobile ? css`
                font-size: 18px;
                margin-right: 0;
                color:var(--color-blue);
            `: null}
            }

            h3{
                font-size:1.8rem;
                font-weight:400;
                ${(props) => props.isMobile ? css`
                font-size:1.3rem;
            `: null}
            }
        }

    }

    @media (min-width: ${screenSize.size_m}){
        .modal__header{
            .modal__title{
                h2{
                    font-size:3rem;
                }
                h3{
                    font-size:2.3rem;
                }
            }
        }
    }

    @media (max-width: 320px) {
        .modal__header{
            .modal__mockupmini{
                width: 65%;
            }
            .modal__title{
                h2{
                    font-size: 14px;
                }
            }
        }
     }
`

type Props = {
    onClose: () => void,
    isOpen: boolean,
    type: ModalType,
    children: React.ReactNode,
}

const Wrapper = (props: Props) => {
    const modalContainerRef = useRef<HTMLElement>()

    useEffect(() => {
        const handleKeyUp = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                props.onClose()
            }
        }
        document.addEventListener("keyup", handleKeyUp)
        return () => {
            document.removeEventListener("keyup", handleKeyUp)
        }
    })

    return (
        <AnimatePresence>
            {
                props.isOpen && (
                    <ModalWrapper isMobile={isMobile(window.navigator).any}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: 0.2,
                        }}
                        isDownload={props.type === ModalType.download}
                        isPremium={[
                            ModalType.mockupsAccess,
                            ModalType.noMoreDownloads,
                        ].includes(props.type)}
                        onClick={(event: {
                            target
                        }) => {
                            if (event.target.classList.contains("overlay")) {
                                props.onClose()
                            }
                        }}
                        className='overlay'
                    >
                        <motion.section
                            initial={{ opacity: 0, y: -20, }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            className="modal__container"
                            ref={modalContainerRef}
                        >
                            {props.children}
                            <div className="modal__closeButtonContainer">
                                <button
                                    type="button"
                                    className="modal__closeButton"
                                    onClick={props.onClose}>
                                    <Close />
                                </button>
                            </div>
                        </motion.section>
                    </ModalWrapper>
                )
            }
        </AnimatePresence>
    )
}

export default Wrapper