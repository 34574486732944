import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import iro from '@jaames/iro'
import { useRecoilState } from "recoil"
import { mockupState, MockupState } from "../../../../Recoil/RecoilState"
import produce from "immer"
import { sendEvent } from "../../../../utils/Events"
import isMobile from 'ismobilejs'
import PickerCircle from "./HandlePickers/PickerCircle";
import PickerRect from "./HandlePickers/PickerRect";

const isMobilePhone = () => {
    return isMobile(window.navigator).any
}

const ColorSelectorWrapper = styled.section`
    width:100%;
    display: grid;
    gap: 2rem;

    .color-content {
        width: ${isMobilePhone() ? `${window.innerWidth - 90}px` : '100%'};
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        gap: 5px;
    }

    .color-sample {
        width: 36px;
        height: 36px;
        background-color: #7D7D7D;
        box-shadow: 0 0 18px #0000001a;
        border-radius: 50%;
    }
    
    .colorPicker__input{
        flex: 1;
        border: 2px solid #E9E9E9;
        border-radius: 18px;
        width: 100%;
        padding: 0.8rem 0;
        text-align:center;
        color:#9F9F9F;
        font-size:1.2rem;
        display: block;

        &:focus{
            outline:none;
            border:1px solid var(--color-blue);
        }
    }
`

const ColorPicker = () => {
    const colorPickerContainerRef = useRef<HTMLDivElement>()
    const colorPickerRef = useRef<iro.ColorPicker>()
    const inputRef = useRef<HTMLInputElement>();
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState);
    const [idHandlePickerCircle] = useState<string>('handle-color-picker-rect')
    const [idHandlePickerRect] = useState<string>('handle-color-picker-circle')

    useEffect(() => {
        const hex = getHexValueForColorPicker(mockupGeneralState.mockup.color)
        inputRef.current.value = hex ? hex : ""
        colorPickerRef.current = iro.ColorPicker(colorPickerContainerRef.current, {
            width: getOffsetWidth(colorPickerContainerRef),
            boxHeight: 140,
            color: `#fff`,
            padding: 2,
            margin: 20,
            sliderSize: 10,
            layout: [
                {
                    component: iro.ui.Box,
                    options: {
                        handleSvg: `#${idHandlePickerCircle}`,
                    },
                },
                {
                    component: iro.ui.Slider,
                    options: {
                        sliderType: "hue",
                        sliderShape: "bar",
                        handleSvg: `#${idHandlePickerRect}`,
                    }
                }
            ]
        });
        colorPickerRef.current.on('color:change', (color) => {
            const hexColor = color.hexString as string;
            const pixiHex = getHexValueForPixi(hexColor)
            inputRef.current.value = color.hexString;

            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.mockup.color = pixiHex
                })
            )
        });
        // Queremos que se ejecute el efecto una unica vez porque si no se crea una instancia
        // del color picker cada vez que hay un cambio 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (colorPickerRef) {
            const color = getHexValueForColorPicker(mockupGeneralState.mockup.color)
            if (color) {
                colorPickerRef.current.color.hexString = color
            }
        }
    }, [mockupGeneralState.mockup.color])

    const getOffsetWidth = (ref: { current: HTMLElement }) => {
        try {
            return ref.current.offsetWidth;
        } catch {
            console.error('ColorPicker/getOffsetWidth');
            return 222
        }
    }

    const isHexValid = (value: string) => {
        const regEx = new RegExp(/^#([A-Fa-f0-9]{6})$/)
        return regEx.test(value)
    }

    const getHexValueForPixi = (hex: string) => {
        const noHash = hex.split("#")[1];
        return `0x${noHash}`
    }

    const getHexValueForColorPicker = (pixiHex: string) => {
        if (pixiHex) {
            const hex = pixiHex.split("x")[1]
            return `#${hex}`
        }
        return undefined
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const hex = event.target.value
        if (colorPickerRef && isHexValid(hex)) {
            setMockupGeneralState(
                produce((draftState: MockupState) => {
                    draftState.mockup.color = getHexValueForPixi(hex)
                })
            )
        }
    }

    const handleMouseUp = () => {
        // setMockupGeneralState(
        //     produce((draftState: MockupState) => {
        //         if (draftState.userImage.base64 !== "") {
        //             draftState.events.push(EventTypes.colorChange)
        //         }
        //     })
        // )
        sendEvent('MG - Color', 'colorChange')
    }

    const getHex = () => {
        const hex = getHexValueForColorPicker(mockupGeneralState.mockup.color)
        return hex ? hex : ""
    }

    const handleInputBlur = () => {
        if (inputRef && !isHexValid(inputRef.current.value)) {
            const hex = getHexValueForColorPicker(mockupGeneralState.mockup.color)
            inputRef.current.value = hex ? hex : ""
        }
        sendEvent('MG - Color', 'hexColorChange')
        // setMockupGeneralState(
        //     produce((draftState: MockupState) => {
        //         if (draftState.userImage.base64 !== "") {
        //             draftState.events.push(EventTypes.colorChange)
        //         }
        //     })
        // )
    }

    return (
        <ColorSelectorWrapper>
            <div className="color-content">
                <div className="color-sample" style={{ background: getHex() }} />
                <input
                    type="text"
                    className="colorPicker__input"
                    ref={inputRef}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                />
            </div>
            <div
                ref={colorPickerContainerRef}
                className="colorPicker"
                onMouseUp={handleMouseUp}
            />
            <PickerCircle id={idHandlePickerCircle} />
            <PickerRect id={idHandlePickerRect} />
        </ColorSelectorWrapper >
    )
}

export default ColorPicker