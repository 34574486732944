import produce from 'immer';
import React from 'react';
import styled from 'styled-components';
import { mockupState, MockupState, ModalType } from '../../../Recoil/RecoilState';
import { ReactComponent as Download } from "../../../assets/icons/download.svg"
import { useSetRecoilState } from 'recoil';

const DownloadButtonWrapper = styled.div`
    width: 50px;
    height: 50px;
    background-color: var(--color-green);
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    pointer-events: all;

    svg{
        fill:var(--color-white);
        width:1.7rem;
    }
`;

const DownloadButton = () => {
    const setMockupGeneralState = useSetRecoilState(mockupState);
    const handleDownload = () => {
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.modal = {
                    open: true,
                    type: !draftState.user.loggedIn
                    ? ModalType.login
                    : draftState.mockup.premium
                    ? ModalType.mockupsAccess
                    : ModalType.download,
                }
            })
        )
    }
    return (
        <DownloadButtonWrapper onClick={handleDownload}>
            <Download />
        </DownloadButtonWrapper>
    )
}

export default DownloadButton;