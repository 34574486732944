import React from "react";
import styled from "styled-components";
import { MockupWithImagesViewModel } from "../../../api/clientInterfaces/MockupModel";
import FiltersList from "../toolbar/FiltersList/FiltersList";

type Props = {
    loading: boolean,
    mockup: MockupWithImagesViewModel
}

const FilterControlWrapper = styled.div``;

const FilterControl = (props: Props) => {
    return (
        <FilterControlWrapper>
            <FiltersList 
                selectedMockup={props.mockup}
                loading={props.loading}
            />
        </FilterControlWrapper>
    )
}

export default FilterControl;