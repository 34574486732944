import React from 'react';
import styled from 'styled-components';
import warning_icon from '../../../assets/icons/waring_icon.svg';

interface Props {
    label: string
}

const ErrorBoxWrapper = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: #ff0000a6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 30px;
    }

    p {
        margin-top: 10px;
        color: white;
    }
`;

const ErrorBox: React.FC<Props> = (props) => {
    return (
        <ErrorBoxWrapper>
            <img src={warning_icon} alt="Warning icon" />
            <p>{props.label}</p>
        </ErrorBoxWrapper>
    )
}

export default ErrorBox;