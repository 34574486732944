import React from "react"
import styled from "styled-components"
import Spinner from "../spinner/Spinner"
import { useRecoilValue } from "recoil"
import { mockupState } from "../../../Recoil/RecoilState"
import Button, { ButtonColor } from "../buttons/Button"
import RelatedMockups from './relatedMockups/RelatedMockups'
import EmptyState from "../../emptyState/EmptyState"
import { ApiClient } from "../../../api/ApiClient"
import ErrorMessage from "../../error/ErrorMessage"
import { useGetRequest } from "../../../api/useGetRequest"

type MockupListWrapperProps = {
    openBrowseMockups: boolean
}

const MockupListWrapper = styled.div<MockupListWrapperProps>`
    width:100%;
    display:${props => props.openBrowseMockups ? "none" : "block"};

    .mockupsBar__button{
        margin-bottom: 1.5rem;
        margin-top: 2rem;
        display:flex;
        justify-content:center;

        button {
            border-radius: 2em;
            padding: 1.3rem 2.8rem;
        }
    }

    .mockupsBar__loading{
        margin-top:5rem;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .mockupsBar__list{
        display:flex;
        justify-content:center;
        flex-direction:column;
        min-height:1000px;
    }

`
type Props = {
    open: boolean,
    openBrowseMockups: boolean,
    selectedMockupId: number,
    handleBrowseAllMockups: () => void,
}

const MockupList = (props: Props) => {
    const mockupGeneralState = useRecoilValue(mockupState);
    const { error, data, loading, handleRetry } = useGetRequest({
        key: "related " + props.selectedMockupId,
        fetcher: () => ApiClient.getRelatedMockups({ mockupId: props.selectedMockupId })
    })

    const renderMockupsList = () => {
        if (loading) {
            return (
                <div className="mockupsBar__loading">
                    <Spinner />
                </div>
            )
        } else if (error) {
            return (
                <ErrorMessage
                    label="Error loading related mockups"
                    handleRetry={handleRetry}
                />
            )
        } else if (!loading && data && data.length === 0) {
            return (
                <EmptyState
                    message="No related mockups available"
                />
            )
        }
        else if (!loading && data && data.length > 0) {
            const firstImage = Object.keys(mockupGeneralState.userUploads).map((id) => mockupGeneralState.userUploads[id])[0];
            return (
                <RelatedMockups
                    relatedMockupsList={data}
                    selectedMockupId={props.selectedMockupId}
                    uploadedImage={firstImage ? firstImage.userImage.base64 : ""} />
            )
        } else {
            return null
        }
    }

    return (
        <MockupListWrapper openBrowseMockups={props.openBrowseMockups}>
            {props.open && (
                <div className="mockupsBar__button">
                    <Button
                        color={ButtonColor.blue}
                        label="Browse"
                        onClick={props.handleBrowseAllMockups}
                        disabled={loading}
                    />
                </div>
            )}
            <h2 className="mockupsBar__title">Related mockups</h2>
            {renderMockupsList()}
        </MockupListWrapper>
    )
}

export default MockupList