import React from "react"
import styled from "styled-components"
import * as Sentry from "@sentry/browser"
import Button, { ButtonColor } from "../ui/buttons/Button"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"

const ErrorBoundaryWrapper = styled.section`

    width:100%;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    svg{
        width:7rem;
        height:7rem;
        margin-bottom:1.5rem;
    }

    p{
        font-size:2.1rem;
        margin-bottom:1rem;
    }

    button{
        margin-top:2rem;
    }

`

type State = {
    error: Error,
    info: React.ErrorInfo
}

type Props = {}
class ErrorBoundary extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props)
        this.state = {
            error: undefined,
            info: undefined
        }
    }

    componentDidCatch = (error: Error, info: React.ErrorInfo) => {
        console.error("Error in render catch", { error, info, })
        this.setState({
            error: error,
            info: info
        })
        Sentry.setTag("Source", "ErrorBondary")
        Sentry.setExtra("ErrorBoundaryInfo", info)
        Sentry.captureException(error)
    }

    handleRetry = () => {
        this.setState({
            error: undefined,
            info: undefined,
        })
    }

    render() {
        if (this.state.error) {
            return (
                <ErrorBoundaryWrapper>
                    <ErrorIcon/>
                    <p>An unexpected error ocurred</p>
                    <p>Please, try again</p>
                    <Button
                        color={ButtonColor.red}
                        label="Retry"
                        onClick={this.handleRetry}
                        large={true}
                    />
                </ErrorBoundaryWrapper>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary

