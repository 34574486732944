import Button, { ButtonColor } from "../ui/buttons/Button";
import { MockupState, mockupState } from "../../Recoil/RecoilState";
import React, { useEffect, useRef, useState } from "react";

import { ApiClient } from "../../api/ApiClient";
import ErrorMessage from "./ErrorMessage";
import InputText from "../ui/form/InputText";
import ReCAPTCHA from "react-google-recaptcha";
import produce from "immer";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const LoginFormWrapper = styled.div`
  display: grid;
  gap: 2em;

  .forgot-password {
    font-size: 1.6rem;
    color: var(--color-blue);
  }

  .re-captcha-container {
    display: flex;
    justify-content: center;
  }
  button {
    width: 100%;
    border-radius: 40px;
  }
  .switch-container {
    font-size: 1.6rem;
    color: gray;
    margin: 10px;
    .switch-text {
      cursor: pointer;
      color: #3cd699;
    }
  }
`;
type Props = {
  isLogin: boolean;
  handleChangeIsLogin: (isLogin: boolean) => void;
  handleLoading: (isLoading: boolean) => void;
};

const LoginForm = (props: Props) => {
  type FormValidation = { isValid: boolean; errorMessage: string[] };

  const captchaSiteKey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
  const { handleChangeIsLogin, isLogin, handleLoading } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameErrors, setUsernameErrors] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const recaptchaRef = useRef<ReCAPTCHA>();
  const [mockupGeneralState, setMockupGeneralState] =
    useRecoilState(mockupState);
  const [formValidation, setFormValidation] = useState<FormValidation>({
    isValid: true,
    errorMessage: [],
  });

  const switchModalContent = () => {
    handleChangeIsLogin(!isLogin);
  };

  const formValidator = ({ isValid, errorMessage }: FormValidation) => {
    setFormValidation({
      isValid: isValid,
      errorMessage: [...errorMessage],
    });
  };

  const validateEmptyFields = () => {
    if (!username) {
      setUsernameErrors(["The Email field is required"]);
    }
    if (!password) {
      setPasswordErrors(["The Password field is required"]);
    }
  };

  useEffect(() => {
    async function fetchTranslations() {
      const isEmpty = Object.keys(mockupGeneralState.translations).length === 0;
      if (isEmpty) {
        const translations: any = await ApiClient.getCommonTranslations();
        setMockupGeneralState(
          produce((draftState: MockupState) => {
            draftState.translations = translations;
          })
        );
      }
    }
    fetchTranslations();
  }, []);

  const handleLogIn = async () => {
    setUsernameErrors([]);
    setPasswordErrors([]);
    formValidator({
      isValid: true,
      errorMessage: [],
    });
    validateEmptyFields();

    const captchaToken = recaptchaRef.current.getValue();

    if (!captchaToken) {
      formValidator({
        isValid: false,
        errorMessage: ["Please verify that you are not a robot"],
      });
    }
    if (!username || !password || !captchaToken) {
      return;
    }
    handleLoading(true);
    const { error, message } = await ApiClient.login({
      username,
      password,
      captchaToken,
    });
    handleLoading(false);

    if (error) {
      if (message) {
        formValidator({
          isValid: false,
          errorMessage:
            message === "invalid_captcha"
              ? ["Please verify that you are not a robot"]
              : message === "invalid_credentials"
              ? [
                  "Email and password provided did not match, please check and try again",
                ]
              : ["Login error. Try again!"],
        });
      }
      recaptchaRef.current.reset();
      return;
    }

    await loadUserData();

    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.modal = {
          open: false,
          type: undefined,
        };
      })
    );
  };
  const validateUsername = (username) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(username);

  const loadUserData = async () => {
    try {
      const userInfo = await ApiClient.getUserInfo();

      const { userName, userAvatar, userPlanType, watermark, isSubscriptor, hasMockupsAccess, hasRemainingDownloads } =
        userInfo;

      sessionStorage.setItem("user", JSON.stringify(userInfo));

      setMockupGeneralState(
        produce((draftState: MockupState) => {
          draftState.user = {
            loggedIn: true,
            token: "",
            name: userName,
            avatar: userAvatar,
            planType: userPlanType,
            watermark,
            isSubscriptor,
            hasMockupsAccess,
            hasRemainingDownloads,
          };
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleSignUp = async () => {
    setUsernameErrors([]);
    setPasswordErrors([]);
    formValidator({
      isValid: true,
      errorMessage: [],
    });
    validateEmptyFields();
    const captchaToken = recaptchaRef.current.getValue();
    if (!captchaToken || !username || !password) {
      return;
    }
    if (!validateUsername(username)) {
      setUsernameErrors(["Email field is invalid"]);
      return;
    }

    handleLoading(true);
    const { error, data } = await ApiClient.signUp({
      email: username,
      password,
      captchaToken,
    });
    handleLoading(false);

    if (error) {
      let errorMessage = [];
      for (let i = 0; i < data.length; i++) {
        const descend = data[i].code.split(".");
        const translations = mockupGeneralState.translations;
        const page = translations[descend[0]];
        const section = page?.[descend[1]];
        const folder = section?.[descend[2]];
        const text: string = folder?.[descend[3]];
        let auxError: string = "Unknown sign up error";
        if (text) {
          auxError = `${text} ${data[i].data ? `: min. ${data[i].data}` : ""}`;
        }
        errorMessage.push(auxError);
      }
      formValidator({ isValid: false, errorMessage });

      recaptchaRef.current?.reset();
      return;
    }

    recaptchaRef.current.reset();
    await loadUserData();

    formValidator({ isValid: true, errorMessage: [] });
    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.modal = {
          open: false,
          type: undefined,
        };
      })
    );
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <LoginFormWrapper>
      <InputText
        errorMessages={usernameErrors}
        handleChange={handleEmailChange}
        placeholder="Email"
        type="text"
      />
      <InputText
        errorMessages={passwordErrors}
        handleChange={handlePasswordChange}
        placeholder="Password"
        type="password"
      />
      {isLogin && (
        <a
          className="forgot-password"
          target="_blank"
          href="https://www.vexels.com/login/forgot"
        >
          Forgot your password?
        </a>
      )}
      <div className="re-captcha-container">
        {captchaSiteKey && (
          <ReCAPTCHA ref={recaptchaRef} sitekey={captchaSiteKey} />
        )}
      </div>
      <Button
        color={ButtonColor.green}
        label={isLogin ? "Log in" : "Sign in"}
        large
        onClick={isLogin ? handleLogIn : handleSignUp}
      />
      {!formValidation.isValid && formValidation.errorMessage.length > 0
        ? formValidation.errorMessage.map((error, index) => (
            <ErrorMessage key={index} message={error} />
          ))
        : ""}
      <div className="switch-container">
        <p>
          {isLogin ? `Don't have an account?` : `Already have an account?`}
          <span onClick={switchModalContent} className="switch-text">
            &nbsp;{isLogin ? `Sign up` : `Log in`}
          </span>
        </p>
      </div>
    </LoginFormWrapper>
  );
};

export default LoginForm;
