import React from "react"
import styled from "styled-components"
import { useRecoilState } from "recoil"
import { mockupState, MockupState, SCALE_STATE } from "../../../Recoil/RecoilState"
import produce from "immer"
import { transform } from "framer-motion"
import { screenSize } from "../../../stylesGlobal/Variables"
import { sendEvent } from "../../../utils/Events"

const ScaleImageWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:var(--color-white);
    margin-right: 6%;
    position:relative;

    .scaleImage__label{
        font-size:1.3rem;
        margin-right:1.5rem;
        font-weight:500;
    }

    .scaleImage__Container {
        display: flex;
    }

    .scaleImage_symbol{
        font-size:1.2rem;
        font-weight:500;
    }

    .scaleImage__range{
        --track-height: .6rem;
        --slider-thumb-size: 1.3em;
        --slider-thumb-radius: 50%;
        --slider-thumb-shadow: 0px 0px 0px .7em rgba(63,128,241,0.3);
        margin: 0 1rem;
        border: none;
        height: 4px;
        width: 143px;
        outline: none; 
        transition: background 450ms ease-in;
        accent-color: var(--color-blue);
        cursor:pointer;
        
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 12em;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track, &::-moz-range-track {
            height: var(--track-height);  
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            margin-top: 0;

            background-color: var(--color-blue);
            height: var(--slider-thumb-size);
            width: var(--slider-thumb-size);
            border-radius: var(--slider-thumb-radius);

            transition: box-shadow .3s;
            box-shadow: 0px 0px 0px 0px transparent;

            &:hover {
                box-shadow: var(--slider-thumb-shadow);
            }
        }
        &::-moz-range-thumb {
            border: none;
            border-radius: 0;

            background-color: var(--color-blue);
            height: var(--slider-thumb-size);
            width: var(--slider-thumb-size);
            border-radius: var(--slider-thumb-radius);

            transition: box-shadow .3s;
            box-shadow: 0px 0px 0px 0px transparent;

            &:hover {
                box-shadow: var(--slider-thumb-shadow);
            }
        }
    }

    @media (min-width: ${screenSize.size_m}){
        margin-right: 7%;
    }
    @media (min-width: ${screenSize.size_xl}){
        margin-right: 8%;
    }  
`

const ScaleImage = () => {
    const [mockupGeneralState, setMockupGeneralState] = useRecoilState(mockupState)

    const maxWidth = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale * 3;
    const minWidth = mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale / 3;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const scaleValue = parseInt(event.currentTarget.value, 10);
        const newValue = transform(scaleValue, [0, 100], [minWidth, maxWidth]);
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                draftState.mockup.images[draftState.selectedImage.id].imageScale = newValue;
                draftState.mockup.scaleState = SCALE_STATE.changeing;
            })
        )
    }

    const handleMouseUp = (_event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        sendEvent('MG - Toolbar', 'resize');
        setMockupGeneralState(
            produce((draftState: MockupState) => {
                // if (draftState.userImage.base64 !== "") {
                //     draftState.events.push(EventTypes.scaleImage)
                // }
                draftState.mockup.scaleState = SCALE_STATE.stop;
            })
        )
    }

    const rangeValue = transform(
        mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].imageScale
            ? mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].imageScale
            : mockupGeneralState.mockup.images[mockupGeneralState.selectedImage.id].defaultScale,
        [minWidth, maxWidth], [0, 100]
    )

    return (
        <ScaleImageWrapper>
            <p className="scaleImage__label">size</p>
            <span className="scaleImage_symbol">-</span>
            <div
                className="scaleImage__Container"
            >
                <input
                    className="scaleImage__range"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    style={{
                        background: `linear-gradient(to right, var(--color-blue) 0%, var(--color-blue) ${rangeValue}%, #FFF ${rangeValue}%, #FFF 100%)`,
                        borderRadius: '5px'
                    }}
                    value={rangeValue.toString()}
                    onChange={handleChange}
                    onMouseUp={handleMouseUp}
                />
            </div>
            <span className="scaleImage_symbol">+</span>

        </ScaleImageWrapper>
    )
}

export default ScaleImage