import React from "react";
import styled from "styled-components";
import { mockupState, MockupState } from "../../../../Recoil/RecoilState";
import produce from "immer";
import { useRecoilState } from "recoil";
import { motion } from "framer-motion";
import isMobile from 'ismobilejs';
import { screenSize } from "../../../../stylesGlobal/Variables"

const ColorsListSelectorWrapper = styled.section`
  display: grid;
  gap: 2em;
  height: ${isMobile(window.navigator).any ? '201px' : 'auto'};
  width: 100%;
`;

const ColorsListText = styled.p`
  text-align: center;
  font-weight: 300;
  color: #9F9F9F;
  font-size: 1.2rem;
`;

const ColorsListGrid = styled.div`
  --colors-reference-gap: 2em;
  display: grid;
  justify-content: center;
  gap: var(--colors-reference-gap);
  width: ${isMobile(window.navigator).any ? '285px' : 'auto'};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  gap: var(--colors-reference-gap);

  :last-child {
    grid-auto-flow: column;
    grid-template-columns: none;
  }
`;

const ColorButton = styled(motion.button) <{ color: string }>`
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  box-shadow: 0 0 18px #0000001a;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  @media (min-width: ${screenSize.size_s}){
    width: 25px;
    height: 25px;
  }
`;

const ColorsList = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mockupGeneralState, setMockupGeneralState] = useRecoilState(
    mockupState
  );
  const listColorsHex = [
    [
      "#FFFFFF",
      "#111111",
      "#cfd1d1",
      "#818189",
      "#31261d"
    ],
    [
      "#3f3e3c",
      "#15232b",
      "#1c4086",
      "#4a4f26",
      "#514689"
    ],
    ["#006136",
      "#6e0a25",
      "#8fb8db",
      "#f0e87b",
      "#f8a3bc"
    ],
    [
      "#ff5c39",
      "#b71111",
      "#5e9444"
    ]
  ];

  const getHexValueForPixi = (hex: string) => {
    const noHash = hex.split("#")[1];
    return `0x${noHash}`;
  };

  const handleClickColor = (hex: string) => {
    setMockupGeneralState(
      produce((draftState: MockupState) => {
        draftState.mockup.color = getHexValueForPixi(hex);
      })
    );
  };
  return (
    <ColorsListSelectorWrapper>
      {!isMobile(window.navigator).any && <ColorsListText>Merch by Amazon Product Colors</ColorsListText>}
      <ColorsListGrid>
        {listColorsHex.map((row, index) => <Row>
          {row.map((color) => <ColorButton key={index} whileTap={{ scale: 1.4 }} color={color} onClick={() => handleClickColor(color)} />
          )}
        </Row>
        )}
      </ColorsListGrid>
    </ColorsListSelectorWrapper>
  );
};

export default ColorsList;
