import { ApiFunctions, apiUrl } from "./ApiConfig";
import {
  ApiResponse,
  MockupBindingModel,
  MockupSearchModel,
  MockupWithImagesViewModel,
} from "./clientInterfaces/MockupModel";
import {
  AuthenticationModel,
  AuthenticationModelGoogle,
  AuthenticationResponse,
  RegisterModel,
  RegisterResponse,
} from "./clientInterfaces/AuthenticationModel";
import {
  CategoryBindingModel,
  CategoryViewModel,
} from "./clientInterfaces/CategoryModel";
import { getToken, setToken } from "../utils/AuthCookies";

import { EnvHelper } from "../utils/EnvHelper";
import { MockClient } from "./MockClient";
import { UserViewModel } from "./clientInterfaces/UserModel";
import { dataLayerPush } from "../utils/DataLayer";

const fetcher = async <T>(url: string): Promise<T> => {
  const resp = await fetch(url);
  return resp.json();
};

const fetcherPost = async <T>(data: any): Promise<T> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const headersWithAuth = { ...headers, Authorization: `Bearer ${data.token}` };
  const resp = await fetch(data.url, {
    method: "POST",
    headers: data.token ? headersWithAuth : headers,
    body: JSON.stringify(data.body),
  });
  return resp.json();
};

export const fetcherWithCredentials = async <T>(url: string, method?: string): Promise<T> => {
  const token = getToken();
  try {
    if (token) {
      const resp = await fetch(url, {
        method: method || "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resp.json();
    }
  } catch (error) {
    throw new Error(error);
  }
};

class _ApiClient implements ApiFunctions {
  private static instance: _ApiClient;

  private constructor() { }

  static getInstance(): _ApiClient {
    if (!_ApiClient.instance) {
      _ApiClient.instance = new _ApiClient();
      return _ApiClient.instance;
    } else {
      return _ApiClient.instance;
    }
  }

  getMockup = async (query: MockupBindingModel) => {
    const result = await fetcher<MockupWithImagesViewModel>(
      apiUrl.getMockup(query.mockupId)
    );
    return result;
  };

  getRelatedMockups = async (query: MockupBindingModel) => {
    const result = await fetcher<MockupWithImagesViewModel[]>(
      apiUrl.getRelatedMockups(query.mockupId)
    );
    return result;
  };

  getCategories = async () => {
    const result = await fetcher<CategoryViewModel[]>(apiUrl.getCategories);
    return result;
  };

  getCategoryMockups = async (query: CategoryBindingModel) => {
    const result = await fetcher<MockupWithImagesViewModel[]>(
      apiUrl.getCategoryMockups(
        query.categoryId,
        query.tags,
        query.limit,
        query.page
      )
    );
    return result;
  };

  getUserInfo = async () => {
    try {
      let user = await fetcherWithCredentials<UserViewModel>(
        apiUrl.getUserInfo
      );
      if (user.isSubscriptor) {
        const {
          features: {
            "mockup-generator": hasAccess,
            "mockup-generator-mockups": hasMockupsAccess,
            "mockup-generator-download": { used: usedDownloads, total: totalDownloads },
          }
        } = await fetcherWithCredentials<any>(apiUrl.getPermissions);
        user.hasMockupsAccess = hasMockupsAccess;
        user.hasRemainingDownloads = totalDownloads - usedDownloads > 0;
        user.watermark = user.watermark || !hasAccess || !user.hasRemainingDownloads;
      }
      return user;
    } catch (error) {
      throw new Error(error);
    }
  };

  registerDownload = async () => {
    try {
      const res: { error: boolean, data: string } = await fetcherWithCredentials(apiUrl.registerDownload, "POST");
      return !res.error && res.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  getDefaultMockups = async () => {
    const { results } = await fetcher<ApiResponse>(apiUrl.getDefaultMockups);
    return results;
  };

  login = async (query: AuthenticationModel) => {
    const { username, password, captchaToken } = query;
    if (!username || !password || !captchaToken) {
      return { error: true };
    }
    const res = await fetcherPost<AuthenticationResponse>({
      url: apiUrl.login,
      method: "POST",
      body: query,
    });
    const { accessToken, userId } = res;
    setToken(accessToken, "local", userId);
    dataLayerPush("login");
    return res;
  };

  loginGoogle = async (token: AuthenticationModelGoogle) => {
    const res = await fetcherPost<any>({
      url: apiUrl.loginGoogle,
      method: "POST",
      body: {
        id_token: token,
      },
    });
    const { accessToken, userId } = res;
    setToken(accessToken, "google", userId);
    dataLayerPush("login");
    return res;
  };

  loginFacebook = async (token: string) => {
    const res = await fetcherPost<any>({
      url: apiUrl.loginFacebook,
      method: "POST",
      body: {
        accessToken: token,
      },
    });
    const { accessToken, userId } = res;
    setToken(accessToken, "facebook", userId);
    dataLayerPush("login");
    return res;
  };

  signUp = async (query: RegisterModel) => {
    const { error, message, data } = await fetcherPost<RegisterResponse>({
      url: apiUrl.signUp,
      method: "POST",
      body: query,
    });
    const { token, userId } = data;
    setToken(token, "local", userId);
    dataLayerPush("sign_up");
    return {
      error,
      message,
      data,
    };
  };

  getCommonTranslations = async () => {
    const results = await fetcher<ApiResponse>(apiUrl.getCommonTranslations);
    return results;
  };

  getMockupSearch = async (query: MockupSearchModel) => {
    const res = await fetcher<ApiResponse>(
      apiUrl.getMockupSearch(query.mockup, query.page)
    );
    return res;
  };
}

export const ApiClient = EnvHelper.apiMock
  ? MockClient.getInstance()
  : _ApiClient.getInstance();
