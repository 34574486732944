import React from "react"
import styled from "styled-components"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"
import Button, { ButtonColor } from "../ui/buttons/Button"

const ErrorModalWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:2rem;

    svg{
        width:4rem;
        height:4rem;
        margin-bottom:1rem;
    }

    p{
        font-size:1.6rem;
        text-align:center;

        &.label{
            font-weight:500;
            margin-bottom:1rem;
        }
    }

    button{
        margin-top:1.5rem;
    }
`

type Props = {
    handleRetry: () => void,
    label: string,
}

const ErrorMessage = (props: Props) => {
    return (
        <ErrorModalWrapper>
            <ErrorIcon />
            <p className="label">{props.label}</p>
            <p>Please, try again</p>
            <Button
                color={ButtonColor.red}
                label="Retry"
                onClick={props.handleRetry}
                large={true}
            />
        </ErrorModalWrapper>
    )
}

export default ErrorMessage