import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { mockupState } from '../../../Recoil/RecoilState';

const SelectedImageWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:var(--color-white);
    margin-right: 6%;
    font-size:1.2rem;
    margin-right:1.5rem;
    font-weight:500;
    position: absolute;
    left: 0;

    img {
        height:4.6rem;
        object-fit: cover;
        width: 4.6rem;
        background: #eaeaea;
    }

    p {
        margin-left: 1rem;
    }
`;

const SelectedImage: React.FC = () => {
    const mockupGeneralState = useRecoilValue(mockupState);
    
    return (
        <SelectedImageWrapper>
            <img 
            src={mockupGeneralState.userUploads[mockupGeneralState.selectedImage.id].userImage.base64} 
            alt="Selected user upload" /><p>Image {Object.keys(mockupGeneralState.userUploads).indexOf(mockupGeneralState.selectedImage.id) + 1}</p>
        </SelectedImageWrapper>
    )
}

export default SelectedImage;