
class CancelationError extends Error {

}

export type CancelationToken = {
    checkCancelation: () => void,
    isCancelationError: (error: Error) => boolean
}

export const makeCancelable = <T extends unknown[], R>(f: (c: CancelationToken, ...args: T) => Promise<R>) => {
    return (...args: T) => {

        const state = {
            canceled: false
        }

        const checkCancelation = () => {
            if (state.canceled) {
                throw new CancelationError()
            }
        }

        const handleCancel = () => {
            state.canceled = true
        }

        const isCancelationError = (e: Error) => {
            return e instanceof CancelationError
        }


        const getResult = async () => {
            try {
                const result = await f({
                    checkCancelation: checkCancelation,
                    isCancelationError: isCancelationError
                }, ...args)
                return {
                    ret: result,
                    canceled: false,
                }
            } catch (e) {
                if (e instanceof CancelationError) {
                    return {
                        ret: null,
                        canceled: true,
                    }
                } else {
                    throw e
                }
            }
        }


        return {
            cancel: handleCancel,
            result: getResult()
        }
    }
}
