import { timeoutPromise } from "../components/smartCanvas/SmartFunctions"


const state = {
    isDragging: false,
}

export const Interacting = {
    start: () => {
        state.isDragging = true
    },
    stop: () => {
        state.isDragging = false
    }
}
export const notInteracting = async ()=>{
    while (state.isDragging){
        await timeoutPromise(30)
    }
}