import React from "react";
import styled from "styled-components";
import MobileMockups from "./mockups/MobileMockups";

type Props = {
    selectedMockupId: number,
    closeEvent: () => void
}

const MockupsControlWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const MockupsControl = (props: Props) => {
    return (
        <MockupsControlWrapper>
            <MobileMockups selectedMockupId = {props.selectedMockupId} closeEvent={props.closeEvent} />
        </MockupsControlWrapper>
    )
}

export default MockupsControl;